import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { Condition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import IntervalUnit from "@app/enum/IntervalUnit";

export const roundToIntervalUnit: Record<string, IntervalUnit> = {
  days: IntervalUnit.DAY,
  hours: IntervalUnit.HOUR,
  months: IntervalUnit.MONTH,
  weeks: IntervalUnit.WEEK,
  years: IntervalUnit.YEAR,
};

const VALUE_REGEX = /^(?<amount>-?\d+)\s*(?<unit>(hours|days|weeks|months|years))$/;
const parseValue = (value: string): { amount: number; unit: IntervalUnit } | undefined => {
  const match = VALUE_REGEX.exec(value);
  return match
    ? {
        amount: parseInt(match.groups!.amount, 10),
        unit: roundToIntervalUnit[match.groups!.unit] as IntervalUnit,
      }
    : undefined;
};

const parseIntervalCondition = (platformCondition: Condition) => {
  const filterObject = platformCondition[PlatformFilterOperator.INTERVAL] as {
    [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]?: string;
    [PlatformFilterOperator.GREATER_THAN]?: string;
    [PlatformFilterOperator.LESS_THAN_OR_EQUAL]?: string;
    [PlatformFilterOperator.LESS_THAN]?: string;
  };

  let parsedValue;
  let unit;
  let from: number | undefined = undefined;

  // we know that one of these must be defined - either $gt or $gte
  parsedValue = parseValue(
    (filterObject[PlatformFilterOperator.GREATER_THAN] ??
      filterObject[PlatformFilterOperator.GREATER_THAN_OR_EQUAL]) as string
  );
  if (parsedValue) {
    from = parsedValue.amount;
    unit = parsedValue.unit;
  }

  let to: number | undefined = undefined;
  // we know that one of these must be defined - either $lt or $lte
  parsedValue = parseValue(
    (filterObject[PlatformFilterOperator.LESS_THAN] ??
      filterObject[PlatformFilterOperator.LESS_THAN_OR_EQUAL]) as string
  );
  if (parsedValue) {
    to = parsedValue.amount;
    unit = unit ?? parsedValue.unit;
  }

  if (isDefined(from) && isDefined(to)) {
    return {
      operator:
        from < 0 ? FilterOperator.INTERVAL_WITHIN_LAST : FilterOperator.INTERVAL_WITHIN_NEXT,
      value: { from, to, unit },
    };
  }

  const value = from ?? to ?? 0;
  return {
    operator:
      PlatformFilterOperator.LESS_THAN in filterObject ||
      PlatformFilterOperator.LESS_THAN_OR_EQUAL in filterObject
        ? FilterOperator.INTERVAL_BEFORE
        : FilterOperator.INTERVAL_AFTER,
    value: { unit, value },
  };
};

export default parseIntervalCondition;
