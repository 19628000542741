import React, { useCallback } from "react";
import { connect } from "react-redux";

import Button from "antd/es/button";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import Path from "@app/enum/Path";
import { resetErrors } from "@app/store/auth/actions";

import AuthLayout from "../AuthLayout";

import styles from "./LinkExpiredPage.module.scss";

interface Props {
  onResetErrors: typeof resetErrors;
}

export const LinkExpiredPage: React.FC<Props> = ({ onResetErrors }) => {
  const intl = useIntl();

  const history = useHistory();

  const handleClick = useCallback(() => {
    onResetErrors();
    history.push(Path.FORGOT_PASSWORD);
  }, [onResetErrors, history]);

  return (
    <AuthLayout>
      <div className={styles.container}>
        <p className={styles.title}>
          {intl.formatMessage({
            id: "auth.forgotPassword.linkExpired.title",
            defaultMessage: "Link Expired",
            description: "Title of link expired page",
          })}
        </p>
        <p className={styles.description}>
          {intl.formatMessage({
            id: "auth.forgotPassword.linkExpired.description",
            defaultMessage: "The password request has expired.",
            description: "Title of link expired page",
          })}
        </p>
        <Link to={Path.FORGOT_PASSWORD}>
          <Button onClick={handleClick} type="primary">
            {intl.formatMessage({
              id: "auth.forgotPassword.linkExpired.button.title",
              defaultMessage: "Send new password reset link",
              description: "Title of send another request button",
            })}
          </Button>
        </Link>
      </div>
    </AuthLayout>
  );
};

const mapDispatchToProps = {
  onResetErrors: resetErrors,
};

export default connect(null, mapDispatchToProps)(LinkExpiredPage);
