import { useMemo } from "react";

import colorUtil from "color";

import AnyColor, { HexColor } from "@mapmycustomers/shared/types/AnyColor";
import { ActivityTypeRef } from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import anyColorToHex from "@mapmycustomers/shared/util/color/anyColorToHex";
import getContrastColor from "@mapmycustomers/shared/util/color/getContrastColor";

import colors from "@app/styles/_colors.module.scss";

const getActivityTypeColors = (
  activityType?: ActivityTypeRef,
  lightBg?: boolean // guarantee that the bg will be light, e.g. for the SchedulerView
): [color: AnyColor, bgColor: AnyColor, borderColor: AnyColor] => {
  const { color } = activityType ?? {};
  if (!color) {
    return [colors.softSlate, colors.softGray, colors.softSlate];
  }
  const bgColor = colorUtil(anyColorToHex(color));

  if (getContrastColor(color, true) === "black" && !lightBg) {
    // not only make bg darker, but also use it for the border too
    const darkerColor = bgColor.darken(0.5).hex() as HexColor;
    return [color, darkerColor, darkerColor];
  } else {
    return [color, bgColor.lighten(0.8).hex() as HexColor, color];
  }
};

export const useActivityTypeColors = (type?: ActivityTypeRef) =>
  useMemo(() => getActivityTypeColors(type), [type]);

export default getActivityTypeColors;
