enum OrganizationSetting {
  ACTIVE_CHECK_IN = "activeCheckIn",
  ACTIVITY_COMPLETION_STATUS = "activityCompletionStatus",
  ACTIVITY_VISIBILITY = "activityVisibility",
  ALLOW_PHI_ACCESS_TO_MMC = "allowPHIAccessToMMC",
  CHECK_IN_RADIUS = "reliabilityThreshold",
  CHECK_IN_VERIFIED_VISIBILITY = "checkInVerifiedVisibility",
  CURRENCY = "currency",
  DEFAULT_CHECK_IN_ACTIVITY_TYPE = "defaultCheckInActivityType",
  DISTANCE_UNIT = "distanceUnit",
  DUPLICATE_CHECK = "duplicateCheck",
  DUPLICATE_CHECK_CRITERIA = "duplicateCheckCriteria",
  EXPORT_UID = "apostropheUID",
  IMPORT_ALLOWED = "importAllowed",
  LEAD_GEN_CHECK = "leadGenCheck",
  LEAD_GEN_ENTITY = "leadGenEntity",
  LEADERBOARD_VISIBILITY = "leaderboardVisibility",
  LOGO = "logo",
  NEAR_BY_SUGGESTION_ACTIVITY_TYPE = "nearbySuggestionActivityType",
  SSO_LOGIN_APPROVAL_REQUIRED = "ssoLoginApprovalRequired",
  TIMEZONE = "timezone",
  USER_LOCATION_VISIBILITY = "userLocationVisibility",
  UTC_OFFSET = "utcOffset",
  CLOSED_DATE_UPDATE_PREFERENCE = "closedDateUpdatePreference",
}

export default OrganizationSetting;
