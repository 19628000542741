import React, { useCallback, useState } from "react";

import cn from "classnames";
import { useIntl } from "react-intl";

import { RawFile } from "@mapmycustomers/shared/types/File";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";

import ButtonLink from "@app/component/ButtonLink";
import DropZone from "@app/component/Dropzone";
import canPreview from "@app/component/FilePreview/canPreview";
import PreviewFile from "@app/component/FilePreview/PreviewFile";
import TextWithInfo from "@app/component/typography/TextWithInfo";
import FileListItem from "@app/types/FileListItem";
import { RECORD_FILE_UPLOAD_LIMIT } from "@app/util/consts";

import useAnalytics from "../../util/contexts/useAnalytics";

import FileItem from "./FileItem";
import styles from "./FileUploader.module.scss";
import messages from "./messages";

interface Props {
  associatedFiles?: RawFile[];
  disabled?: boolean;
  filePreview?: Blob;
  filePreviewId?: RawFile["id"];
  filePreviewLoading?: boolean;
  fileUploading?: boolean;
  onChange?: (uploadedFiles: RawFile[]) => void;
  onDownload?: (file: RawFile) => void;
  onFetchFilePreview?: (fileId: RawFile["id"]) => void;
  onFileUpload: (payload: {
    callback: (filesList: FileListItem[]) => void;
    files: (Blob | File)[];
  }) => void;
  onRemoveFile: (uploadedFile: RawFile) => void;
  required?: boolean;
  validateLastRequiredFileRemove?: boolean;
}

const FileUploader: React.FC<Props> = ({
  associatedFiles,
  disabled,
  filePreview,
  filePreviewId,
  filePreviewLoading,
  fileUploading,
  onChange,
  onDownload,
  onFetchFilePreview,
  onFileUpload,
  onRemoveFile,
  required,
  validateLastRequiredFileRemove,
}) => {
  const intl = useIntl();
  const analyticsIssuer = useAnalytics();
  const [isDragging, startDragging, stopDragging] = useBoolean();
  const [attemptedFiles, setAttemptedFiles] = useState<FileListItem[]>([]);
  const [selectedFile, selectFile] = useState<RawFile | undefined>(undefined);

  // We use useDynamicCallback instead of direct onChange usage because of some weirdness in Form.Item.
  // Its onChange handler changes all the time causing useChangeTracking to be executed again and again.
  // It's not good that we're modifying this component for its possible usage, but no big harm either.
  const handleChange = useDynamicCallback((uploadedFiles: RawFile[]) => {
    onChange?.(uploadedFiles);
  });

  useChangeTracking(() => {
    if (attemptedFiles.some(({ uploading }) => uploading)) {
      // do not fire onChange if there are files that are still uploading to avoid additional validations
      return;
    }

    const uploadedFiles = attemptedFiles.reduce(
      (result, { uploadedFile }) => {
        if (uploadedFile) {
          result.push(uploadedFile);
        }
        return result;
      },
      associatedFiles ? [...associatedFiles] : []
    );

    handleChange(uploadedFiles);
  }, [attemptedFiles, associatedFiles, handleChange]);

  const handleSelectFile = useCallback(
    (files: File[]) => {
      analyticsIssuer.clicked(["Upload File"]);

      setAttemptedFiles((attemptedFiles) => [
        ...attemptedFiles,
        ...files.map((file) => ({
          errored: false,
          exceedsLimit: file.size >= RECORD_FILE_UPLOAD_LIMIT,
          file,
          uploading: file.size < RECORD_FILE_UPLOAD_LIMIT,
        })),
      ]);

      onFileUpload({
        callback: (filesList) => {
          setAttemptedFiles((attemptedFiles) => {
            return [...attemptedFiles.filter(({ uploading }) => !uploading), ...filesList];
          });
        },
        files: files.filter(({ size }) => size < RECORD_FILE_UPLOAD_LIMIT),
      });
    },
    [analyticsIssuer, onFileUpload]
  );

  const isAnyFileErrored = attemptedFiles.some(({ errored }) => errored);
  const fileExceedsLimit = attemptedFiles.some(({ exceedsLimit }) => exceedsLimit);

  const attemptedFilesLastFileRemoveDisabled =
    validateLastRequiredFileRemove &&
    required &&
    !associatedFiles?.length &&
    attemptedFiles?.length === 1;

  const associatedFilesLastFileRemoveDisabled =
    validateLastRequiredFileRemove &&
    required &&
    !attemptedFiles.length &&
    associatedFiles?.length === 1;

  const previewRemoveLastFileDisabled =
    selectedFile &&
    validateLastRequiredFileRemove &&
    required &&
    (associatedFiles?.length ?? 0 + attemptedFiles?.length ?? 0) === 1;

  const handleRemove = useCallback(
    (index) => {
      const uploadedFile = attemptedFiles[index].uploadedFile;
      if (uploadedFile) {
        onRemoveFile(uploadedFile);
      }
      setAttemptedFiles([...attemptedFiles.slice(0, index), ...attemptedFiles.slice(index + 1)]);
    },
    [attemptedFiles, onRemoveFile, setAttemptedFiles]
  );

  const handleFileClick = useCallback(
    (file: RawFile) => {
      if (canPreview(file)) {
        selectFile(file);
      } else {
        onDownload?.(file);
      }
    },
    [onDownload, selectFile]
  );

  return (
    <div>
      <div className={styles.filesTitleDiv}>
        <TextWithInfo
          info={intl.formatMessage(messages.labelInfoTooltip, {
            fileLimit: RECORD_FILE_UPLOAD_LIMIT / 1024 / 1024,
          })}
        >
          <div className={cn({ [styles.required]: required })}>
            {intl.formatMessage(messages.label)}
          </div>
        </TextWithInfo>
      </div>
      {!disabled && (
        <DropZone onSelect={handleSelectFile}>
          <div
            className={cn(styles.dropzone, {
              [styles.draggedInDropZone]: isDragging && !isAnyFileErrored && !fileExceedsLimit,
              [styles.dropzoneErrored]: (isAnyFileErrored && !fileUploading) || fileExceedsLimit,
            })}
            onDragEnter={startDragging}
            onDragLeave={stopDragging}
          >
            <p className={styles.dropzoneTitle}>
              {intl.formatMessage(messages.dropZoneTitle, {
                a: (text: string) => <ButtonLink>{text}</ButtonLink>,
              })}
            </p>
          </div>
        </DropZone>
      )}
      {isAnyFileErrored && !fileUploading && (
        <span className={styles.errorMessage}>
          {intl.formatMessage(messages.erroredFilesTitle)}
        </span>
      )}
      {fileExceedsLimit && (
        <span className={styles.errorMessage}>
          {intl.formatMessage(messages.exceededSizeLimitError, {
            fileLimit: RECORD_FILE_UPLOAD_LIMIT / 1024 / 1024,
          })}
        </span>
      )}
      <ul className={cn(styles.filesList)}>
        {attemptedFiles.map((fileItem, index) => {
          return (
            <FileItem
              className={
                (!fileItem.uploading && fileItem.errored) || fileItem.exceedsLimit
                  ? styles.erroredFileRow
                  : styles.fileRow
              }
              disabled={disabled || attemptedFilesLastFileRemoveDisabled}
              errored={fileItem.errored || fileItem.exceedsLimit}
              fileName={
                fileItem.uploadedFile?.name ??
                (fileItem.file instanceof File ? fileItem.file.name : undefined)
              }
              key={fileItem?.uploadedFile?.id ?? index}
              onRemove={() => handleRemove(index)}
              removeTooltip={
                attemptedFilesLastFileRemoveDisabled
                  ? intl.formatMessage(messages.minimumOneFileRequiredTooltip)
                  : undefined
              }
              uploading={fileItem.uploading}
            />
          );
        })}
        {associatedFiles?.map((file) => {
          return (
            <FileItem
              className={cn(styles.fileRow, styles.clickableFileRow)}
              disabled={disabled || associatedFilesLastFileRemoveDisabled}
              file={file}
              fileName={file.name}
              key={file.id}
              onClick={handleFileClick}
              onRemove={() => onRemoveFile(file)}
              removeTooltip={
                associatedFilesLastFileRemoveDisabled
                  ? intl.formatMessage(messages.minimumOneFileRequiredTooltip)
                  : undefined
              }
            />
          );
        })}
      </ul>
      {selectedFile && (
        <PreviewFile
          deleteTooltip={
            previewRemoveLastFileDisabled
              ? intl.formatMessage(messages.minimumOneFileRequiredTooltip)
              : undefined
          }
          disabled={disabled || previewRemoveLastFileDisabled}
          file={selectedFile}
          loading={filePreviewLoading}
          onCancel={() => {
            selectFile(undefined);
          }}
          onDelete={(file: RawFile) => {
            onRemoveFile(file);
            selectFile(undefined);
          }}
          onDownload={onDownload}
          onFetchFilePreview={onFetchFilePreview}
          preview={filePreview}
          previewId={filePreviewId}
        />
      )}
    </div>
  );
};

export default FileUploader;
