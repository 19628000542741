enum FilterOperator {
  EMPTY = "empty", // value is undefined or null
  NOT_EMPTY = "notEmpty", // value is defined
  EQUALS = "equals",
  NOT_EQUAL = "notEqual",
  LESS_THAN = "lessThan",
  GREATER_THAN = "greaterThan",
  IN_RANGE = "inRange",
  LESS_THAN_OR_EQUAL = "lessThanOrEqual",
  GREATER_THAN_OR_EQUAL = "greaterThanOrEqual",
  CONTAINS = "contains",
  NOT_CONTAINS = "notContains",
  STARTS_WITH = "startsWith",
  ENDS_WITH = "endsWith",
  IN_ALL = "inAll",
  IN_ANY = "inAny",
  IN_AREA = "inArea", // this one is only used in the area filter
  // the following are used for dates, and basically match usual eq/neq/gt/gte/lt/lte, but with different labels
  ON = "on",
  NOT_ON = "notOn",
  NONE_OF = "noneOf",
  NOT_IN = "notIn",
  AFTER = "after",
  ON_OR_AFTER = "onOrAfter",
  BEFORE = "before",
  ON_OR_BEFORE = "onOrBefore",
  // these are used for frequency status field filter and don't directly match on eq/neq/gt/lt filters, because
  // the mapping also depends on the value
  IS_OVERDUE = "isOverdue",
  WILL_BE_OVERDUE = "willBeOverdue",
  IS_UPCOMING = "isUpcoming",
  IS_OVERDUE_BY_MORE_THAN = "isOverdueByMoreThan",
  IS_OVERDUE_BY_LESS_THAN = "isOverdueByLessThan",
  IS_OVERDUE_IN_MORE_THAN = "isUpcomingInMoreThan",
  IS_OVERDUE_IN_LESS_THAN = "isUpcomingInLessThan",
  // for boolean field
  IS_TRUE = "isTrue",
  IS_FALSE = "isFalse",
  // for relative date filtering
  INTERVAL_WITHIN_LAST = "intervalWithinLast",
  INTERVAL_WITHIN_NEXT = "intervalWithinNext",
  INTERVAL_AFTER = "intervalAfter",
  INTERVAL_BEFORE = "intervalBefore",
}

export default FilterOperator;
