import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import {
  Company,
  Deal,
  EntityType,
  EntityTypeSupportingPostCreationModal,
  Person,
} from "@mapmycustomers/shared/types/entity";

import CompanyAssociation from "@app/component/associations/CompanyAssociation";

import {
  addCompanyParentCompany,
  addDealParentCompany,
  addPersonCompanies,
} from "../store/actions";

interface Props {
  addCompanyParentCompany: typeof addCompanyParentCompany.request;
  addDealParentCompany: typeof addDealParentCompany.request;
  addPersonCompanies: typeof addPersonCompanies.request;
  createdRecord: Company | Deal | Person;
  entityType: EntityTypeSupportingPostCreationModal;
  onHide: (updated?: boolean) => void;
}

const ParentCompany: React.FC<Props> = ({
  addCompanyParentCompany,
  addDealParentCompany,
  addPersonCompanies,
  createdRecord,
  entityType,
  onHide,
}) => {
  const intl = useIntl();
  const handleSelectParent = useCallback(
    (companyIds: Company["id"][]) => {
      if (companyIds.length > 0) {
        if (entityType === EntityType.COMPANY) {
          addCompanyParentCompany({ id: createdRecord.id, parentCompanyId: companyIds[0] });
        } else if (entityType === EntityType.PERSON) {
          addPersonCompanies({ id: createdRecord.id, companyIds });
        } else if (entityType === EntityType.DEAL) {
          addDealParentCompany({ id: createdRecord.id, parentCompanyId: companyIds[0] });
        }
        onHide();
      }
    },
    [
      addCompanyParentCompany,
      addDealParentCompany,
      addPersonCompanies,
      createdRecord,
      entityType,
      onHide,
    ]
  );

  const assignedCompanies = useMemo(() => {
    switch (entityType) {
      case EntityType.COMPANY: {
        const company = createdRecord as Company;
        return company.parentAccount ? [company.parentAccount] : undefined;
      }
      case EntityType.DEAL: {
        const deal = createdRecord as Deal;
        return deal.account ? [deal.account] : undefined;
      }
      case EntityType.PERSON:
        return (createdRecord as Person)?.accounts;
    }
  }, [entityType, createdRecord]);

  return (
    <CompanyAssociation
      assignedCompanies={assignedCompanies}
      associateWith={createdRecord.id}
      entityType={entityType}
      multiselect={entityType === EntityType.PERSON}
      onHide={onHide}
      onSelect={handleSelectParent}
      supportsPrimaryEntity={entityType === EntityType.PERSON}
      title={
        entityType === EntityType.COMPANY
          ? intl.formatMessage({
              id: "companyRecordPane.relationships.parentCompany.modal.title",
              defaultMessage: "Select Parent Company",
              description: "Title of a Select Parent modal in the Preview Record pane",
            })
          : intl.formatMessage({
              id: "companyRecordPane.relationships.companies.modal.title",
              defaultMessage: "Select Companies",
              description: "Title of a Select Companies modal in the Preview Record pane",
            })
      }
    />
  );
};

const mapDispatchToProps = {
  addCompanyParentCompany: addCompanyParentCompany.request,
  addDealParentCompany: addDealParentCompany.request,
  addPersonCompanies: addPersonCompanies.request,
};

export default connect(null, mapDispatchToProps)(ParentCompany);
