import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import Organization from "@mapmycustomers/shared/types/Organization";
import UserRef from "@mapmycustomers/shared/types/User";

import UserRequestStatus from "@app/enum/sso/UserRequestStatus";
import SignupFormValues from "@app/scene/auth/component/Register/types/SignupFormValues";
import InvitationInfo from "@app/types/auth/InvitationInfo";
import UserDetails from "@app/types/auth/UserDetails";

export const login = createAction("@auth/login")();
export const logout = createAction("@auth/logout")<string | void>();

export const resetErrors = createAction("@auth/resetErrors")<void>();

export const tryLogin = createAsyncAction(
  "@auth/tryLogin/request",
  "@auth/tryLogin/success",
  "@auth/tryLogin/fail"
)<{ password: string; username: string }, string | undefined, unknown>();

export const loggedIn = createAction("@auth/loggedIn")<{
  keepLocation?: boolean;
  token?: string;
}>();

export const forgotPassword = createAsyncAction(
  "@auth/forgotPassword/request",
  "@auth/forgotPassword/success",
  "@auth/forgotPassword/failure"
)<string, void, unknown>();

export const checkResetPasswordCodeStatus = createAsyncAction(
  "@auth/checkResetPasswordCodeStatus/request",
  "@auth/checkResetPasswordCodeStatus/success",
  "@auth/checkResetPasswordCodeStatus/failure"
)<{ code: string; username: string }, void, void>();

export const resetPassword = createAsyncAction(
  "@auth/resetPassword/request",
  "@auth/resetPassword/success",
  "@auth/resetPassword/failure"
)<{ code: string; password: string; username: string }, void, unknown>();

export const userExists = createAsyncAction(
  "@auth/userExists/request",
  "@auth/userExists/success",
  "@auth/userExists/failure"
)<{ username: string }, boolean, unknown>();

export const signUp = createAsyncAction(
  "@auth/signUp/request",
  "@auth/signUp/success",
  "@auth/signUp/failure"
)<SignupFormValues, { organization: Organization; user: UserRef }, unknown>();

export const signUpMember = createAsyncAction(
  "@auth/signUpMember/request",
  "@auth/signUpMember/success",
  "@auth/signUpMember/failure"
)<SignupFormValues, { organization: Organization; user: UserRef }, unknown>();

export const fetchInvitationInfo = createAsyncAction(
  "@auth/fetchInvitationInfo/request",
  "@auth/fetchInvitationInfo/success",
  "@auth/fetchInvitationInfo/failure"
)<string, InvitationInfo, void>();

export const loggedInWithSso = createAction("@auth/loggedInWithSso")<{
  sessionId: string;
}>();

export const requestAccountSso = createAsyncAction(
  "@auth/requestAccountSso/request",
  "@auth/requestAccountSso/success",
  "@auth/requestAccountSso/failure"
)<{ callback: () => void; sessionId: string }, void, unknown>();

export const ssoLogin = createAsyncAction(
  "@auth/ssoLogin/request",
  "@auth/ssoLogin/success",
  "@auth/ssoLogin/failure"
)<{ username: string }, void, unknown>();

export const updateToken = createAction("@auth/updateToken")<boolean>();

export const updateUserStatus = createAsyncAction(
  "@auth/updateUserStatus/request",
  "@auth/updateUserStatus/success",
  "@auth/updateUserStatus/failure"
)<
  { sessionCode: string; status: UserRequestStatus; username: string },
  { status: UserRequestStatus; userDetails: UserDetails },
  unknown
>();

export const fetchUserDetailsSso = createAsyncAction(
  "@auth/fetchUserDetailsSso/request",
  "@auth/fetchUserDetailsSso/success",
  "@auth/fetchUserDetailsSso/failure"
)<string, UserDetails, unknown>();

export type Actions = ActionType<
  | typeof checkResetPasswordCodeStatus
  | typeof fetchInvitationInfo
  | typeof fetchUserDetailsSso
  | typeof forgotPassword
  | typeof loggedIn
  | typeof loggedInWithSso
  | typeof login
  | typeof logout
  | typeof requestAccountSso
  | typeof resetErrors
  | typeof resetPassword
  | typeof signUp
  | typeof signUpMember
  | typeof ssoLogin
  | typeof tryLogin
  | typeof updateToken
  | typeof updateUserStatus
  | typeof userExists
>;
