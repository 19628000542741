import { endOfDay, startOfDay, subQuarters } from "date-fns";
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns/esm";
import { defineMessages, IntlShape } from "react-intl";

import type DateRangeType from "@mapmycustomers/shared/types/range/DateRange";

import { getWeekEnd, getWeekStart } from "@app/util/dates";

export enum DateRange {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year",
}

export enum DateSubRange {
  THIS = "this",
  LAST = "last",
}

export const ALL_RANGES = Object.values(DateRange).reduce(
  (result, item) => ({
    ...result,
    [item]: Object.values(DateSubRange),
  }),
  {} as Record<DateRange, DateSubRange[]>
);

export const startOfFunction: Record<DateRange, (date: Date) => Date> = {
  [DateRange.DAY]: startOfDay,
  [DateRange.MONTH]: startOfMonth,
  [DateRange.QUARTER]: startOfQuarter,
  [DateRange.WEEK]: getWeekStart,
  [DateRange.YEAR]: startOfYear,
};

export const subFunction: Record<DateRange, (date: Date, amount: number) => Date> = {
  [DateRange.DAY]: subDays,
  [DateRange.MONTH]: subMonths,
  [DateRange.QUARTER]: subQuarters,
  [DateRange.WEEK]: subWeeks,
  [DateRange.YEAR]: subYears,
};

const subAmount = { beforeLast: 2, [DateSubRange.LAST]: 1, [DateSubRange.THIS]: 0 } as const;

export const endOfFunction: Record<DateRange, (date: Date) => Date> = {
  [DateRange.DAY]: endOfDay,
  [DateRange.MONTH]: endOfMonth,
  [DateRange.QUARTER]: endOfQuarter,
  [DateRange.WEEK]: getWeekEnd,
  [DateRange.YEAR]: endOfYear,
};

export const getDatesRange = (
  dateRange: DateRange,
  dateSubRange: DateSubRange,
  // Gives date range before the given dateSubRange. I.e. range was THIS day,
  // it's return dates for LAST day. If range was LAST day, it will return
  // dates for a day before it. This is useful for calculating a trend.
  previousDateRange?: boolean
): DateRangeType => {
  const startDate = startOfFunction[dateRange](new Date());
  const endDate =
    dateSubRange === DateSubRange.THIS ? new Date() : endOfFunction[dateRange](startDate);

  const shiftDate = (date: Date) =>
    subFunction[dateRange](date, subAmount[dateSubRange] + (previousDateRange ? 1 : 0));

  return { endDate: shiftDate(endDate), startDate: shiftDate(startDate) };
};

const rangeMessages = defineMessages<DateRange>({
  [DateRange.DAY]: {
    id: "dashboard.enum.dateRange.day",
    defaultMessage: "Day",
    description: "Label for the Day DateRange value",
  },
  [DateRange.MONTH]: {
    id: "dashboard.enum.dateRange.month",
    defaultMessage: "Month",
    description: "Label for the Month DateRange value",
  },
  [DateRange.QUARTER]: {
    id: "dashboard.enum.dateRange.quarter",
    defaultMessage: "Quarter",
    description: "Label for the Quarter DateRange value",
  },
  [DateRange.WEEK]: {
    id: "dashboard.enum.dateRange.week",
    defaultMessage: "Week",
    description: "Label for the Week DateRange value",
  },
  [DateRange.YEAR]: {
    id: "dashboard.enum.dateRange.year",
    defaultMessage: "Year",
    description: "Label for the Year DateRange value",
  },
});

export const getDateRangeDisplayName = (intl: IntlShape, dateRange: DateRange) =>
  intl.formatMessage(rangeMessages[dateRange]);

const subRangeMessages = defineMessages<`${DateRange}_${DateSubRange}`>({
  [`${DateRange.DAY}_${DateSubRange.LAST}` as const]: {
    id: "dashboard.enum.dateRange.day.dateSubRange.last",
    defaultMessage: "Last full Day",
    description: "Label for the Last Day DateRange value",
  },
  [`${DateRange.DAY}_${DateSubRange.THIS}` as const]: {
    id: "dashboard.enum.dateRange.day.dateSubRange.this",
    defaultMessage: "Current Day",
    description: "Label for the This Day DateRange value",
  },
  [`${DateRange.MONTH}_${DateSubRange.LAST}` as const]: {
    id: "dashboard.enum.dateRange.month.day.dateSubRange.last",
    defaultMessage: "Last full Month",
    description: "Label for the Last Month DateRange value",
  },
  [`${DateRange.MONTH}_${DateSubRange.THIS}` as const]: {
    id: "dashboard.enum.dateRange.month.dateSubRange.this",
    defaultMessage: "Month to date",
    description: "Label for the This Month DateRange value",
  },
  [`${DateRange.QUARTER}_${DateSubRange.LAST}` as const]: {
    id: "dashboard.enum.dateRange.quarter.day.dateSubRange.last",
    defaultMessage: "Last full Quarter",
    description: "Label for the Last Quarter DateRange value",
  },
  [`${DateRange.QUARTER}_${DateSubRange.THIS}` as const]: {
    id: "dashboard.enum.dateRange.quarter.dateSubRange.this",
    defaultMessage: "Quarter to date",
    description: "Label for the This Quarter DateRange value",
  },
  [`${DateRange.WEEK}_${DateSubRange.LAST}` as const]: {
    id: "dashboard.enum.dateRange.week.day.dateSubRange.last",
    defaultMessage: "Last full Week",
    description: "Label for the Last Week DateRange value",
  },
  [`${DateRange.WEEK}_${DateSubRange.THIS}` as const]: {
    id: "dashboard.enum.dateRange.week.dateSubRange.this",
    defaultMessage: "Current Week",
    description: "Label for the This Week DateRange value",
  },
  [`${DateRange.YEAR}_${DateSubRange.LAST}` as const]: {
    id: "dashboard.enum.dateRange.year.day.dateSubRange.last",
    defaultMessage: "Last full Year",
    description: "Label for the Last Year DateRange value",
  },
  [`${DateRange.YEAR}_${DateSubRange.THIS}` as const]: {
    id: "dashboard.enum.dateRange.year.dateSubRange.this",
    defaultMessage: "Year to date",
    description: "Label for the This Year DateRange value",
  },
});

export const getDateSubRangeDisplayName = (
  intl: IntlShape,
  dateRange: DateRange,
  dateSubRange: DateSubRange
) => intl.formatMessage(subRangeMessages[`${dateRange}_${dateSubRange}`]);
