import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const upcomingActivitiesState = (state: RootState) => state.scene.dashboard.upcomingActivitiesState;

export const getUpcomingActivitiesDrillDownViewState = createSelector(
  upcomingActivitiesState,
  ({ viewState }) => viewState
);

export const getUpcomingActivitiesDrillDownTotalFilteredRecords = createSelector(
  upcomingActivitiesState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getUpcomingActivitiesDrillDownTotalRecords = createSelector(
  upcomingActivitiesState,
  ({ totalRecords }) => totalRecords
);

export const getUpcomingActivitiesDrillDownConfiguration = createSelector(
  upcomingActivitiesState,
  ({ configuration }) => configuration
);

export const getUpcomingActivitiesMapBounds = createSelector(
  upcomingActivitiesState,
  ({ bounds }) => bounds
);

export const isUpcomingActivitiesDrillDownLoading = createSelector(
  upcomingActivitiesState,
  ({ loading }) => loading
);
