import React, { Fragment, useMemo } from "react";
import { connect } from "react-redux";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons/faStopwatch";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared/enum";
import User from "@mapmycustomers/shared/types/User";
import { Avatar, AvatarWithName } from "@mapmycustomers/ui";

import EntityBadge from "@app/component/EntityBadge";
import {
  isSearchActivity,
  isSearchCompany,
  isSearchDeal,
  isSearchGroup,
  isSearchPerson,
  isSearchRoute,
  isSearchTerritory,
} from "@app/component/GlobalSearch/util/assert";
import UserPreviewCard from "@app/component/UserPreviewCard";
import { getUsers } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import GlobalSearchItem from "@app/types/globalSearch/GlobalSearchItem";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";
import { formatDate, getUserDisplayNameWithInfo } from "@app/util/formatters";
import { parseApiDate } from "@app/util/parsers";
import getShortAddress from "@app/util/ui/getShortAddress";

import styles from "./Title.module.scss";

interface Props {
  item: GlobalSearchItem;
  users: User[];
}

const Title: React.FC<Props> = ({ item, users }) => {
  const intl = useIntl();

  const addressField = item.entityType
    ? // we're fine to get undefined here if item.entityType is not among those
      // understood by getFieldModelByEntityType
      // @ts-ignore
      getFieldModelByEntityType(item.entityType)?.getByName("address")
    : undefined;
  const isAddressReadble = addressField?.isReadable ?? false;

  const secondaryPart = useMemo(() => {
    let parts: React.ReactNode[] = [];
    const entity = item.entity;

    if (entity) {
      if (isSearchCompany(entity)) {
        if (isAddressReadble) {
          parts.push(getShortAddress(entity));
        }
        parts.push(entity.phone ?? "");
        parts.push(entity.email ?? "");
      } else if (isSearchPerson(entity)) {
        if (isAddressReadble) {
          parts.push(getShortAddress(entity));
        }
        parts.push(
          entity.primaryAccount ? (
            <span className={styles.primaryCompany}>
              <FontAwesomeIcon className={styles.star} icon={faStar} />
              {entity.primaryAccount.name}
            </span>
          ) : (
            ""
          )
        );
        parts.push(entity.phone ?? "");
        parts.push(entity.email ?? "");
      } else if (isSearchDeal(entity)) {
        if (entity.stage) {
          parts.push(<EntityBadge entity={entity.stage} />);
        }
        parts.push(entity.funnel?.name ?? "");
        parts.push(entity.account?.name ?? "");
        parts.push(entity.contact?.name ?? "");
      } else if (isSearchGroup(entity)) {
        if (entity.cadenceInterval) {
          parts.push(
            <span className={styles.cadenceValue}>
              <FontAwesomeIcon icon={faStopwatch} />
              {intl.formatMessage(
                {
                  id: "component.globalSearch.item.group.cadenceInterval",
                  defaultMessage: "Every {count} {count, plural, one {day} other {days}}",
                  description: "Cadence details interval row value title on global search item",
                },
                { count: entity.cadenceInterval }
              )}
            </span>
          );
        }
        if (entity.user) {
          parts.push(
            <UserPreviewCard userId={entity.user.id}>
              <div>
                <AvatarWithName
                  actionOnHover
                  avatarClassName={styles.userAvatar}
                  user={entity.user}
                />
              </div>
            </UserPreviewCard>
          );
        }
      } else if (isSearchActivity(entity)) {
        parts.push(entity.name);
        if (entity.startAt) {
          parts.push(formatDate(parseApiDate(entity.startAt), "PPP"));
        }
        if (entity.completed) {
          parts.push(
            <span className={styles.activityDone}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>
                {intl.formatMessage({
                  id: "component.globalSearch.item.activity.done",
                  defaultMessage: "Done",
                  description: "Activity done on global search item",
                })}
              </span>
            </span>
          );
        }
      } else if (isSearchTerritory(entity)) {
        const userIds = new Set(entity.userIds);
        const territoryUsers = users.filter(({ id }) => userIds.has(id));
        const firstUsers = territoryUsers.slice(0, 3);
        const restUsers = territoryUsers.slice(3);
        parts.push(
          <span className={styles.territoryUsers}>
            {firstUsers.map((user) => (
              <UserPreviewCard key={user.id} userId={user.id}>
                <Avatar actionOnHover className={styles.userAvatar} user={user} />
              </UserPreviewCard>
            ))}
            {restUsers.length ? (
              <Tooltip
                title={restUsers.map((user) => getUserDisplayNameWithInfo(intl, user)).join(", ")}
              >
                {intl.formatMessage(
                  {
                    id: "component.globalSearch.item.territory.more",
                    defaultMessage: "+ {count} more...",
                    description: "Territory users more on global search item",
                  },
                  { count: restUsers.length }
                )}
              </Tooltip>
            ) : null}
          </span>
        );
      } else if (isSearchRoute(entity)) {
        parts.push(
          intl.formatMessage(
            {
              id: "component.globalSearch.item.routes.stops",
              defaultMessage: "{count} {count, plural, one {Stop} other {Stops}}",
              description: "Route stops count on global search item",
            },
            { count: entity.items }
          )
        );
        parts.push(
          getShortAddress(entity.routeDetail.geoAddress) || entity.routeDetail.startAddress
        );
      }
    }

    parts = parts.filter((part) => (part?.toString().trim().length ?? 0) > 0);
    return parts.map((part, i) => (
      <Fragment key={i}>
        {part}
        {i < parts.length - 1 ? <span className={styles.partsSeparator}>•</span> : ""}
      </Fragment>
    ));
  }, [intl, isAddressReadble, item, users]);

  const nameField = item.entityType
    ? // We don't really care about exact match of entity types here. If some other
      // type is used which doesn't have a field model, we'll get undefined,
      // which is totally fine and expected.
      // @ts-ignore
      getFieldModelByEntityType(item.entityType)?.getByName(
        item.entityType === EntityType.PERSON ? PersonFieldName.FIRST_NAME : "name"
      )
    : undefined;

  return (
    <div className={styles.container}>
      <div className={styles.mainPart}>
        <Tooltip
          title={intl.formatMessage({
            id: "component.globalSearch.title.hiddenNameTooltip",
            defaultMessage: "Protected Information",
            description:
              "Protected information tooltip in GlobalSearch result title when the name field is hidden",
          })}
          trigger={nameField && !nameField.isReadable ? ["hover"] : []}
        >
          {item.title}
        </Tooltip>
      </div>
      <div className={styles.secondaryPart}>{secondaryPart}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: getUsers(state),
});

export default connect(mapStateToProps)(Title);
