import { notification } from "antd";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import PinLegend, {
  ColorPinLegend,
  ShapePinLegend,
} from "@mapmycustomers/shared/types/map/PinLegend";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { isApiError } from "@mapmycustomers/shared/util/assert";

import i18nService from "@app/config/I18nService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import {
  createColorPinLegend,
  createShapePinLegend,
  deletePinLegend,
  fetchPinLegends,
  refreshPinLegend,
  updateColorPinLegend,
  updateShapePinLegend,
} from "@app/store/pinLegends/actions";

export function* onFetchPinLegends() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<PinLegend> = yield callApi("fetchPinLegends", orgId);

    yield put(fetchPinLegends.success(response.data));
  } catch (error) {
    yield put(fetchPinLegends.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCreateColorPinLegend({
  payload: { callback, payload },
}: ReturnType<typeof createColorPinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const pinLegend: ColorPinLegend = yield callApi("createColorPinLegend", orgId, payload);
    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        description: intl.formatMessage({
          id: "pinLegends.createColorPinLegend.success.description",
          defaultMessage: "Edit the preferences to personalize the legend.",
          description: "Text of success notification when pin legend created successfully",
        }),
        message: intl.formatMessage({
          id: "pinLegends.createColorPinLegend.success.message",
          defaultMessage: "Pin Legend Created",
          description: "Title of success notification when pin legend created successfully",
        }),
      });
    }
    if (callback) {
      yield call(callback, pinLegend);
    }
    yield put(createColorPinLegend.success(pinLegend));
  } catch (error) {
    yield put(createColorPinLegend.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateColorPinLegend({
  payload: { callback, conflictCallback, payload },
}: ReturnType<typeof updateColorPinLegend.request>) {
  try {
    console.log("!!! saga", JSON.parse(JSON.stringify(payload)));
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const pinLegend: ColorPinLegend = yield callApi("updateColorPinLegend", orgId, payload);
    if (callback) {
      yield call(callback, pinLegend);
    }
    yield put(updateColorPinLegend.success(pinLegend));
  } catch (error) {
    yield put(updateColorPinLegend.failure(error));
    const hasConflict = isApiError(error) && error.status === 409;
    if (hasConflict) {
      if (conflictCallback) {
        yield call(conflictCallback);
      }
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onCreateShapePinLegend({
  payload: { callback, payload },
}: ReturnType<typeof createShapePinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const pinLegend: ShapePinLegend = yield callApi("createShapePinLegend", orgId, payload);
    const intl = i18nService.getIntl();
    if (intl) {
      notification.success({
        description: intl.formatMessage({
          id: "pinLegends.createShapePinLegend.success.description",
          defaultMessage: "Edit the preferences to personalize the legend.",
          description: "Text of success notification when pin legend created successfully",
        }),
        message: intl.formatMessage({
          id: "pinLegends.createShapePinLegend.success.message",
          defaultMessage: "Pin Legend Created",
          description: "Title of success notification when pin legend created successfully",
        }),
      });
    }
    if (callback) {
      yield call(callback, pinLegend);
    }
    yield put(createShapePinLegend.success(pinLegend));
  } catch (error) {
    yield put(createShapePinLegend.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateShapePinLegend({
  payload: { callback, conflictCallback, payload },
}: ReturnType<typeof updateShapePinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const pinLegend: ShapePinLegend = yield callApi("updateShapePinLegend", orgId, payload);
    if (callback) {
      yield call(callback, pinLegend);
    }
    yield put(updateShapePinLegend.success(pinLegend));
  } catch (error) {
    yield put(updateShapePinLegend.failure(error));
    const hasConflict = isApiError(error) && error.status === 409;
    if (hasConflict) {
      if (conflictCallback) {
        yield call(conflictCallback);
      }
    } else {
      yield put(handleError({ error }));
    }
  }
}

export function* onDeletePinLegend({
  payload: { callback, pinLegendId },
}: ReturnType<typeof deletePinLegend.request>) {
  const intl = i18nService.getIntl();
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("deletePinLegend", orgId, pinLegendId);
    if (callback) {
      yield call(callback);
    }

    if (intl) {
      notification.success({
        message: intl.formatMessage({
          id: "pinLegends.deleteColorPinLegend.success",
          defaultMessage: "Successfully deleted pin legend.",
          description: "Text of success notification when pin legend deleted successfully",
        }),
      });
    }
    yield put(deletePinLegend.success(pinLegendId));
  } catch (error) {
    yield put(deletePinLegend.failure(error));
    if (intl) {
      notification.error({
        message: intl.formatMessage({
          id: "pinLegends.deleteColorPinLegend.error",
          defaultMessage: "Error deleting pin legend. Please try again.",
          description: "Text of error notification when pin legend deleted error",
        }),
      });
    }
    yield put(handleError({ error }));
  }
}

export function* onRefreshPinLegend({
  payload: { id, callback },
}: ReturnType<typeof refreshPinLegend.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const [pinLegend, stats]: [PinLegend, PinLegend["stats"]] = yield all([
      callApi("fetchPinLegend", orgId, id),
      callApi("updatePinLegendStats", orgId, id),
    ]);
    pinLegend.stats = stats;
    yield put(refreshPinLegend.success(pinLegend));
    if (callback) {
      yield call(callback, pinLegend);
    }
  } catch (error) {
    yield put(refreshPinLegend.failure(error));
    yield put(handleError({ error }));
  }
}

export function* pinLegendsSagas() {
  yield takeLatest(fetchPinLegends.request, onFetchPinLegends);
  yield takeEvery(createColorPinLegend.request, onCreateColorPinLegend);
  yield takeEvery(updateColorPinLegend.request, onUpdateColorPinLegend);
  yield takeEvery(createShapePinLegend.request, onCreateShapePinLegend);
  yield takeEvery(updateShapePinLegend.request, onUpdateShapePinLegend);
  yield takeEvery(deletePinLegend.request, onDeletePinLegend);
  yield takeEvery(refreshPinLegend.request, onRefreshPinLegend);
}
