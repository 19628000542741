import { useCallback, useMemo } from "react";

import Identified from "@mapmycustomers/shared/types/base/Identified";
import Named from "@mapmycustomers/shared/types/base/Named";
import { createPrimaryCompanyComparator } from "@mapmycustomers/shared/util/comparator";

const useEntitySorter = <T extends Identified & Named>(
  entities: T[],
  createdEntities: T[],
  isFilterApplied: boolean,
  selectedEntities?: T[],
  associatedEntities?: T[],
  primaryCompanyId?: T["id"]
) => {
  const selectedEntitiesIds = useMemo(
    () => (selectedEntities ?? []).map(({ id }) => id),
    [selectedEntities]
  );

  const associatedEntitiesIds = useMemo(
    () => (associatedEntities ?? []).map(({ id }) => id),
    [associatedEntities]
  );

  const createdEntitiesIds = useMemo(
    () => (createdEntities ?? []).map(({ id }) => id),
    [createdEntities]
  );

  const compareByCreatedEntities = useCallback(
    (a: T, b: T) => {
      if (createdEntitiesIds.includes(a.id)) return createdEntitiesIds.includes(b.id) ? 0 : -1;
      if (createdEntitiesIds.includes(b.id)) return 1;
      return 0;
    },
    [createdEntitiesIds]
  );

  const compareByAssociatedEntities = useCallback(
    (a: T, b: T) => {
      if (associatedEntitiesIds.length) {
        if (associatedEntitiesIds.includes(a.id))
          return associatedEntitiesIds.includes(b.id) ? 0 : -1;
        if (associatedEntitiesIds.includes(b.id)) return 1;
      }
      return 0;
    },
    [associatedEntitiesIds]
  );

  const compareBySelectedEntities = useCallback(
    (a: T, b: T) => {
      if (selectedEntitiesIds.length) {
        if (selectedEntitiesIds.includes(a.id)) return selectedEntitiesIds.includes(b.id) ? 0 : -1;
        if (selectedEntitiesIds.includes(b.id)) return 1;
      }
      return 0;
    },
    [selectedEntitiesIds]
  );

  const compareByName = useCallback((a: T, b: T) => (a.name ?? "").localeCompare(b.name ?? ""), []);

  return useMemo(() => {
    // take all new entities and existing entities (avoid duplicates though) and sort them
    // however if filter is applied, do not include createdEntities (and do not sort, since entities are
    // assumed to be sorted by name already)
    return isFilterApplied
      ? entities
      : [
          ...(selectedEntities ?? []),
          ...(associatedEntities ?? []).filter(({ id }) => !selectedEntitiesIds?.includes(id)),
          ...createdEntities,
          ...entities.filter(
            ({ id }) =>
              !associatedEntitiesIds?.includes(id) &&
              !createdEntitiesIds.includes(id) &&
              !selectedEntitiesIds?.includes(id)
          ),
        ].sort((a, b) => {
          return (
            createPrimaryCompanyComparator(primaryCompanyId)(a, b) ||
            compareBySelectedEntities(a, b) ||
            compareByAssociatedEntities(a, b) ||
            compareByCreatedEntities(a, b) ||
            compareByName(a, b)
          );
        });
  }, [
    isFilterApplied,
    entities,
    selectedEntities,
    associatedEntities,
    createdEntities,
    selectedEntitiesIds,
    associatedEntitiesIds,
    createdEntitiesIds,
    primaryCompanyId,
    compareBySelectedEntities,
    compareByAssociatedEntities,
    compareByCreatedEntities,
    compareByName,
  ]);
};

export default useEntitySorter;
