import { call, put, select, takeLatest } from "redux-saga/effects";

import Note from "@mapmycustomers/shared/types/entity/common/Note";
import Organization from "@mapmycustomers/shared/types/Organization";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganization } from "@app/store/iam";

import { createNote } from "./actions";

export function* onCreateNote({ payload }: ReturnType<typeof createNote.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const { callback, entityId, entityType, note } = payload;
    const newNote: Note = yield callApi("createNote", org.id, entityType, entityId, note);
    yield call(callback, newNote);
    yield put(createNote.success(newNote));
  } catch (error) {
    yield put(createNote.failure());
    yield put(handleError({ error }));
  }
}

export function* addNoteModalSaga() {
  yield takeLatest(createNote.request, onCreateNote);
}
