import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Deal } from "@mapmycustomers/shared/types/entity";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import ClosedStageDealAmountCardData from "@app/scene/dashboard/store/cards/closedStageDealAmount/ClosedStageDealAmountCardData";
import { DrillDownListFetcherPayload } from "@app/scene/dashboard/types/DrillDownListFetcher";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import ClosedStageDealAmountCardConfiguration from "@app/types/dashboard/cards/closedStageDealAmount/ClosedStageDealAmountCardConfiguration";
import Scope from "@app/types/dashboard/Scope";

export const fetchClosedStageDealAmountCardData = createAction(
  "@dashboard/fetchClosedStageDealAmountCardData"
)<{
  callback: (data: ClosedStageDealAmountCardData) => void;
  configuration: ClosedStageDealAmountCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showClosedStageDealAmountDrillDown = createAsyncAction(
  "@dashboard/showClosedStageDealAmountCardDrillDownModal/request",
  "@dashboard/showClosedStageDealAmountCardDrillDownModal/success",
  "@dashboard/showClosedStageDealAmountCardDrillDownModal/failure"
)<
  {
    configuration: ClosedStageDealAmountCardConfiguration;
    customDateRange?: DateRange;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideClosedStageDealAmountDrillDown = createAction(
  "@dashboard/hideClosedStageDealAmountCardDrillDownModal"
)<void>();

export const fetchClosedStageDealAmountCardDrillDownData = createAsyncAction(
  "@dashboard/fetchClosedStageDealAmountCardDrillDownData/request",
  "@dashboard/fetchClosedStageDealAmountCardDrillDownData/success",
  "@dashboard/fetchClosedStageDealAmountCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<Deal>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyClosedStageDealAmountDrillDownListViewSettings = createAction(
  "@dashboard/applyClosedStageDealAmountDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportClosedStageDealAmountCardDrillDownData = createAction(
  "@dashboard/exportClosedStageDealAmountCardData"
)<{
  configuration: ClosedStageDealAmountCardConfiguration;
  scope: Scope;
}>();

export type ClosedStageDealAmountCardActions = ActionType<
  | typeof applyClosedStageDealAmountDrillDownListViewSettings
  | typeof exportClosedStageDealAmountCardDrillDownData
  | typeof fetchClosedStageDealAmountCardData
  | typeof fetchClosedStageDealAmountCardDrillDownData
  | typeof hideClosedStageDealAmountDrillDown
  | typeof showClosedStageDealAmountDrillDown
>;
