import React, { useEffect, useMemo, useState } from "react";

import Select from "antd/es/select";
import Row from "antd/lib/grid/row";
import cn from "classnames";
import { useIntl } from "react-intl";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import { NumberField } from "@mapmycustomers/ui";

import IntervalUnit, { getIntervalUnitDisplayName } from "@app/enum/IntervalUnit";

import styles from "./IntervalFilter.module.scss";

export const INTERVAL_FILTER_OPERATORS = [
  FilterOperator.INTERVAL_WITHIN_LAST,
  FilterOperator.INTERVAL_WITHIN_NEXT,
  FilterOperator.INTERVAL_BEFORE,
  FilterOperator.INTERVAL_AFTER,
];

interface IntervalFilterProps extends IFilterComponentProps {}

const IntervalFilter: IFilterInstance = {
  doesSupportValue: (value: any, operator: FilterOperator) => {
    return (
      typeof value === "object" &&
      (([FilterOperator.INTERVAL_WITHIN_LAST, FilterOperator.INTERVAL_WITHIN_NEXT].includes(
        operator
      ) &&
        value.unit &&
        value.from &&
        value.to) ||
        ([FilterOperator.INTERVAL_AFTER, FilterOperator.INTERVAL_BEFORE].includes(operator) &&
          value.unit &&
          value.value))
    );
  },
  getComponent:
    (): React.FC<IntervalFilterProps> =>
    ({ className, onChange, value }) => {
      const intl = useIntl();

      const [unit, setUnit] = useState<IntervalUnit>(value.value?.unit ?? IntervalUnit.DAY);
      const [count, setCount] = useState<number | undefined>(
        value.operator === FilterOperator.INTERVAL_WITHIN_LAST
          ? value.value?.from !== undefined
            ? -value.value.from
            : 1
          : value.operator === FilterOperator.INTERVAL_WITHIN_NEXT
          ? value.value?.to ?? 1
          : value.operator === FilterOperator.INTERVAL_BEFORE
          ? value.value?.value !== undefined
            ? -value.value.value
            : 0
          : value.value?.value ?? 0
      );

      useEffect(() => {
        if (count === undefined) {
          return; // do not notify
        }

        if (
          [FilterOperator.INTERVAL_WITHIN_LAST, FilterOperator.INTERVAL_WITHIN_NEXT].includes(
            value.operator
          )
        ) {
          onChange?.({
            operator: value.operator,
            value: {
              from: value.operator === FilterOperator.INTERVAL_WITHIN_LAST ? -count : 0,
              to: value.operator === FilterOperator.INTERVAL_WITHIN_LAST ? 0 : count,
              unit,
            },
          });
        } else {
          onChange?.({
            operator: value.operator,
            value: {
              unit,
              value: value.operator === FilterOperator.INTERVAL_BEFORE ? -count : count,
            },
          });
        }
      }, [count, onChange, unit, value.operator]);

      const intervalOptions = useMemo(
        () =>
          [
            IntervalUnit.HOUR,
            IntervalUnit.DAY,
            IntervalUnit.WEEK,
            IntervalUnit.MONTH,
            IntervalUnit.YEAR,
          ].map((unit) => ({
            label: getIntervalUnitDisplayName(intl, unit, count),
            value: unit,
          })),
        [count, intl]
      );

      return (
        <Row className={cn(styles.container, className)}>
          <NumberField
            inputClassName={styles.unitCounter}
            min={0}
            onChange={setCount}
            size="middle"
            value={count}
          />
          <Select<IntervalUnit>
            className={styles.unitSelector}
            onChange={setUnit}
            options={intervalOptions}
            value={unit}
          />
          {value.operator === FilterOperator.INTERVAL_BEFORE && (
            <div className={styles.suffix}>
              {intl.formatMessage({
                id: "filters.interval.suffix",
                defaultMessage: "ago",
                description: "A suffix to show for the 'was more than' operator",
              })}
            </div>
          )}
        </Row>
      );
    },
};

export default IntervalFilter;
