import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Deal } from "@mapmycustomers/shared/types/entity";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import ClosedStageDealCountCardData from "@app/scene/dashboard/store/cards/closedStageDealCount/ClosedStageDealCountCardData";
import { DrillDownListFetcherPayload } from "@app/scene/dashboard/types/DrillDownListFetcher";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import ClosedStageDealCountCardConfiguration from "@app/types/dashboard/cards/closedStageDealCount/ClosedStageDealCountCardConfiguration";
import Scope from "@app/types/dashboard/Scope";

export const fetchClosedStageDealCountCardData = createAction(
  "@dashboard/fetchClosedStageDealCountCardData"
)<{
  callback: (data: ClosedStageDealCountCardData) => void;
  configuration: ClosedStageDealCountCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showClosedStageDealCountDrillDown = createAsyncAction(
  "@dashboard/showClosedStageDealCountCardDrillDownModal/request",
  "@dashboard/showClosedStageDealCountCardDrillDownModal/success",
  "@dashboard/showClosedStageDealCountCardDrillDownModal/failure"
)<
  {
    configuration: ClosedStageDealCountCardConfiguration;
    customDateRange?: DateRange;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideClosedStageDealCountDrillDown = createAction(
  "@dashboard/hideClosedStageDealCountCardDrillDownModal"
)<void>();

export const fetchClosedStageDealCountCardDrillDownData = createAsyncAction(
  "@dashboard/fetchClosedStageDealCountCardDrillDownData/request",
  "@dashboard/fetchClosedStageDealCountCardDrillDownData/success",
  "@dashboard/fetchClosedStageDealCountCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<Deal>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyClosedStageDealCountDrillDownListViewSettings = createAction(
  "@dashboard/applyClosedStageDealCountDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportClosedStageDealCountCardDrillDownData = createAction(
  "@dashboard/exportClosedStageDealCountCardData"
)<{
  configuration: ClosedStageDealCountCardConfiguration;
  scope: Scope;
}>();

export type ClosedStageDealCountCardActions = ActionType<
  | typeof applyClosedStageDealCountDrillDownListViewSettings
  | typeof exportClosedStageDealCountCardDrillDownData
  | typeof fetchClosedStageDealCountCardData
  | typeof fetchClosedStageDealCountCardDrillDownData
  | typeof hideClosedStageDealCountDrillDown
  | typeof showClosedStageDealCountDrillDown
>;
