import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { EntityType } from "@mapmycustomers/shared";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { Activity } from "@mapmycustomers/shared/types/entity";
import { MapEntry, MultiPin } from "@mapmycustomers/shared/types/map";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";
import PlatformMapRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformMapRequest";

import localSettings from "@app/config/LocalSettings";
import { getDatesRange } from "@app/enum/dashboard/nextDateRanges";
import ViewMode from "@app/enum/dashboard/ViewMode";
import {
  getUpcomingActivitiesDrillDownTotalFilteredRecords,
  getUpcomingActivitiesDrillDownViewState,
  getUpcomingActivitiesMapBounds,
} from "@app/scene/dashboard/store/cards/upcomingActivities/selectors";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import expandColumns from "@app/scene/dashboard/utils/expandColumns";
import getPrecision from "@app/scene/map/utils/getPrecision";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganizationId, isBigOrganization } from "@app/store/iam";
import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";
import loggingService from "@app/util/logging";
import categorizeMapEntries from "@app/util/map/categorizeMapEnties";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import {
  applyUpcomingActivitiesDrillDownListViewSettings,
  exportUpcomingActivitiesCardDrillDownData,
  fetchUpcomingActivitiesCardData,
  fetchUpcomingActivitiesCardDrillDownData,
  showUpcomingActivitiesDrillDown,
} from "./actions";

const LIST_PAGE_SIZE = 20;

function* onFetchUpcomingActivitiesCardData({
  payload,
}: ReturnType<typeof fetchUpcomingActivitiesCardData>) {
  const { callback, configuration, failureCallback, offset, scope, viewMode, viewport } = payload;
  const { criteria, dateRange } = configuration;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const bigOrganization: boolean = yield select(isBigOrganization);

    const range = getDatesRange(dateRange.range, dateRange.subRange);

    const $and = [
      {
        ...(criteria.activityTypeIds.length
          ? {
              crmActivityTypeId: {
                $in: [...criteria.activityTypeIds],
              },
            }
          : {}),
        completed: {
          $eq: "false",
        },
        startAt: {
          $gte: range.startDate,
          $lte: range.endDate,
        },
      },
      ...(!scope?.userId && scope?.teamId ? [{ teamId: { $in: [scope.teamId] } }] : []),
      ...(scope?.userId ? [{ assigneeId: { $in: [scope.userId] } }] : []),
      ...(criteria.sources?.length ? [{ source: { $in: criteria.sources } }] : []),
    ];

    if (viewMode === ViewMode.MAP) {
      const payload: PlatformMapRequest = {
        $filters: {
          $and,
          bounds: viewport?.bounds,
          precision: getPrecision(viewport?.zoom ?? 1, bigOrganization),
          precisionThreshold: 1000,
        },
        $offset: offset,
        $order: "startAt",
      };

      const response: ListResponse<MapEntry> = yield callApi(
        "fetchMappedActivities",
        orgId,
        payload
      );
      const categorizedMapEntries = categorizeMapEntries(response.data);

      yield call(callback, {
        categorizedMapEntries,
        total: response.total,
      });
    } else {
      const activityPayload: Partial<PlatformListRequest> = {
        $filters: {
          $and,
          includeAccessStatus: true,
          includeAccountSharedActivities: true,
        },
        $limit: LIST_PAGE_SIZE,
        $offset: offset,
        $order: "startAt",
      };

      const activitiesResponse: ListResponse<Activity> = yield callApi(
        "fetchActivities",
        orgId,
        activityPayload
      );

      yield call(callback, {
        activities: activitiesResponse.data,
        total: activitiesResponse.total,
      });
    }
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({ payload }: ReturnType<typeof showUpcomingActivitiesDrillDown.request>) {
  const { configuration, scope } = payload;
  const { criteria, dateRange } = configuration;

  const drillDownViewState: DrillDownViewState = yield select(
    getUpcomingActivitiesDrillDownViewState
  );
  const viewState = localSettings.getViewSettings(
    `dashboard/upcomingActivities`,
    activityFieldModel,
    drillDownViewState
  );

  const isTeamScope = !!scope?.teamId && !scope?.userId;
  const range = getDatesRange(dateRange.range, dateRange.subRange);

  const filter: FilterModel = {
    completed: { operator: FilterOperator.EQUALS, value: "false" },
    startAt: { operator: FilterOperator.IN_RANGE, value: [range.startDate, range.endDate] },
  };
  if (criteria.activityTypeIds.length) {
    filter.activityType = { operator: FilterOperator.IN_ANY, value: criteria.activityTypeIds };
  }
  if (isTeamScope) {
    filter.teams = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
  }
  if (scope?.userId) {
    filter.assignee = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
  }
  if (criteria.sources.length) {
    filter.sourceCreated = { operator: FilterOperator.IN_ANY, value: criteria.sources };
  }
  viewState.filter = filter;

  yield put(showUpcomingActivitiesDrillDown.success({ viewState }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchUpcomingActivitiesCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: upcoming activities card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyUpcomingActivitiesDrillDownListViewSettings(payload.request));
    }

    const drillDownViewState: DrillDownViewState = yield select(
      getUpcomingActivitiesDrillDownViewState
    );

    const bounds: MultiPin["bounds"] | undefined = yield select(getUpcomingActivitiesMapBounds);

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(drillDownViewState, `dashboard/upcomingActivities`);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const requestPayload = {
      $filters: {
        bounds,
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          activityFieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const response: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      orgId,
      requestPayload
    );
    payload.dataCallback?.(response);
    yield put(
      fetchUpcomingActivitiesCardDrillDownData.success({
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchUpcomingActivitiesCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

function* onExport() {
  const drillDownViewState: DrillDownViewState = yield select(
    getUpcomingActivitiesDrillDownViewState
  );
  const total: number = yield select(getUpcomingActivitiesDrillDownTotalFilteredRecords);

  yield put(
    exportEntities.request({
      entityType: EntityType.ACTIVITY,
      total,
      viewState: expandColumns(drillDownViewState),
    })
  );
}

export function* upcomingActivitiesSagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchUpcomingActivitiesCardData, onFetchUpcomingActivitiesCardData);
  yield takeLatest(showUpcomingActivitiesDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchUpcomingActivitiesCardDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportUpcomingActivitiesCardDrillDownData, onExport);
}
