import { createReducer } from "typesafe-actions";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import DateRange from "@mapmycustomers/shared/types/range/DateRange";

import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import ClosedStageDealAmountCardConfiguration from "@app/types/dashboard/cards/closedStageDealAmount/ClosedStageDealAmountCardConfiguration";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";
import dealFieldModel, { DealFieldName } from "@app/util/fieldModel/DealFieldModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  applyClosedStageDealAmountDrillDownListViewSettings,
  ClosedStageDealAmountCardActions,
  fetchClosedStageDealAmountCardDrillDownData,
  hideClosedStageDealAmountDrillDown,
  showClosedStageDealAmountDrillDown,
} from "./actions";

const defaultColumns: string[] = [
  DealFieldName.NAME,
  DealFieldName.USER,
  DealFieldName.ADDRESS,
  DealFieldName.AMOUNT,
  DealFieldName.CREATED_AT,
  DealFieldName.SOURCE,
];

const defaultSortColumn = DealFieldName.CREATED_AT;

export interface ClosedStageDealAmountCardState {
  configuration?: ClosedStageDealAmountCardConfiguration; // also works as a "visible" trigger, visible if set
  drillDownCustomDateRange?: DateRange;
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const closedStageDealAmountInitialState: ClosedStageDealAmountCardState = {
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: getDefaultDrillDownColumns(defaultColumns, dealFieldModel),
    filter: {},
    range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
    sort: [
      {
        field: dealFieldModel.getByName(defaultSortColumn)!,
        order: SortOrder.DESC,
      },
    ],
    viewAs: undefined,
  },
};

const closedStageDealAmountState = createReducer<
  ClosedStageDealAmountCardState,
  ClosedStageDealAmountCardActions
>(closedStageDealAmountInitialState)
  .handleAction(showClosedStageDealAmountDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
    drillDownCustomDateRange: payload.customDateRange,
  }))
  .handleAction(showClosedStageDealAmountDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideClosedStageDealAmountDrillDown, (state) => ({
    ...state,
    configuration: undefined,
    drillDownCustomDateRange: undefined,
  }))
  .handleAction(fetchClosedStageDealAmountCardDrillDownData.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchClosedStageDealAmountCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchClosedStageDealAmountCardDrillDownData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyClosedStageDealAmountDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      columns: payload.columns ?? state.viewState.columns,
      filter: payload.filter ?? state.viewState.filter,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }));

export default closedStageDealAmountState;
