import {
  addMinutes,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  format,
  formatDistance,
  formatDistanceStrict,
  formatRelative,
  getDay,
  isYesterday,
} from "date-fns/esm";
import numeral from "numeral";
import { defineMessages, IntlShape } from "react-intl";

import Address from "@mapmycustomers/shared/types/Address";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import Mappable from "@mapmycustomers/shared/types/base/Mappable";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import GeoAddress from "@mapmycustomers/shared/types/GeoAddress";
import User, { UserRef } from "@mapmycustomers/shared/types/User";
import { Nullable } from "@mapmycustomers/shared/util/ts";

import dateFnsLocaleService from "@app/config/DateFnsLocaleService";
import i18nService from "@app/config/I18nService";

import AddressPart from "../enum/AddressPart";

import countryListOptions from "./country-options.json";
import { injectLocale } from "./dates";
import isValidDate from "./isValidDate";
import floorToFixed from "./number/floorToFixed";
import roundToPrecision from "./number/roundToPrecision";
import { parseApiDate } from "./parsers";

export const formatDate: typeof format = (date, formatStr, options) => {
  return format(date, formatStr, injectLocale(options ?? {}));
};

// Same as formatDate but is designed to accept data as string, null, or undefined.
// Also supports returning a defaultValue in case when date is null, undefined, or has invalid format.
export const formatRawDate = (
  dateString: null | string | undefined,
  formatStr: string,
  defaultValue: string = "",
  parser: (dateString: string) => Date = parseApiDate,
  options?: Parameters<typeof format>["2"]
) => {
  if (dateString == null) {
    return defaultValue;
  }
  const date = parser(dateString);
  return isValidDate(date) ? formatDate(date, formatStr, options) : defaultValue;
};

export const formatDateDistance: typeof formatDistance = (date, baseDate, options) => {
  return formatDistance(date, baseDate, injectLocale(options ?? {}));
};

export const formatDateDistanceStrict: typeof formatDistanceStrict = (date, baseDate, options) => {
  return formatDistanceStrict(date, baseDate, injectLocale(options ?? {}));
};

export const formatDateRelative: typeof formatRelative = (date, baseDate, options) => {
  return formatRelative(date, baseDate, injectLocale(options ?? {}));
};

const relativePrefixMessages = defineMessages({
  formatRegular: {
    id: "relativePrefixMessages.message.regular",
    defaultMessage: "eeee, MMMM do",
  },
  formatRelativeLastFriday: {
    id: "relativePrefixMessages.message.relative.last.friday",
    defaultMessage: "'Last Friday'",
  },
  formatRelativeLastMonday: {
    id: "relativePrefixMessages.message.relative.last.monday",
    defaultMessage: "'Last Monday'",
  },
  formatRelativeLastSaturday: {
    id: "relativePrefixMessages.message.relative.last.saturday",
    defaultMessage: "'Last Saturday'",
  },
  formatRelativeLastSunday: {
    id: "relativePrefixMessages.message.relative.last.sunday",
    defaultMessage: "'Last Sunday'",
  },
  formatRelativeLastThursday: {
    id: "relativePrefixMessages.message.relative.last.thursday",
    defaultMessage: "'Last Thursday'",
  },
  formatRelativeLastTuesday: {
    id: "relativePrefixMessages.message.relative.last.tuesday",
    defaultMessage: "'Last Tuesday'",
  },
  formatRelativeLastWednesday: {
    id: "relativePrefixMessages.message.relative.last.wednesday",
    defaultMessage: "'Last Wednesday'",
  },
  formatRelativeNextFriday: {
    id: "relativePrefixMessages.message.relative.next.friday",
    defaultMessage: "'Next Friday'",
  },
  formatRelativeNextMonday: {
    id: "relativePrefixMessages.message.relative.next.monday",
    defaultMessage: "'Next Monday'",
  },
  formatRelativeNextSaturday: {
    id: "relativePrefixMessages.message.relative.next.saturday",
    defaultMessage: "'Next Saturday'",
  },
  formatRelativeNextSunday: {
    id: "relativePrefixMessages.message.relative.next.sunday",
    defaultMessage: "'Next Sunday'",
  },
  formatRelativeNextThursday: {
    id: "relativePrefixMessages.message.relative.next.thursday",
    defaultMessage: "'Next Thursday'",
  },
  formatRelativeNextTuesday: {
    id: "relativePrefixMessages.message.relative.next.tuesday",
    defaultMessage: "'Next Tuesday'",
  },
  formatRelativeNextWednesday: {
    id: "relativePrefixMessages.message.relative.next.wednesday",
    defaultMessage: "'Next Wednesday'",
  },
  formatRelativeToday: {
    id: "relativePrefixMessages.message.relative.today",
    defaultMessage: "'Today'",
  },
  formatRelativeTomorrow: {
    id: "relativePrefixMessages.message.relative.tomorrow",
    defaultMessage: "'Tomorrow'",
  },
  formatRelativeYesterday: {
    id: "relativePrefixMessages.message.relative.yesterday",
    defaultMessage: "'Yesterday'",
  },
});

const relativePrefixFormat = {
  "-1": [
    relativePrefixMessages.formatRelativeLastSunday,
    relativePrefixMessages.formatRelativeLastMonday,
    relativePrefixMessages.formatRelativeLastTuesday,
    relativePrefixMessages.formatRelativeLastWednesday,
    relativePrefixMessages.formatRelativeLastThursday,
    relativePrefixMessages.formatRelativeLastFriday,
    relativePrefixMessages.formatRelativeLastSaturday,
  ],
  "1": [
    relativePrefixMessages.formatRelativeNextSunday,
    relativePrefixMessages.formatRelativeNextMonday,
    relativePrefixMessages.formatRelativeNextTuesday,
    relativePrefixMessages.formatRelativeNextWednesday,
    relativePrefixMessages.formatRelativeNextThursday,
    relativePrefixMessages.formatRelativeNextFriday,
    relativePrefixMessages.formatRelativeNextSaturday,
  ],
};

export const getRelativeMessagePrefix = (
  date: Date,
  defaultFormat = relativePrefixMessages.formatRegular
) => {
  const diffFromToday = differenceInCalendarDays(date, Date.now());
  let prefixFormat = defaultFormat;
  if (diffFromToday === 0) {
    prefixFormat = relativePrefixMessages.formatRelativeToday;
  } else if (diffFromToday === -1) {
    prefixFormat = relativePrefixMessages.formatRelativeYesterday;
  } else if (diffFromToday === 1) {
    prefixFormat = relativePrefixMessages.formatRelativeTomorrow;
  } else if (diffFromToday < 0 && diffFromToday > -6) {
    prefixFormat = relativePrefixFormat[-1][getDay(date)];
  } else if (diffFromToday > 0 && diffFromToday < 6) {
    prefixFormat = relativePrefixFormat[1][getDay(date)];
  }
  return prefixFormat;
};

export const formatFriendlyRelativeTime = (
  intl: IntlShape,
  dateString: string,
  showTime?: boolean,
  baseDate: Date = new Date()
): string => {
  const date = parseApiDate(dateString);
  const differenceM = differenceInMinutes(date, baseDate);

  if (Math.abs(differenceM) < 1) {
    return intl.formatMessage({
      id: "relativePrefixMessages.date.justNow",
      defaultMessage: "Just now",
      description: "Navbar Notifications - list -  Just now",
    });
  }
  if (Math.abs(differenceM) <= 50) {
    return intl.formatRelativeTime(differenceM, "minute");
  }

  const differenceH = differenceInHours(date, addMinutes(baseDate, 10));
  if (Math.abs(differenceH) < 24) {
    return intl.formatRelativeTime(differenceH, "hour");
  }

  if (isYesterday(date)) {
    return intl.formatMessage({
      id: "relativePrefixMessages.date.yesterday",
      defaultMessage: "Yesterday",
      description: "Navbar Notifications - list -  yesterday",
    });
  }
  if (Math.abs(differenceInDays(endOfDay(date), endOfDay(baseDate))) <= 6) {
    return formatDate(date, `EEEE${showTime ? " p" : ""}`);
  }
  return formatDate(date, `PPP${showTime ? " p" : ""}`);
};

const messages = defineMessages({
  formatRelativeLast: {
    id: "formatRelative.last",
    defaultMessage: "Last",
    description: "FormatRelative last message",
  },
  formatRelativeNext: {
    id: "formatRelative.next",
    defaultMessage: "Next",
    description: "FormatRelative next message",
  },
  formatRelativeToday: {
    id: "formatRelative.today",
    defaultMessage: "Today",
    description: "FormatRelative today message",
  },
  formatRelativeTomorrow: {
    id: "formatRelative.tomorrow",
    defaultMessage: "Tomorrow",
    description: "FormatRelative tomorrow message",
  },
  formatRelativeYesterday: {
    id: "formatRelative.yesterday",
    defaultMessage: "Yesterday",
    description: "FormatRelative yesterday message",
  },
});

export const formatDateRelativeExtended = (
  date: Date | number,
  baseDate: Date | number,
  options?: {
    locale?: Locale;
    useTime?: boolean;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  }
) => {
  const dateFnsLocale = dateFnsLocaleService.locale;

  const intl = i18nService.getIntl();

  const formatRelativeLocale: Record<string, string> = {
    lastWeek: `'${intl?.formatMessage(messages.formatRelativeLast)}' eeee`,
    nextWeek: `'${intl?.formatMessage(messages.formatRelativeNext)}' eeee`,
    today: `'${intl?.formatMessage(messages.formatRelativeToday)}'`,
    tomorrow: `'${intl?.formatMessage(messages.formatRelativeTomorrow)}'`,
    yesterday: `'${intl?.formatMessage(messages.formatRelativeYesterday)}'`,
  };

  const locale = {
    ...dateFnsLocale,
    ...(!options?.useTime
      ? {
          formatRelative: (token: string) => {
            return token in formatRelativeLocale
              ? formatRelativeLocale[token]
              : dateFnsLocale.formatRelative?.(token);
          },
        }
      : {}),
  };
  return formatRelative(date, baseDate, { locale });
};

// Same as formatDate but is designed to accept data as string, null, or undefined.
// Also supports returning a defaultValue in case when date is null, undefined, or has invalid format.
export const formatRawDateRelative = (
  dateString: null | string | undefined,
  baseDate: Date | number,
  defaultValue: string = "",
  distantDateFormat: string = "P",
  parser: (dateString: string) => Date = parseApiDate,
  options?: Parameters<typeof formatDateRelativeExtended>["2"]
) => {
  if (dateString == null) {
    return defaultValue;
  }
  const date = parser(dateString);
  if (!isValidDate(date)) {
    return defaultValue;
  }

  return Math.abs(differenceInDays(Date.now(), date)) < 6
    ? formatDateRelativeExtended(date, Date.now(), options)
    : formatDate(date, distantDateFormat);
};

export const money = (num: number) => numeral(num).format("0,0.00");

export const percent = (num: number) => numeral(num).format("0.00%");

export const distance = (intl: IntlShape, num: number) => {
  if (num < 5) {
    return intl.formatNumber(roundToPrecision(num, 1), {
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    });
  } else if (num < 10) {
    return intl.formatNumber(Math.round(num * 2) / 2, {
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    });
  } else if (num < 50) {
    return intl.formatNumber(Math.round(num));
  } else if (num < 500) {
    return intl.formatNumber(Math.ceil(num / 5) * 5);
  } else if (num < 1000) {
    return intl.formatNumber(Math.ceil(num / 100) * 100);
  }

  return intl.formatNumber(Math.ceil(num / 1000) * 1000);
};

export const userDisplayName = (user: Pick<User, "fullName" | "username">) =>
  user.fullName || user.username;

export const userDisplayNameWithEmail = (user: Pick<User, "fullName" | "username">) =>
  user.fullName ? `${user.fullName} (${user.username})` : `${user.username}`;

export const userEmailishName = (user: Pick<User, "fullName" | "username">) =>
  `${userDisplayName(user)} <${user.username}>`;

export const formatLink = (url: string): string => (/^https?:\/\//.test(url) ? url : `//${url}`);
export const formatEmailLink = (email: string): string => `mailto:${email}`;

// display username for deleted/deactivated users

export const getUserDisplayNameWithInfo = (intl: IntlShape, user: UserRef) =>
  intl.formatMessage(
    {
      id: "userDisplayNameWithInfo",
      defaultMessage:
        "{username}{isDeleted, select, true { (Deleted)} other {}}{isDeactivated, select, true { (Deactivated)} other {}}",
      description: "Full username with meta-info about its delete or deactivated status",
    },
    {
      isDeactivated: !!user.deactivated,
      isDeleted: !!user.deletedAt,
      username: userDisplayName(user),
    }
  );

export const csvBoolFormatter = (value?: boolean) => (value ? "Yes" : value === false ? "No" : "");

export const csvUserFullNameFormatter = (user?: Pick<User, "fullName">) => user?.fullName ?? "";

export const csvDateFormatter = (value?: null | string) =>
  formatRawDate(value, "PP").replaceAll(",", "");

export const csvDateTimeFormatter = (value?: null | string) =>
  formatRawDate(value, "PPp").replaceAll(",", "");

export const csvStageFormatter = (stage?: Stage): string => stage?.name ?? "";

export const csvNumberFormatter = (value?: number): string => (value ? value.toString() : "");

export const entityToAddressLines = (
  entity: Nullable<Address & Partial<Mappable>>,
  parts: Readonly<AddressPart[]>,
  options?: { locationWhenNoAddress?: boolean }
): { line1: string | undefined; line2: string | undefined; line3: string | undefined } => {
  let line1, line2, line3;
  const { address, city, country, postalCode, region } = entity;
  const showAddress = address && parts.includes(AddressPart.ADDRESS);
  const showCity = city && parts.includes(AddressPart.CITY);
  const showState = region && region !== city && parts.includes(AddressPart.STATE);
  const showPostalCode = postalCode && parts.includes(AddressPart.POSTAL_CODE);
  const showCountry = country && parts.includes(AddressPart.COUNTRY);

  if (showAddress) {
    line1 = address ?? undefined;
  }
  if (showCity || showState || showPostalCode) {
    line2 =
      (showCity ? city : "") +
      (showCity && (showState || showPostalCode) ? ", " : "") +
      (showState ? region : "") +
      (showState && showPostalCode ? " " : "") +
      (showPostalCode ? postalCode : "");
  }
  if (showCountry) {
    line3 = country ?? undefined;
  }
  if (
    line1 === undefined &&
    line2 === undefined &&
    line3 === undefined &&
    options?.locationWhenNoAddress &&
    entity.geoPoint?.coordinates
  ) {
    line1 = entity.geoPoint?.coordinates.join(", ");
  }
  return { line1, line2, line3 };
};

export const getFormattedAddressForUi = (address?: GeoAddress): string => {
  if (!address) {
    return "";
  }
  const country =
    countryListOptions.find(({ value }) => value === address.countryCode)?.label ??
    address.countryCode;

  return [
    address.address,
    address.city,
    // Do not put region if it matches the city. Improves readability
    address.city === address.region ? "" : address.region,
    address.fullPostalCode,
    country,
  ]
    .filter((x) => !!x)
    .join(", ");
};

export const formatCoordinates = (coordinates?: LongLat): string => {
  if (coordinates) {
    return `${floorToFixed(coordinates[1], 6)}, ${floorToFixed(coordinates[0], 6)}`;
  }
  return "";
};
