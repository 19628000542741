import { startOfDay } from "date-fns/esm";
import { defineMessages, IntlShape } from "react-intl";

import type DateRangeType from "@mapmycustomers/shared/types/range/DateRange";

import { DateRange, endOfFunction, startOfFunction, subFunction } from "./dateRanges";

export enum NextDateSubRange {
  THIS = "this",
  NEXT = "next",
}

export const ALL_RANGES = Object.values(DateRange).reduce(
  (result, item) => ({
    ...result,
    [item]: Object.values(NextDateSubRange),
  }),
  {} as Record<DateRange, NextDateSubRange[]>
);

const subAmount = {
  [NextDateSubRange.NEXT]: -1,
  [NextDateSubRange.THIS]: 0,
} as const;

export const getDatesRange = (
  nextDateRange: DateRange,
  dateSubRange: NextDateSubRange
): DateRangeType => {
  const startDate =
    dateSubRange === NextDateSubRange.NEXT
      ? startOfFunction[nextDateRange](new Date())
      : startOfDay(new Date());
  const endDate = endOfFunction[nextDateRange](startDate);

  const shiftDate = (date: Date) => subFunction[nextDateRange](date, subAmount[dateSubRange]);

  return { endDate: shiftDate(endDate), startDate: shiftDate(startDate) };
};

const subRangeMessages = defineMessages<`${DateRange}_${NextDateSubRange}`>({
  [`${DateRange.DAY}_${NextDateSubRange.NEXT}` as const]: {
    id: "dashboard.enum.nextDateRange.day.dateSubRange.next",
    defaultMessage: "Tomorrow",
    description: "Label for the Next Day DateRange value",
  },
  [`${DateRange.DAY}_${NextDateSubRange.THIS}` as const]: {
    id: "dashboard.enum.nextDateRange.day.dateSubRange.this",
    defaultMessage: "Today",
    description: "Label for the This Day DateRange value",
  },
  [`${DateRange.MONTH}_${NextDateSubRange.NEXT}` as const]: {
    id: "dashboard.enum.nextDateRange.month.day.dateSubRange.next",
    defaultMessage: "Next month",
    description: "Label for the Next Month DateRange value",
  },
  [`${DateRange.MONTH}_${NextDateSubRange.THIS}` as const]: {
    id: "dashboard.enum.nextDateRange.month.dateSubRange.this",
    defaultMessage: "This month",
    description: "Label for the This Month DateRange value",
  },
  [`${DateRange.QUARTER}_${NextDateSubRange.NEXT}` as const]: {
    id: "dashboard.enum.nextDateRange.quarter.day.dateSubRange.next",
    defaultMessage: "Next Quarter",
    description: "Label for the Next Quarter DateRange value",
  },
  [`${DateRange.QUARTER}_${NextDateSubRange.THIS}` as const]: {
    id: "dashboard.enum.nextDateRange.quarter.dateSubRange.this",
    defaultMessage: "This Quarter",
    description: "Label for the This Quarter DateRange value",
  },
  [`${DateRange.WEEK}_${NextDateSubRange.NEXT}` as const]: {
    id: "dashboard.enum.nextDateRange.week.day.dateSubRange.next",
    defaultMessage: "Next week",
    description: "Label for the Next Week DateRange value",
  },
  [`${DateRange.WEEK}_${NextDateSubRange.THIS}` as const]: {
    id: "dashboard.enum.nextDateRange.week.dateSubRange.this",
    defaultMessage: "This week",
    description: "Label for the This Week DateRange value",
  },
  [`${DateRange.YEAR}_${NextDateSubRange.NEXT}` as const]: {
    id: "dashboard.enum.nextDateRange.year.day.dateSubRange.next",
    defaultMessage: "Next Year",
    description: "Label for the Next Year DateRange value",
  },
  [`${DateRange.YEAR}_${NextDateSubRange.THIS}` as const]: {
    id: "dashboard.enum.nextDateRange.year.dateSubRange.this",
    defaultMessage: "This Year",
    description: "Label for the This Year DateRange value",
  },
});

export const getDateSubRangeDisplayName = (
  intl: IntlShape,
  nextDateRange: DateRange,
  dateSubRange: NextDateSubRange
) => intl.formatMessage(subRangeMessages[`${nextDateRange}_${dateSubRange}`]);
