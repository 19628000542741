import React, { FC, useCallback, useMemo } from "react";

import { LabeledValue, SelectProps } from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import { Company } from "@mapmycustomers/shared/types/entity";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import { createPrimaryCompanyComparator } from "@mapmycustomers/shared/util/comparator";
import { SelectField } from "@mapmycustomers/ui";

import EntityChip from "@app/component/EntityChip";
import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";

import styles from "./BaseCompanyFieldCommon.module.scss";

interface CompaniesSelectInputProps extends SelectProps {
  onChange?: (companies?: CompanyRef[]) => void;
  primaryCompanyId?: Company["id"];
  value?: CompanyRef[];
}

const CompaniesSelectInput: FC<CompaniesSelectInputProps> = ({
  disabled,
  onChange,
  primaryCompanyId,
  value,
  ...props
}) => {
  const intl = useIntl();

  const tagRender = useCallback(
    (props: CustomTagProps) => {
      const { label, value } = props;
      return (
        <Tooltip
          title={
            primaryCompanyId === value
              ? intl.formatMessage({
                  id: "formFields.companiesSelectField.primary",
                  defaultMessage: "Primary Company",
                  description: "Primary Company Tooltip",
                })
              : null
          }
        >
          <EntityChip
            entityId={value}
            entityType={EntityType.COMPANY}
            name={label as string}
            primary={primaryCompanyId === value}
            tooltipProps={{ trigger: [] }}
          />
        </Tooltip>
      );
    },
    [intl, primaryCompanyId]
  );

  const options = useMemo<LabeledValue[]>(
    () =>
      (value ?? [])
        .sort(createPrimaryCompanyComparator(primaryCompanyId))
        .map(({ id, name }) => ({ key: String(id), label: name, value: id })) ?? [],
    [primaryCompanyId, value]
  );

  return (
    <SelectField<Company["id"][]>
      locked={disabled}
      maxTagCount="responsive"
      mode="multiple"
      options={options}
      placeholder={intl.formatMessage({
        id: "formFields.companiesSelectField.select",
        defaultMessage: "Select",
        description: "Title of the Select Parent Company modal",
      })}
      prefixIcon={
        <EntityTypeShapedIcon
          containerClassName={styles.entityIcon}
          entityType={EntityType.COMPANY}
        />
      }
      showArrow={false}
      tagRender={tagRender}
      value={value?.map(({ id }) => id)}
      {...props}
    />
  );
};

export default CompaniesSelectInput;
