import React, { useCallback, useMemo, useState } from "react";

import { faSearch } from "@fortawesome/pro-light-svg-icons/faSearch";
import { faBracketsCurly } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "antd/es/col";
import Dropdown from "antd/es/dropdown";
import Row from "antd/es/row";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { Descendant, Editor, Transforms } from "slate";
import { useSlate } from "slate-react";

import ElementType from "@mapmycustomers/shared/enum/textEditor/ElementType";
import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import { stopEvents } from "@mapmycustomers/shared/util/browser";

import { useConfigProvider } from "../../../../ConfigProvider";
import TextField from "../../../../input/TextField";
import DynamicVarVariant from "../../../type/DynamicVarVariant";
import componentsStyles from "../../Components.module.scss";
import DynamicVarChip from "../../DynamicVars/DynamicVarChip";
import toolbarStyles from "../../Toolbar.module.scss";

import styles from "./DynamicVars.module.scss";

interface DynamicVarsProps {
  dynamicVarVariants: DynamicVarVariant[];
  editorId?: string;
  entityType?: EntityTypesSupportedByEmailFeature;
  onAction?: (actionKey: string) => void;
}

const DynamicVars: React.FC<DynamicVarsProps> = ({
  dynamicVarVariants,
  editorId,
  entityType,
  onAction,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [dropDownVisible, setDropDownVisible] = useState<boolean>(false);
  const editor = useSlate();
  const configProvider = useConfigProvider();

  const filteredVariants = useMemo(() => {
    const text = searchText.trim().toLowerCase();
    return dynamicVarVariants.filter(({ displayName }) => {
      return displayName.toLowerCase().includes(text);
    });
  }, [searchText, dynamicVarVariants]);

  const handleInsert = useCallback(
    ({ name, prefix }: DynamicVarVariant) => {
      setDropDownVisible(false);
      const insert: Descendant[] = [
        {
          children: [{ text: "" }],
          type: ElementType.DYNAMIC_VAR,
          value: `{{${prefix}:${entityType ?? ""}:${name}}}`,
        },
        { text: "" },
      ];
      Transforms.insertNodes(editor, insert);
      Transforms.move(editor, { edge: "end" });

      // move cursor to the end of the inserted dynamic variable
      if (editor.selection) {
        const path = editor.selection.anchor.path;
        Transforms.select(editor, Editor.end(editor, path));
      }

      if (editorId) {
        document.getElementById(editorId)?.focus();
      }

      onAction?.("Insert Dynamic Variable");
    },
    [entityType, editor, editorId, onAction, setDropDownVisible]
  );

  const dropdownRender = useCallback(
    () => (
      <div className={styles.overlayContainer}>
        <div className={styles.overlaySearchContainer}>
          <TextField
            onChange={setSearchText}
            placeholder={configProvider.formatMessage(
              "ui.emailBody.toolbar.actions.dynamicVar.searchPlaceholder"
            )}
            prefix={<FontAwesomeIcon className={styles.searchFieldIcon} icon={faSearch} />}
            value={searchText}
          />
        </div>
        <div className={styles.overlayList}>
          {filteredVariants.length === 0 && (
            <div className={styles.noResult}>
              <div className={styles.noResultTitle}>
                {configProvider.formatMessage("ui.emailBody.toolbar.dynamicVar.noResult.title")}
              </div>
              <div className={styles.noResultSubTitle}>
                {configProvider.formatMessage("ui.emailBody.toolbar.dynamicVar.noResult.subTitle")}
              </div>
            </div>
          )}
          {filteredVariants.map((filteredVariant) => (
            <Row
              className={styles.overlayItem}
              key={filteredVariant.name}
              onClick={() => handleInsert(filteredVariant)}
            >
              <Col offset={2} span={11}>
                <DynamicVarChip
                  allEntitiesHaveValues={filteredVariant.allEntitiesHaveValues}
                  tooltipText={
                    filteredVariant.allEntitiesHaveValues
                      ? undefined
                      : configProvider.formatMessage(
                          "ui.emailBody.toolbar.actions.dynamicVar.someEntityDontHaveValue"
                        )
                  }
                >
                  {filteredVariant.displayName}
                </DynamicVarChip>
              </Col>
              <Col className={styles.overlayItemValue} span={11}>
                {filteredVariant.displayValue}
              </Col>
            </Row>
          ))}
        </div>
      </div>
    ),
    [configProvider, filteredVariants, handleInsert, searchText, setSearchText]
  );

  return (
    <Dropdown
      dropdownRender={dropdownRender}
      onOpenChange={setDropDownVisible}
      open={dropDownVisible}
      placement="topRight"
      trigger={["click"]}
    >
      <Tooltip
        title={configProvider.formatMessage("ui.emailBody.toolbar.actions.insertDynamicVar")}
      >
        <span
          className={cn(toolbarStyles.togglerButton, {
            [componentsStyles.active]: dropDownVisible,
          })}
          onMouseDown={stopEvents}
        >
          <FontAwesomeIcon icon={faBracketsCurly} />
        </span>
      </Tooltip>
    </Dropdown>
  );
};

export default DynamicVars;
