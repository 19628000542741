import React, { FC, ReactElement, ReactNode } from "react";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";

import loggingService from "@app/util/logging";

export interface ConditionalFormFieldRenderProps {
  actualSchema?: LayoutSchemaField[];
  disabled?: boolean;
  isCreateForm?: boolean;
  label?: ReactNode;
  required?: boolean;
  variant?: boolean;
}

export interface ConditionalFormFieldProps extends ConditionalFormFieldRenderProps {
  children?: FC<ConditionalFormFieldRenderProps> | ReactElement<ConditionalFormFieldRenderProps>;
  feature?: FieldFeature;
  fieldName?: string | string[];
}

const ConditionalFormField: React.FC<ConditionalFormFieldProps> = ({ children, ...props }) => {
  if (typeof children === "function") {
    return children(props);
  }

  if (!children || React.Children.count(children) < 1 || React.Children.count(children) > 1) {
    loggingService.error("Invalid number of children in ConditionalFormField", {
      label: props.label,
    });
    return null;
  }

  const childElement = React.Children.only(
    children
  ) as ReactElement<ConditionalFormFieldRenderProps>;
  return React.cloneElement(childElement, props);
};

export default ConditionalFormField;
