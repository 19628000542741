import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import {
  AnyEntity,
  AnyEntityId,
  Deal,
  EntityType,
  EntityTypesSupportingDownloading,
  EntityTypeSupportingReports,
} from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import PlatformBounds from "@mapmycustomers/shared/types/map/PlatformBounds";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import ReportType from "@app/enum/ReportType";
import LeaderboardResponse from "@app/scene/reports/components/Leaderboard/types/LeaderboardResponse";
import Report from "@app/types/Report";
import WeeklyReportSetting from "@app/types/report/WeeklyReportCardSettings";
import LeaderboardViewState from "@app/types/viewModel/LeaderboardViewState";
import { ListFetcherPayload } from "@app/types/viewModel/ListFetcher";

import FilterType from "../components/Export/FilterType";
import ReportsDateRangeType from "../enums/DateRangeType";
import MapFilter from "../enums/MapFilter";
import DateRange from "../types/DateRange";
import { EntityTypeSupportedInOverviewPreview } from "../types/EntityTypeSupportedInOverviewPreview";
import LeaderboardMetricsData from "../types/LeaderboardMetricsData";

import ActivityCardData from "./ActivityCardData";
import FunnelStatsData from "./FunnelStatsData";
import OverviewData from "./OverviewData";
import StageStatsData from "./StageStatsData";

export const fetchFunnelsStats = createAsyncAction(
  "reports/fetchFunnelsStats/request",
  "reports/fetchFunnelsStats/success",
  "reports/fetchFunnelsStats/failure"
)<{ funnel: Funnel; userIds: Array<User["id"]> }, Partial<FunnelStatsData>, void>();

export const fetchStageStats = createAsyncAction(
  "reports/fetchStageStats/request",
  "reports/fetchStageStats/success",
  "reports/fetchStageStats/failure"
)<
  {
    callback: (stageStatsData: Partial<StageStatsData>) => void;
    funnelId?: Funnel["id"];
    stageId?: Stage["id"];
  },
  void,
  void
>();

export const fetchExtraInfo = createAsyncAction(
  "reports/fetchExtraInfo/request",
  "reports/fetchExtraInfo/success",
  "reports/fetchExtraInfo/failure"
)<{ entityId: AnyEntityId; entityType: EntityType }, AnyEntity | undefined, unknown>();

export const initializeOverview = createAction("reports/initializeReportPage")<void>();

export const fetchOverviewCardData = createAsyncAction(
  "reports/fetchOverviewCardData/request",
  "reports/fetchOverviewCardData/success",
  "reports/fetchOverviewCardData/failure"
)<
  { dateRange: DateRange; parentUserIds: Array<User["id"]>; userIds: Array<User["id"]> },
  OverviewData,
  unknown
>();

export const fetchOverviewMapDeals = createAsyncAction(
  "reports/fetchOverviewMapDeals/request",
  "reports/fetchOverviewMapDeals/success",
  "reports/fetchOverviewMapDeals/failure"
)<
  {
    filter?: MapFilter;
    funnelId?: Funnel["id"];
    stageId?: Stage["id"];
    userIds: Array<User["id"]>;
  },
  Deal[],
  unknown
>();

export interface FetchModalPreviewPayload<T extends EntityTypeSupportedInOverviewPreview> {
  dateRange: DateRange;
  entityType: T;
  limit?: number;
  userIds: Array<User["id"]>;
}

export const fetchModalPreviewData = createAsyncAction(
  "reports/fetchModalPreviewData/request",
  "reports/fetchModalPreviewData/success",
  "reports/fetchModalPreviewData/failure"
)<
  FetchModalPreviewPayload<EntityTypeSupportedInOverviewPreview>,
  Partial<OverviewData["modalPreviews"]>,
  unknown
>();

export const downloadModalData = createAsyncAction(
  "reports/downloadModalData/request",
  "reports/downloadModalData/success",
  "reports/downloadModalData/failure"
)<
  {
    dateRange: DateRange;
    entityType: EntityTypeSupportedInOverviewPreview;
    limit?: number;
    reportType: ReportType;
    userIds: Array<User["id"]>;
  },
  Partial<OverviewData["modalPreviews"]>,
  unknown
>();

export const exportModalEntities = createAction("reports/exportModalEntities")<{
  columns: ColumnModel;
  dateRange: DateRange;
  entityType: EntityTypesSupportingDownloading;
  userIds: Array<User["id"]>;
}>();

export const fetchActivityCardData = createAsyncAction(
  "reports/fetchActivityCardData/request",
  "reports/fetchActivityCardData/success",
  "reports/fetchActivityCardData/failure"
)<
  {
    bounds?: PlatformBounds;
    dateRange?: DateRange;
    mapFilter?: MapFilter;
    userIds?: Array<User["id"]>;
    zoom?: number;
  },
  Partial<ActivityCardData>,
  unknown
>();

export const fetchActivityCardListData = createAsyncAction(
  "reports/fetchActivityCardListData/request",
  "reports/fetchActivityCardListData/success",
  "reports/fetchActivityCardListData/failure"
)<
  number, // page number
  Partial<ActivityCardData>,
  unknown
>();

export const fetchDealsMapData = createAsyncAction(
  "reports/fetchDealsMaoData/request",
  "reports/fetchDealsMaoData/success",
  "reports/fetchDealsMaoData/failure"
)<
  { filter: MapFilter; funnelId: Funnel["id"]; stageId?: Stage["id"]; userIds: Array<User["id"]> },
  { deals: Deal[]; total: number },
  void
>();

export const fetchExportCardData = createAsyncAction(
  "reports/fetchExportCardData/request",
  "reports/fetchExportCardData/success",
  "reports/fetchExportCardData/failure"
)<
  Partial<{
    entityType: EntityTypeSupportingReports;
    filterType: FilterType;
    page: number;
    search: string;
    userIds: Array<User["id"]>;
  }>,
  { reports: Report[]; total: number },
  unknown
>();
export const scheduleReport = createAsyncAction(
  "reports/scheduleReport/request",
  "reports/scheduleReport/success",
  "reports/scheduleReport/failure"
)<Omit<Report, "frequency" | "nextRunAt" | "sendOptions"> & Partial<Report>, void, unknown>();

export const downloadReport = createAsyncAction(
  "reports/downloadReport/request",
  "reports/downloadReport/success",
  "reports/downloadReport/failure"
)<Report, void, unknown>();

export const deleteReport = createAsyncAction(
  "reports/deleteReport/request",
  "reports/deleteReport/success",
  "reports/deleteReport/failure"
)<Report, Report["id"], unknown>();

export const cloneReport = createAsyncAction(
  "reports/cloneReport/request",
  "reports/cloneReport/success",
  "reports/cloneReport/failure"
)<{ callback: (report: Report) => void; report: Report }, void, unknown>();

export const fetchReport = createAsyncAction(
  "reports/fetchReport/request",
  "reports/fetchReport/success",
  "reports/fetchReport/failure"
)<Report["id"], Report, unknown>();

export const saveReport = createAsyncAction(
  "reports/saveReport/request",
  "reports/saveReport/success",
  "reports/saveReport/failure"
)<void, void, unknown>();

export const fetchReportRecordsCountInfo = createAsyncAction(
  "reports/fetchReportRecordsCountInfo/request",
  "reports/fetchReportRecordsCountInfo/success",
  "reports/fetchReportRecordsCountInfo/failure"
)<{ callback?: (count: number) => void; report: Report }, void, unknown>();

export const createReport = createAction("reports/createReport")<void>();
export const initializeCreateReport = createAction("reports/initializeCreateReport")<void>();
export const editReport = createAction("reports/editReport")<Report>();

export const changeReportType = createAction(
  "reports/changeReportType"
)<EntityTypeSupportingReports>();
export const changeReportDescription = createAction("reports/changeReportDescription")<string>();
export const changeReportName = createAction("reports/changeReportName")<string>();
export const changeReportId = createAction("reports/changeReportId")<Report["id"] | undefined>();

export const applyPreviewViewSettings = createAction("reports/applyPreviewViewSettings")<
  Partial<ViewState>
>();

export const fetchPreviewData = createAsyncAction(
  "reports/fetchPreviewData/request",
  "reports/fetchPreviewData/success",
  "reports/fetchPreviewData/failure"
)<ListFetcherPayload<AnyEntity>, { reportTotalRowsCount: number }, unknown>();

export const fetchDealsByStage = createAsyncAction(
  "reports/fetchDealsByStage/request",
  "reports/fetchDealsByStage/success",
  "reports/fetchDealsByStage/failure"
)<
  { limit: number; stageId: Stage["id"]; userIds: Array<User["id"]> },
  { deals: Deal[]; stageId: Stage["id"]; total: number },
  unknown
>();

export const fetchDealsGeoChartData = createAsyncAction(
  "reports/fetchDealsGeoChartData/request",
  "reports/fetchDealsGeoChartData/success",
  "reports/fetchDealsGeoChartData/failure"
)<
  { filter: MapFilter; funnelId: Funnel["id"]; userIds: Array<User["id"]> },
  Record<string, number>,
  unknown
>();

export const setDateRange = createAction("reports/setDateRange")<DateRange>();
export const setDateRangeType = createAction("reports/setDateRangeType")<ReportsDateRangeType>();

export const setUserOrTeamId = createAction("reports/setUserOrTeamId")<
  Team["id"] | undefined | User["id"]
>();
export const setUserOrTeamType = createAction("reports/setUserOrTeamType")<string | undefined>();

export const downloadActivityReportData = createAction("reports/activity/download")<{
  columns: ColumnModel;
  entityType: EntityTypesSupportingDownloading;
}>();

export const downloadReportEntities = createAction("reports/downloadReportEntities")<{
  columns: ColumnModel;
  entityType: EntityTypesSupportingDownloading;
  ids: AnyEntity["id"][];
}>();
export const setUserIds = createAction("reports/setUserIds")<User["id"][]>();

export const toggleWeeklyReport = createAsyncAction(
  "reports/toggleWeeklyReport/request",
  "reports/toggleWeeklyReport/success",
  "reports/toggleWeeklyReport/failure"
)<{ enabled: boolean }, void, void>();

export const saveWeeklyReport = createAsyncAction(
  "reports/saveWeeklyReport/request",
  "reports/saveWeeklyReport/success",
  "reports/saveWeeklyReport/failure"
)<WeeklyReportSetting["value"], void, void>();

export const initializeLeaderboard = createAction("reports/leaderboard/initialize")<void>();

export const fetchLeaderboardData = createAsyncAction(
  "reports/leaderboard/fetchData/request",
  "reports/leaderboard/fetchData/success",
  "reports/leaderboard/fetchData/failure"
)<Partial<LeaderboardViewState>, LeaderboardResponse | undefined, void>();

export const fetchLeaderboardMetricsData = createAsyncAction(
  "reports/leaderboard/fetchMetricsData/request",
  "reports/leaderboard/fetchMetricsData/success",
  "reports/leaderboard/fetchMetricsData/failure"
)<
  {
    activityTypeIds?: Array<ActivityType["id"]>;
    funnelIds?: Funnel["id"][];
    selectedMetrics?: LeaderboardMetricFieldName[];
    stageIds?: Stage["id"][];
    teamIds?: Team["id"][];
    userId?: User["id"];
  },
  Partial<Record<LeaderboardMetricFieldName, LeaderboardMetricsData>>,
  void
>();

export const downloadLeaderboardTableData = createAction(
  "reports/leaderboard/downloadTableData"
)<void>();

export type Actions = ActionType<
  | typeof applyPreviewViewSettings
  | typeof changeReportDescription
  | typeof changeReportId
  | typeof changeReportName
  | typeof changeReportType
  | typeof cloneReport
  | typeof createReport
  | typeof deleteReport
  | typeof downloadActivityReportData
  | typeof downloadLeaderboardTableData
  | typeof downloadModalData
  | typeof downloadReport
  | typeof downloadReportEntities
  | typeof editReport
  | typeof fetchActivityCardData
  | typeof fetchActivityCardListData
  | typeof fetchDealsByStage
  | typeof fetchDealsGeoChartData
  | typeof fetchDealsMapData
  | typeof fetchExportCardData
  | typeof fetchExtraInfo
  | typeof fetchFunnelsStats
  | typeof fetchLeaderboardData
  | typeof fetchLeaderboardMetricsData
  | typeof fetchModalPreviewData
  | typeof fetchOverviewCardData
  | typeof fetchOverviewMapDeals
  | typeof fetchPreviewData
  | typeof fetchReport
  | typeof fetchReportRecordsCountInfo
  | typeof fetchStageStats
  | typeof initializeCreateReport
  | typeof initializeLeaderboard
  | typeof saveReport
  | typeof saveWeeklyReport
  | typeof scheduleReport
  | typeof setDateRange
  | typeof setDateRangeType
  | typeof setUserIds
  | typeof toggleWeeklyReport
>;
