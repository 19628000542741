import { defineMessage, defineMessages, IntlShape } from "react-intl";

import CustomFieldDataType from "@mapmycustomers/shared/enum/CustomFieldDataType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import { isCustomField } from "@app/util/fieldModel/impl/assert";

const messages = defineMessages({
  [FilterOperator.AFTER]: {
    id: "filterOperator.after",
    defaultMessage: "is after",
    description: "'Is after' filter operator display name",
  },
  [FilterOperator.BEFORE]: {
    id: "filterOperator.before",
    defaultMessage: "is before",
    description: "'Is before' filter operator display name",
  },
  [FilterOperator.CONTAINS]: {
    id: "filterOperator.contains",
    defaultMessage: "contains",
    description: "'Contains' filter operator display name",
  },
  [FilterOperator.EMPTY]: {
    id: "filterOperator.empty",
    defaultMessage: "is unknown",
    description: "'Empty' filter operator display name",
  },
  [FilterOperator.ENDS_WITH]: {
    id: "filterOperator.endsWith",
    defaultMessage: "ends with",
    description: "'Ends with' filter operator display name",
  },
  [FilterOperator.EQUALS]: {
    id: "filterOperator.equals",
    defaultMessage: "is exactly",
    description: "'Equals' filter operator display name",
  },
  [FilterOperator.GREATER_THAN]: {
    id: "filterOperator.greaterThan",
    defaultMessage: "is greater than",
    description: "'Greater than' filter operator display name",
  },
  [FilterOperator.GREATER_THAN_OR_EQUAL]: {
    id: "filterOperator.greaterThanOrEqual",
    defaultMessage: "is greater than or equal to",
    description: "'Greater than or equal' filter operator display name",
  },
  [FilterOperator.IN_ALL]: {
    id: "filterOperator.inAll",
    defaultMessage: "is in all",
    description: "'In all' filter operator display name",
  },
  [FilterOperator.IN_ANY]: {
    id: "filterOperator.inAny",
    defaultMessage: "is any of",
    description: "'In any' filter operator display name",
  },
  [FilterOperator.IN_AREA]: {
    id: "filterOperator.inArea",
    defaultMessage: "is within",
    description: "'In area' filter operator display name",
  },
  [FilterOperator.IN_RANGE]: {
    id: "filterOperator.inRange",
    defaultMessage: "is in range",
    description: "'In range' filter operator display name",
  },
  [FilterOperator.INTERVAL_AFTER]: {
    id: "filterOperator.interval_after",
    defaultMessage: "is in more than",
    description: "Is within the last operator display name",
  },
  [FilterOperator.INTERVAL_BEFORE]: {
    id: "filterOperator.interval_before",
    defaultMessage: "was more than",
    description: "Is within the next operator display name",
  },
  [FilterOperator.INTERVAL_WITHIN_LAST]: {
    id: "filterOperator.interval_within_last",
    defaultMessage: "is within the last",
    description: "Is within the last operator display name",
  },
  [FilterOperator.INTERVAL_WITHIN_NEXT]: {
    id: "filterOperator.interval_within_next",
    defaultMessage: "is within the next",
    description: "Is within the next operator display name",
  },
  [FilterOperator.IS_FALSE]: {
    id: "filterOperator.isFalse",
    defaultMessage: "is false",
    description: "Is False operator display name",
  },
  [FilterOperator.IS_OVERDUE]: {
    id: "filterOperator.isOverdue",
    defaultMessage: "is past due",
    description: "Is Overdue operator in grid's frequency filter",
  },
  [FilterOperator.IS_OVERDUE_BY_LESS_THAN]: {
    id: "filterOperator.isOverdueLessThan",
    defaultMessage: "is past due by fewer than",
    description: "Overdue less than operator in grid's frequency filter",
  },
  [FilterOperator.IS_OVERDUE_BY_MORE_THAN]: {
    id: "filterOperator.isOverdueMoreThan",
    defaultMessage: "is past due by more than",
    description: "Overdue more than operator in grid's frequency filter",
  },
  [FilterOperator.IS_OVERDUE_IN_LESS_THAN]: {
    id: "filterOperator.isOverdueInLessThan",
    defaultMessage: "is overdue in less than",
    description: "Overdue in less than operator in grid's frequency filter",
  },
  [FilterOperator.IS_OVERDUE_IN_MORE_THAN]: {
    id: "filterOperator.isOverdueInMoreThan",
    defaultMessage: "is overdue in more than",
    description: "Overdue in more than operator in grid's frequency filter",
  },
  [FilterOperator.IS_TRUE]: {
    id: "filterOperator.isTrue",
    defaultMessage: "is true",
    description: "Is True operator display name",
  },
  [FilterOperator.IS_UPCOMING]: {
    id: "filterOperator.isUpcoming",
    defaultMessage: "is not yet past due",
    description: "Is upcoming operator in grid's frequency filter",
  },
  [FilterOperator.LESS_THAN]: {
    id: "filterOperator.lessThan",
    defaultMessage: "is less than",
    description: "'Less than' filter operator display name",
  },
  [FilterOperator.LESS_THAN_OR_EQUAL]: {
    id: "filterOperator.lessThanOrEqual",
    defaultMessage: "is less than or equal to",
    description: "'Less than or equal' filter operator display name",
  },
  [FilterOperator.NONE_OF]: {
    id: "filterOperator.noneOf",
    defaultMessage: "is none of",
    description: "'Is not in' filter operator display name",
  },
  [FilterOperator.NOT_CONTAINS]: {
    id: "filterOperator.notContains",
    defaultMessage: "does not contain",
    description: "'Not contains' filter operator display name",
  },
  [FilterOperator.NOT_EMPTY]: {
    id: "filterOperator.notEmpty",
    defaultMessage: "is known",
    description: "'Not empty' filter operator display name",
  },
  [FilterOperator.NOT_EQUAL]: {
    id: "filterOperator.notEqual",
    defaultMessage: "is not",
    description: "'Not equal' filter operator display name",
  },
  [FilterOperator.NOT_IN]: {
    id: "filterOperator.notIn",
    defaultMessage: "is not in",
    description: "'Is not in' filter operator display name",
  },
  [FilterOperator.NOT_ON]: {
    id: "filterOperator.notOn",
    defaultMessage: "is not on",
    description: "'Is not on' filter operator display name",
  },
  [FilterOperator.ON]: {
    id: "filterOperator.on",
    defaultMessage: "is on",
    description: "'Is on' filter operator display name",
  },
  [FilterOperator.ON_OR_AFTER]: {
    id: "filterOperator.onOrAfter",
    defaultMessage: "is on or after",
    description: "'Is on or after' filter operator display name",
  },
  [FilterOperator.ON_OR_BEFORE]: {
    id: "filterOperator.onOrBefore",
    defaultMessage: "is on or before",
    description: "'Is on or before' filter operator display name",
  },
  [FilterOperator.STARTS_WITH]: {
    id: "filterOperator.startsWith",
    defaultMessage: "starts with",
    description: "'Starts with' filter operator display name",
  },
  [FilterOperator.WILL_BE_OVERDUE]: {
    id: "filterOperator.willBeOverdue",
    defaultMessage: "will be past due",
    description: "Will Be Overdue operator in grid's frequency filter",
  },
});

const timeFieldMessages = defineMessages({
  [FilterOperator.NOT_ON]: {
    id: "filterOperator.notOn.time",
    defaultMessage: "is not at",
    description: "'Is not on' filter operator display name for time fields",
  },
  [FilterOperator.ON]: {
    id: "filterOperator.on.time",
    defaultMessage: "is at",
    description: "'Is on' filter operator display name for time fields",
  },
  [FilterOperator.ON_OR_AFTER]: {
    id: "filterOperator.onOrAfter.time",
    defaultMessage: "is at or after",
    description: "'Is on or after' filter operator display name for time fields",
  },
  [FilterOperator.ON_OR_BEFORE]: {
    id: "filterOperator.onOrBefore.time",
    defaultMessage: "is at or before",
    description: "'Is on or before' filter operator display name for time fields",
  },
});

const noContactNoCheckInFieldMessages = defineMessages({
  [FilterOperator.EQUALS]: {
    id: "filterOperator.equals.noContactOrNoCheckIn",
    defaultMessage: "exactly",
    description: "'Equals' filter operator display name for NoContact and NoCheckIn Field",
  },
  [FilterOperator.GREATER_THAN]: {
    id: "filterOperator.greaterThan.noContactOrNoCheckIn",
    defaultMessage: "greater than",
    description: "'Greater than' filter operator display name for NoContact and NoCheckIn Field",
  },
  [FilterOperator.GREATER_THAN_OR_EQUAL]: {
    id: "filterOperator.greaterThanOrEqual.noContactOrNoCheckIn",
    defaultMessage: "greater than or exactly",
    description:
      "'Greater than or equal' filter operator display name for NoContact and NoCheckIn Field",
  },
  [FilterOperator.LESS_THAN]: {
    id: "filterOperator.lessThan.noContactOrNoCheckIn",
    defaultMessage: "less than",
    description: "'Less than' filter operator display name for NoContact and NoCheckIn Field",
  },
  [FilterOperator.LESS_THAN_OR_EQUAL]: {
    id: "filterOperator.lessThanOrEqual.noContactOrNoCheckIn",
    defaultMessage: "less than or exactly",
    description:
      "'Less than or equal' filter operator display name for NoContact and NoCheckIn Field",
  },
});

const rottingDaysOutFieldMessages = defineMessages({
  [FilterOperator.IS_OVERDUE]: {
    id: "filterOperator.isOverdue.rottingDaysOut",
    defaultMessage: "is rotting",
    description: "Is Overdue operator in grid's rotting filter",
  },
  [FilterOperator.IS_OVERDUE_BY_LESS_THAN]: {
    id: "filterOperator.isOverdueLessThan.rottingDaysOut",
    defaultMessage: "has been rotting for less than",
    description: "Overdue less than operator in grid's rotting filter",
  },
  [FilterOperator.IS_OVERDUE_BY_MORE_THAN]: {
    id: "filterOperator.isOverdueMoreThan.rottingDaysOut",
    defaultMessage: "has been rotting for more than",
    description: "Overdue more than operator in grid's rotting filter",
  },
  [FilterOperator.IS_OVERDUE_IN_LESS_THAN]: {
    id: "filterOperator.isOverdueInLessThan.rottingDaysOut",
    defaultMessage: "will be rotting in less than",
    description: "Overdue in less than operator in grid's rotting filter",
  },
  [FilterOperator.IS_OVERDUE_IN_MORE_THAN]: {
    id: "filterOperator.isOverdueInMoreThan.rottingDaysOut",
    defaultMessage: "will be rotting in more than",
    description: "Overdue in more than operator in grid's rotting filter",
  },
  [FilterOperator.IS_UPCOMING]: {
    id: "filterOperator.isUpcoming.rottingDaysOut",
    defaultMessage: "is not rotting",
    description: "Is upcoming operator in grid's rotting filter",
  },
});

const groupingFieldMessages = defineMessages({
  [FilterOperator.EMPTY]: {
    id: "filterOperator.empty.grouping",
    defaultMessage: "are unknown",
    description: "'Empty' filter operator display name",
  },
  [FilterOperator.IN_ALL]: {
    id: "filterOperator.inAll.grouping",
    defaultMessage: "are all of",
    description: "'Are all' filter operator display name",
  },
  [FilterOperator.IN_ANY]: {
    id: "filterOperator.inAny.grouping",
    defaultMessage: "are any of",
    description: "'Are any' filter operator display name",
  },
  [FilterOperator.NOT_EMPTY]: {
    id: "filterOperator.notEmpty.grouping",
    defaultMessage: "are known",
    description: "'Not empty' filter operator display name",
  },
  [FilterOperator.NOT_IN]: {
    id: "filterOperator.notIn.grouping",
    defaultMessage: "are not in",
    description: "'Are not in' filter operator display name",
  },
});

const listFieldsMessages = defineMessages({
  [FilterOperator.EMPTY]: {
    id: "filterOperator.empty.listType",
    defaultMessage: "are unknown",
    description: "'Empty' filter operator display name",
  },
  [FilterOperator.IN_ALL]: {
    id: "filterOperator.inAll.listType",
    defaultMessage: "are all of",
    description: "'Are all' filter operator display name",
  },
  [FilterOperator.IN_ANY]: {
    id: "filterOperator.inAny.listType",
    defaultMessage: "are any of",
    description: "'Are any' filter operator display name",
  },
  [FilterOperator.NONE_OF]: {
    id: "filterOperator.noneOf.listType",
    defaultMessage: "are none of",
    description: "'Is not in' filter operator display name",
  },
  [FilterOperator.NOT_EMPTY]: {
    id: "filterOperator.notEmpty.listType",
    defaultMessage: "are known",
    description: "'Not empty' filter operator display name",
  },
  [FilterOperator.NOT_IN]: {
    id: "filterOperator.notIn.listType",
    defaultMessage: "are not in",
    description: "'Are not in' filter operator display name",
  },
});

const intervalRangeMessage = defineMessage({
  id: "filterOperator.inRange.interval",
  defaultMessage: "is within range",
  description: "Interval range operator in grid's rotting filter",
});

const getFilterOperatorDisplayName = (
  intl: IntlShape,
  filterOperator: FilterOperator,
  field: IField
) => {
  if (
    isCustomField(field) &&
    field.customFieldData.dataType === CustomFieldDataType.TIME &&
    filterOperator in timeFieldMessages
  ) {
    // ignoring because we just ensured that filterOperator is included into timeFieldMessages
    // @ts-ignore
    return intl.formatMessage(timeFieldMessages[filterOperator]);
  }
  if (
    (field.hasFeature(FieldFeature.NO_CONTACT_FIELD) ||
      field.hasFeature(FieldFeature.NO_CHECK_IN_FIELD)) &&
    filterOperator in noContactNoCheckInFieldMessages
  ) {
    // ignoring because we just ensured that filterOperator is included into noContactNoCheckInFieldMessages
    // @ts-ignore
    return intl.formatMessage(noContactNoCheckInFieldMessages[filterOperator]);
  }
  if (
    field.hasFeature(FieldFeature.ROTTING_DAYS_OUT_FIELD) &&
    filterOperator in rottingDaysOutFieldMessages
  ) {
    // ignoring because we just ensured that filterOperator is included into rottingDaysOutFieldMessages
    // @ts-ignore
    return intl.formatMessage(rottingDaysOutFieldMessages[filterOperator]);
  }
  if (
    [FieldType.DATE, FieldType.DATE_TIME].includes(field.type) &&
    FilterOperator.IN_RANGE === filterOperator
  ) {
    return intl.formatMessage(intervalRangeMessage);
  }

  if (
    field.hasAnyFeature(
      FieldFeature.GROUP_FIELD,
      FieldFeature.ROUTE_FIELD,
      FieldFeature.TERRITORY_FIELD
    ) &&
    filterOperator in groupingFieldMessages
  ) {
    // ignoring because we just ensured that filterOperator is included into groupingFieldMessages
    // @ts-ignore
    return intl.formatMessage(groupingFieldMessages[filterOperator]);
  }

  if (field.type === FieldType.LIST && filterOperator in listFieldsMessages) {
    // ignoring because we just ensured that filterOperator is included into listFieldsMessages
    // @ts-ignore
    return intl.formatMessage(listFieldsMessages[filterOperator]);
  }

  return intl.formatMessage(messages[filterOperator]);
};

export default getFilterOperatorDisplayName;
