import isEmpty from "lodash-es/isEmpty";
import { createReducer } from "typesafe-actions";

import EmailPreview from "@mapmycustomers/shared/types/email/EmailPreview";
import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";
import EmailTemplate from "@mapmycustomers/shared/types/email/EmailTemplate";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import { AttachedFile } from "@mapmycustomers/ui";

import {
  Actions,
  createNewEmailTemplate,
  deleteEmailTemplate,
  deleteUploadedFile,
  fetchEmailInfo,
  fetchEmailTemplates,
  fetchNewEmailPreview,
  fetchTemplatePreview,
  hideEmailCreationModal,
  hideEmailFeatures,
  hideEmailQueue,
  sendEmail,
  showEmailCreationModal,
  showEmailFeatures,
  showEmailQueue,
  updateEmailTemplate,
  uploadFileForNewEmail,
} from "./actions";

export interface EmailState {
  creationModalVisible: boolean;
  emailFeaturesVisible: boolean;
  emailQueueVisible: boolean;
  emailQuota?: EmailQuota;
  emailQuotaUpdating: boolean;
  emailSending: boolean;
  newEmailAttachments: AttachedFile[];
  newEmailPreview?: EmailPreview;
  newEmailPreviewLoading: boolean;
  recipients: EntitiesSupportedByEmailFeature[];
  templateCreating: boolean;
  templateDeleting: boolean;
  templatePreview?: EmailPreview;
  templatePreviewLoading: boolean;
  templates: EmailTemplate[];
  templatesLoading: boolean;
  templateUpdating: boolean;
}

const initialState: EmailState = {
  creationModalVisible: false,
  emailFeaturesVisible: false,
  emailQueueVisible: false,
  emailQuotaUpdating: false,
  emailSending: false,
  newEmailAttachments: [],
  newEmailPreviewLoading: false,
  recipients: [],
  templateCreating: false,
  templateDeleting: false,
  templatePreviewLoading: false,
  templates: [],
  templatesLoading: false,
  templateUpdating: false,
};

const email = createReducer<EmailState, Actions>(initialState)
  .handleAction(showEmailCreationModal, (state, { payload: { recipients } }) => ({
    ...state,
    creationModalVisible: true,
    newEmailAttachments: [],
    recipients: recipients.filter(({ email }) => !isEmpty(email)),
  }))
  .handleAction(hideEmailCreationModal, (state) => ({
    ...state,
    creationModalVisible: false,
  }))
  .handleAction(fetchEmailInfo.request, (state) => ({
    ...state,
    emailQuotaUpdating: true,
  }))
  .handleAction(fetchEmailInfo.success, (state, { payload }) => ({
    ...state,
    emailQuota: payload,
    emailQuotaUpdating: false,
  }))
  .handleAction(fetchEmailInfo.failure, (state) => ({
    ...state,
    emailQuotaUpdating: false,
  }))
  .handleAction(uploadFileForNewEmail.request, (state, { payload }) => ({
    ...state,
    newEmailAttachments: [...state.newEmailAttachments, { ...payload, uploading: true }],
  }))
  .handleAction(uploadFileForNewEmail.success, (state, { payload }) => ({
    ...state,
    newEmailAttachments: state.newEmailAttachments.map((attachment) => {
      if (payload.id === attachment.id) {
        return { ...payload, uploading: false };
      }
      return attachment;
    }),
  }))
  .handleAction(uploadFileForNewEmail.failure, (state, { payload }) => ({
    ...state,
    newEmailAttachments: state.newEmailAttachments.map((attachment) => {
      if (payload.id === attachment.id) {
        return { ...attachment, errored: true, uploading: false };
      }
      return attachment;
    }),
  }))
  .handleAction(deleteUploadedFile, (state, { payload }) => ({
    ...state,
    newEmailAttachments: state.newEmailAttachments.filter(({ id }) => id !== payload),
  }))
  .handleAction(sendEmail.request, (state) => ({
    ...state,
    emailSending: true,
  }))
  .handleAction([sendEmail.success, sendEmail.failure], (state) => ({
    ...state,
    emailSending: false,
  }))
  .handleAction(fetchEmailTemplates.request, (state) => ({
    ...state,
    templatesLoading: true,
  }))
  .handleAction(fetchEmailTemplates.success, (state, { payload }) => ({
    ...state,
    templates: payload,
    templatesLoading: false,
  }))
  .handleAction(fetchEmailTemplates.failure, (state) => ({
    ...state,
    templatesLoading: false,
  }))
  .handleAction(fetchNewEmailPreview.request, (state) => ({
    ...state,
    newEmailPreview: undefined,
    newEmailPreviewLoading: true,
  }))
  .handleAction(fetchNewEmailPreview.success, (state, { payload }) => ({
    ...state,
    newEmailPreview: payload,
    newEmailPreviewLoading: false,
  }))
  .handleAction(fetchNewEmailPreview.failure, (state) => ({
    ...state,
    newEmailPreviewLoading: false,
  }))
  .handleAction(fetchTemplatePreview.request, (state) => ({
    ...state,
    templatePreview: undefined,
    templatePreviewLoading: true,
  }))
  .handleAction(fetchTemplatePreview.success, (state, { payload }) => ({
    ...state,
    templatePreview: payload,
    templatePreviewLoading: false,
  }))
  .handleAction(fetchTemplatePreview.failure, (state) => ({
    ...state,
    templatePreviewLoading: false,
  }))
  .handleAction(createNewEmailTemplate.request, (state) => ({
    ...state,
    templateCreating: true,
  }))
  .handleAction(createNewEmailTemplate.success, (state) => ({
    ...state,
    templateCreating: false,
  }))
  .handleAction(createNewEmailTemplate.failure, (state) => ({
    ...state,
    templateCreating: false,
  }))
  .handleAction(deleteEmailTemplate.request, (state) => ({
    ...state,
    deleting: true,
  }))
  .handleAction(deleteEmailTemplate.success, (state) => ({
    ...state,
    deleting: false,
  }))
  .handleAction(deleteEmailTemplate.failure, (state) => ({
    ...state,
    deleting: false,
  }))
  .handleAction(updateEmailTemplate.request, (state) => ({
    ...state,
    templateUpdating: true,
  }))
  .handleAction(updateEmailTemplate.success, (state) => ({
    ...state,
    templateUpdating: false,
  }))
  .handleAction(updateEmailTemplate.failure, (state) => ({
    ...state,
    creating: false,
    templateUpdating: false,
  }))
  .handleAction(showEmailQueue, (state) => ({
    ...state,
    emailQueueVisible: true,
  }))
  .handleAction(hideEmailQueue, (state) => ({
    ...state,
    emailQueueVisible: false,
  }))
  .handleAction(showEmailFeatures, (state) => ({
    ...state,
    emailFeaturesVisible: true,
  }))
  .handleAction(hideEmailFeatures, (state) => ({
    ...state,
    emailFeaturesVisible: false,
  }));

export * from "./selectors";
export type EmailActions = Actions;

export default email;
