import memoize from "fast-memoize";
import { createSelector } from "reselect";

import Feature from "@mapmycustomers/shared/enum/Feature";
import { EntityType, EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import PinLegend, {
  ColorPinLegend,
  ShapePinLegend,
} from "@mapmycustomers/shared/types/map/PinLegend";

import {
  getFeatures,
  getDefaultColorKeys as getOrganizationDefaultColorKeys,
  getDefaultShapeKeys as getOrganizationDefaultShapeKeys,
} from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import { getDefaultColorPinLegend, getDefaultShapePinLegend } from "@app/util/pinLegend";

const pinLegendsState = (state: RootState) => state.pinLegends;

// DO NOT USE this selector. It's only used in one place and that's for a very good reason.
// In all other places please use getAllColorLegends
export const getAllColorLegendsRaw = createSelector(
  pinLegendsState,
  ({ colorLegends }) => colorLegends
);

export const getAllColorLegends = createSelector(
  pinLegendsState,
  getFeatures,
  ({ colorLegends }, features) => {
    const disallowColor = features?.[Feature.DISALLOW_COLOR]?.enabled;
    return disallowColor
      ? colorLegends.filter(
          (legend: PinLegend) => legend.fieldName !== "color" || legend.entity === EntityType.DEAL
        )
      : colorLegends;
  }
);
export const getColorLegends = createSelector(
  getAllColorLegends,
  (colorLegends: ColorPinLegend[]) =>
    memoize((entityType: EntityTypesSupportedByMapsPage): ColorPinLegend[] =>
      colorLegends.filter(({ entity }) => entity === entityType)
    )
);

export const getAllShapeLegends = createSelector(
  pinLegendsState,
  ({ shapeLegends }) => shapeLegends
);
export const getShapeLegends = createSelector(
  getAllShapeLegends,
  (shapeLegends: ShapePinLegend[]) =>
    memoize((entityType: EntityTypesSupportedByMapsPage): ShapePinLegend[] =>
      shapeLegends.filter(({ entity }) => entity === entityType)
    )
);

export const isDeleting = createSelector(pinLegendsState, ({ deleting }) => deleting);

export const isRefreshing = createSelector(pinLegendsState, ({ refreshing }) => refreshing);

export const getDefaultColorKeys = createSelector(
  getAllColorLegends,
  getOrganizationDefaultColorKeys,
  (colorLegends, defaultColorKeys) =>
    (
      [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as EntityTypesSupportedByMapsPage[]
    ).reduce(
      (result, entityType) => ({
        ...result,
        [entityType]:
          defaultColorKeys?.[entityType] ?? getDefaultColorPinLegend(colorLegends, entityType)?.id,
      }),
      {} as Partial<Record<EntityTypesSupportedByMapsPage, PinLegend["id"]>>
    )
);

export const getDefaultShapeKeys = createSelector(
  getAllShapeLegends,
  getOrganizationDefaultShapeKeys,
  (shapeLegends, defaultShapeKeys) =>
    (
      [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as EntityTypesSupportedByMapsPage[]
    ).reduce(
      (result, entityType) => ({
        ...result,
        [entityType]:
          defaultShapeKeys?.[entityType] ?? getDefaultShapePinLegend(shapeLegends, entityType)?.id,
      }),
      {} as Partial<Record<EntityTypesSupportedByMapsPage, PinLegend["id"]>>
    )
);
