import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import IntervalUnit from "@app/enum/IntervalUnit";

const mapActivityStatusOptionToFilter: Record<ActivityStatusOption, Partial<FilterModel>> = {
  [ActivityStatusOption.ALL]: {
    completed: undefined,
    startAt: undefined,
  },
  [ActivityStatusOption.COMPLETED]: {
    completed: { operator: FilterOperator.IS_TRUE, value: undefined },
    startAt: undefined,
  },
  [ActivityStatusOption.INCOMPLETE]: {
    completed: { operator: FilterOperator.IS_FALSE, value: undefined },
    startAt: undefined,
  },
  [ActivityStatusOption.NOT_SCHEDULED]: {
    completed: undefined,
    startAt: { operator: FilterOperator.EMPTY, value: null },
  },
  [ActivityStatusOption.OVERDUE]: {
    completed: { operator: FilterOperator.IS_FALSE, value: undefined },
    startAt: {
      operator: FilterOperator.INTERVAL_BEFORE,
      value: { unit: IntervalUnit.DAY, value: 0 },
    },
  },
  [ActivityStatusOption.UPCOMING]: {
    completed: { operator: FilterOperator.IS_FALSE, value: undefined },
    startAt: {
      operator: FilterOperator.INTERVAL_AFTER,
      value: { unit: IntervalUnit.DAY, value: 0 },
    },
  },
};

export default mapActivityStatusOptionToFilter;
