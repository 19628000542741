import differenceInDays from "date-fns/differenceInDays";
import { defineMessages } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import {
  Condition,
  PlatformFilterCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import i18nService from "@app/config/I18nService";
import EmptyFilter from "@app/util/filters/EmptyFilter";
import NoCheckInFilter, { NO_CHECK_INS_FILTER_OPERATORS } from "@app/util/filters/NoCheckInFilter";
import { parseApiDate } from "@app/util/parsers";
import { isCombinedCondition, isCombinedPlatformCondition } from "@app/util/viewModel/assert";
import parseIntervalCondition from "@app/util/viewModel/converters/parseIntervalCondition";
import { getRegularFieldConditionValue } from "@app/util/viewModel/convertFromPlatformFilterModel";
import { getRegularFieldPlatformConditionValue } from "@app/util/viewModel/convertToPlatformFilterModel";
import { isIntervalCondition } from "@app/util/viewModel/util";

import Field, { FieldProperties } from "./Field";

// operators are reversed for "no check-in since" (-2 days)
const swappedFilterOperatorToPlatformFilterOperatorMap: Partial<{
  [key in FilterOperator]: PlatformFilterOperator;
}> = {
  [FilterOperator.GREATER_THAN]: PlatformFilterOperator.LESS_THAN,
  [FilterOperator.GREATER_THAN_OR_EQUAL]: PlatformFilterOperator.LESS_THAN_OR_EQUAL,
  [FilterOperator.LESS_THAN]: PlatformFilterOperator.GREATER_THAN,
  [FilterOperator.LESS_THAN_OR_EQUAL]: PlatformFilterOperator.GREATER_THAN_OR_EQUAL,
} as const;

const messages = defineMessages({
  noCheckIn: {
    id: "noCheckInField.value",
    defaultMessage: "{days} {days, plural, one {day} other {days}}",
    description: "No check in days message for the given entity",
  },
});

class NoCheckInField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "noCheckIn",
        filterInstances: { empty: EmptyFilter, noCheckIn: NoCheckInFilter },
        operators: [...NO_CHECK_INS_FILTER_OPERATORS, FilterOperator.EMPTY],
      },
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NO_CHECK_IN_FIELD,
      ],
      platformFilterName: "lastCheckIn",
      type: FieldType.NUMBER,
      valueFormatter: (entity: unknown, value: unknown) => {
        const intl = i18nService.getIntl();
        const now = new Date();
        if (!value) {
          return "";
        }
        if (!intl) {
          return differenceInDays(now, value as Date).toString();
        }
        return intl.formatMessage(messages.noCheckIn, {
          days: differenceInDays(now, parseApiDate(value as Date)),
        });
      },
      valueGetter: ["lastCheckIn", "completedAt"],
      ...data,
    });
  }

  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      return {}; // combined condition is not supported
    } else {
      // Special handling using interval operator for (gt,lt operators), days value is also negative.
      const { operator, value } = filterCondition;
      if (![FilterOperator.EMPTY, FilterOperator.EQUALS].includes(operator)) {
        return {
          [this.platformFilterName]: {
            [PlatformFilterOperator.INTERVAL]: {
              $round: "days",
              [swappedFilterOperatorToPlatformFilterOperatorMap[operator]!]: `${
                value === 0 ? 0 : -value
              } days`,
            },
          },
        };
      }
      // special handling for the $eq operator
      if (operator === FilterOperator.EQUALS) {
        return {
          [this.platformFilterName]: {
            [PlatformFilterOperator.INTERVAL]: {
              $round: "days",
              [PlatformFilterOperator.GREATER_THAN_OR_EQUAL]: `${value === 0 ? 0 : -value} days`,
              [PlatformFilterOperator.LESS_THAN_OR_EQUAL]: `${value === 0 ? 0 : -value} days`,
            },
          },
        };
      }
      return {
        [this.platformFilterName]: getRegularFieldPlatformConditionValue(this, filterCondition),
      };
    }
  }

  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    if (isCombinedPlatformCondition(filterCondition)) {
      return super.convertFromPlatformCondition(filterCondition);
    } else {
      if (this.platformFilterName in filterCondition) {
        const fieldCondition = filterCondition[this.platformFilterName] as Condition;
        if (isIntervalCondition(fieldCondition)) {
          const interval = fieldCondition.$interval as Condition;
          const intervalCondition = parseIntervalCondition(fieldCondition);

          if (PlatformFilterOperator.GREATER_THAN in interval) {
            return {
              operator: FilterOperator.LESS_THAN,
              value: -intervalCondition.value.value!,
            };
          } else if (PlatformFilterOperator.LESS_THAN in interval) {
            return {
              operator: FilterOperator.GREATER_THAN,
              value: -intervalCondition.value.value!,
            };
          } else if (
            PlatformFilterOperator.GREATER_THAN_OR_EQUAL in interval &&
            PlatformFilterOperator.LESS_THAN_OR_EQUAL in interval
          ) {
            return {
              operator: FilterOperator.EQUALS,
              value: -intervalCondition.value.from!,
            };
          } else if (PlatformFilterOperator.LESS_THAN_OR_EQUAL in interval) {
            return {
              operator: FilterOperator.GREATER_THAN_OR_EQUAL,
              value: -intervalCondition.value.value!,
            };
          } else if (PlatformFilterOperator.GREATER_THAN_OR_EQUAL in interval) {
            return {
              operator: FilterOperator.LESS_THAN_OR_EQUAL,
              value: -intervalCondition.value.value!,
            };
          }
        }
        return getRegularFieldConditionValue(this, { [this.platformFilterName]: fieldCondition });
      }
      return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
    }
  }
}

export default NoCheckInField;
