import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { FrigadeProvider } from "@frigade/react";
import ConfigProvider from "antd/es/config-provider";
import notification from "antd/es/notification";
import { preMessage } from "rc-util/es/warning";
import { RawIntlProvider } from "react-intl";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  ActivityPage,
  BillingPage,
  CompanyPage,
  DashboardPage,
  DealPage,
  MapPage,
  NotificationsPage,
  PeoplePage,
  ReportsPage,
  SettingsPage,
  Web1Page,
} from "scene";

import { ShortcutService } from "@mapmycustomers/shared";
import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import Currency from "@mapmycustomers/shared/types/Currency";
import {
  ConfigProviderProps,
  PrimitiveType,
  ConfigProvider as UiConfigProvider,
} from "@mapmycustomers/ui";

import AuthRoute from "@app/component/auth/AuthRoute";
import PrivateRoute from "@app/component/auth/PrivateRoute";
import GlobalCreateEntityModals from "@app/component/createEditEntity/AddNoteModal/GlobalCreateEntityModals";
import SsoLayout from "@app/component/Layout/SsoLayout";
import configService from "@app/config/ConfigService";
import dateFnsLocaleService from "@app/config/DateFnsLocaleService";
import Environment from "@app/enum/Environment";
import OrganizationSetting from "@app/enum/OrganizationSetting";
import Path from "@app/enum/Path";
import ForgotPasswordPage from "@app/scene/auth/component/ForgotPassword";
import ResetPasswordPage from "@app/scene/auth/component/ForgotPassword/ResetPasswordPage";
import LoginPage from "@app/scene/auth/component/Login";
import ManageUserAccess from "@app/scene/auth/component/ManageUserAccess";
import RegisterPage from "@app/scene/auth/component/Register";
import SsoLoginPage from "@app/scene/auth/component/SsoLogin";
import RootPage from "@app/scene/auth/RootPage";
// import ExternalServiceLoginPage from "@app/scene/auth/component/ExternalServiceLoginPage";
import { showGlobalSearch } from "@app/store/globalSearch/actions";
import { getMe, getOrganizationSettingValue } from "@app/store/iam";
import { getLocale, isLoading } from "@app/store/locale";
import { getCurrencies } from "@app/store/referenceData";
import { RootState } from "@app/store/rootReducer";
import { isAnyThemeLoaded } from "@app/store/theme";
import {
  getUserColorClassNameGetter,
  UserColorClassNameGetter,
} from "@app/store/userColor/selectors";
import Iam from "@app/types/Iam";
import analyticsService from "@app/util/analytic/AnalyticsService";
import { DEFAULT_NOTIFICATION_DURATION } from "@app/util/consts";
import AnalyticsContext from "@app/util/contexts/AnalyticsContext";
import defaultEntityLinkGetter from "@app/util/contexts/defaultEntityLinkGetter";
import EntityLinkGetterContext from "@app/util/contexts/EntityLinkGetterContext";
import { default as formatCountryNameFromCode } from "@app/util/countries/formatCountryName";
import { complementFileUrl } from "@app/util/file";
import useFindMyLocation from "@app/util/hook/useFindMyLocation";

import i18nService from "./config/I18nService";
import messages from "./messages";

// lower down antd deprecation messages from error to warning
preMessage((message, type) => {
  if (type === "warning" && message) {
    console.warn(message);
    return null;
  } else {
    return message;
  }
});

interface Props {
  currencies: Currency[];
  defaultCurrencyCode: Currency["id"];
  getUserColorClassName: UserColorClassNameGetter;
  isAnyThemeLoaded: boolean;
  locale: string;
  // localeLoading is needed to be in props to guarantee App component is re-rendered when locale
  // initialization is complete. `locale` prop is not enough because sometimes it has the same
  // value before and after initialization. This is needed to get correct intl instance from
  // the i18nService.
  localeLoading: boolean;
  me?: Iam;
  showGlobalSearch: typeof showGlobalSearch;
}

notification.config({
  duration: DEFAULT_NOTIFICATION_DURATION,
});

const App = ({
  currencies,
  defaultCurrencyCode,
  getUserColorClassName,
  isAnyThemeLoaded,
  locale,
  me,
  showGlobalSearch,
}: Props) => {
  const antDLocale = i18nService.getAntDLocale(locale);
  const intl = i18nService.getIntl();

  const history = useHistory();
  useEffect(() => history.listen(() => analyticsService.page()), [history]);

  useEffect(() => {
    const shortcutService = new ShortcutService(document.body);
    shortcutService.addKeyListener(
      { code: "KeyF", metaKey: true, shiftKey: true, type: "keydown" },
      () => showGlobalSearch()
    );
    return () => shortcutService.destroy();
  }, [showGlobalSearch]);

  const formatMessage = useCallback(
    (messageId: keyof typeof messages, values?: Record<string, PrimitiveType>) =>
      messageId in messages ? intl?.formatMessage(messages[messageId], values) : undefined,
    [intl]
  );

  const formatCountryName = useCallback(
    (countryCode: CountryCode) => {
      return intl ? formatCountryNameFromCode(intl, countryCode) : "";
    },
    [intl]
  );

  if (!intl || !isAnyThemeLoaded) {
    return null;
  }

  return (
    <RawIntlProvider value={intl}>
      <ConfigProvider locale={antDLocale}>
        <UiConfigProvider
          complementFileUrl={complementFileUrl}
          currencies={currencies}
          dateFnsLocale={dateFnsLocaleService.locale}
          defaultCurrencyCode={defaultCurrencyCode}
          formatCountryName={formatCountryName}
          formatMessage={formatMessage as ConfigProviderProps["formatMessage"]}
          getUserColorClassName={getUserColorClassName}
          intl={intl}
          useFindMyLocation={useFindMyLocation}
        >
          <AnalyticsContext.Provider value={analyticsService}>
            <EntityLinkGetterContext.Provider value={defaultEntityLinkGetter}>
              <FrigadeProvider
                config={{ debug: true }}
                organizationId={String(me?.organization.id ?? "public")}
                publicApiKey={configService.getFrigadeApiKey()}
                userId={String(me?.id ?? "public")}
              >
                <Switch>
                  {/*<AuthRoute path={Path.EXTERNAL_LOGIN} component={ExternalServiceLoginPage} />*/}
                  <AuthRoute path={Path.LOGIN} component={LoginPage} />
                  <AuthRoute path={Path.FORGOT_PASSWORD} component={ForgotPasswordPage} />
                  <AuthRoute path={Path.RESET_PASSWORD} component={ResetPasswordPage} />
                  <AuthRoute path={Path.SSO_LOGIN} component={SsoLoginPage} />
                  <AuthRoute path={Path.REGISTER} component={RegisterPage} />
                  <PrivateRoute
                    path={Path.MANAGE_USER_ACCESS}
                    component={ManageUserAccess}
                    layoutComponent={SsoLayout}
                  />
                  <PrivateRoute path={Path.DASHBOARD} component={DashboardPage} />
                  <PrivateRoute path={Path.ACTIVITY} component={ActivityPage} />
                  {configService.getCurrentEnvironment() !== Environment.SANDBOX && (
                    <PrivateRoute path={Path.BILLING} component={BillingPage} />
                  )}
                  <PrivateRoute path={Path.COMPANY} component={CompanyPage} />
                  <PrivateRoute path={Path.MAP} component={MapPage} />
                  <PrivateRoute path={Path.PEOPLE} component={PeoplePage} />
                  <PrivateRoute path={Path.REPORTS} component={ReportsPage} />
                  <PrivateRoute path={Path.SETTINGS} component={SettingsPage} />
                  <PrivateRoute path={Path.DEAL} component={DealPage} />
                  <PrivateRoute
                    path={`${Path.NOTIFICATIONS}/:notificationId?`}
                    component={NotificationsPage}
                  />
                  <PrivateRoute layout={false} path={Path.WEB_1} component={Web1Page} />
                  <Route path={Path.ROOT} component={RootPage} /> {/* Must be the last one */}
                </Switch>
              </FrigadeProvider>
              <GlobalCreateEntityModals />
            </EntityLinkGetterContext.Provider>
          </AnalyticsContext.Provider>
        </UiConfigProvider>
      </ConfigProvider>
    </RawIntlProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  currencies: getCurrencies(state),
  defaultCurrencyCode: getOrganizationSettingValue(state)(OrganizationSetting.CURRENCY),
  getUserColorClassName: getUserColorClassNameGetter(state),
  isAnyThemeLoaded: isAnyThemeLoaded(state),
  locale: getLocale(state),
  localeLoading: isLoading(state),
  me: getMe(state),
});

const mapDispatchToProps = {
  showGlobalSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
