import { EntityTypesSupportedByMapsPage } from "@mapmycustomers/shared/types/entity";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import { PinLegendCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import { getDefaultColorPinLegend, getDefaultShapePinLegend } from "../../pinLegend";

const getColorShapeForEntity = (
  mapViewState: MapViewState | undefined,
  colorPinLegends: PinLegend[],
  shapePinLegends: PinLegend[],
  entityType: EntityTypesSupportedByMapsPage
): PinLegendCondition | undefined => {
  const color =
    mapViewState?.colorKey?.[entityType] ??
    getDefaultColorPinLegend(colorPinLegends, entityType)?.id;
  const shape =
    mapViewState?.shapeKey?.[entityType] ??
    getDefaultShapePinLegend(shapePinLegends, entityType)?.id;

  const colorPinLegend = colorPinLegends.find(({ id }) => id === color);
  const shapePinLegend = shapePinLegends.find(({ id }) => id === shape);

  return color || shape
    ? { color, colorVersion: colorPinLegend?.version, shape, shapeVersion: shapePinLegend?.version }
    : undefined;
};

export default getColorShapeForEntity;
