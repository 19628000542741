import React, { FC, useCallback } from "react";

import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { OptionalFields } from "@mapmycustomers/shared/util/ts";
import { LabeledFieldProps, MonetaryField, MonetaryFieldProps } from "@mapmycustomers/ui";

export interface MonetaryInputProps
  extends Omit<MonetaryFieldProps, "max" | "min" | "onChange">,
    Omit<LabeledFieldProps, "children"> {
  onChange: (value: MonetaryValue) => void;
}

const MonetaryInput: FC<MonetaryInputProps> = ({ onChange, ...props }) => {
  // Platform limit
  // Note: the actual limit is -9223372036854775808..9223372036854775807, but since these
  // numbers can't be represented precisely in JS without using a BigInt, we use the
  // closest smaller number, which is exactly 9223372036854775000.
  const MAX_MONEY_NUMERIC_LIMIT = 9223372036854775000;
  const MIN_MONEY_NUMERIC_LIMIT = -9223372036854775000;

  const handleChange = useCallback(
    (value: MonetaryValue | OptionalFields<MonetaryValue, "value">) => {
      if (isDefined(value.value) && onChange) {
        onChange(value as MonetaryValue);
      }
    },
    [onChange]
  );

  return (
    <MonetaryField
      max={MAX_MONEY_NUMERIC_LIMIT}
      min={MIN_MONEY_NUMERIC_LIMIT}
      onChange={handleChange}
      {...props}
    />
  );
};

export default MonetaryInput;
