import React from "react";

import { bem } from "@react-md/utils";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import { UserRef } from "@mapmycustomers/shared/types/User";

import { useConfigProvider } from "../ConfigProvider";

import Avatar from "./index";
import { getUserDisplayNameWithInfo } from "./util/getUserDisplayNameWithInfo";

const block = bem("mmc-avatar");

interface AvatarWithNameProps {
  actionOnHover?: boolean;
  avatarClassName?: string;
  className?: string;
  size?: "s" | "xs";
  user: UserRef;
}

const AvatarWithName: React.FC<AvatarWithNameProps> = ({
  actionOnHover,
  avatarClassName,
  className,
  size,
  user,
}) => {
  const configProvider = useConfigProvider();

  return (
    <Tooltip
      title={
        actionOnHover && !user.deletedAt && !user.deactivated
          ? configProvider.formatMessage("ui.avatar.tooltip.open", {
              name: getUserDisplayNameWithInfo(configProvider, user),
            })
          : getUserDisplayNameWithInfo(configProvider, user)
      }
    >
      <div
        className={cn(
          block({
            [`size_${size}`]: !!size,
            deleted: !!user.deletedAt || user.deactivated,
            hoverStyle: actionOnHover,
          }),
          className
        )}
      >
        <Avatar className={cn(block("avatar"), avatarClassName)} tooltip={false} user={user} />
        <span className={block("name")}>{getUserDisplayNameWithInfo(configProvider, user)}</span>
      </div>
    </Tooltip>
  );
};

export default AvatarWithName;
