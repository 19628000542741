import { ActionType, createAsyncAction } from "typesafe-actions";

import PinLegend, {
  ColorPinLegend,
  CreateColorPinLegendPayload,
  CreateShapePinLegendPayload,
  ShapePinLegend,
  UpdateColorPinLegendPayload,
  UpdateShapePinLegendPayload,
} from "@mapmycustomers/shared/types/map/PinLegend";

export const fetchPinLegends = createAsyncAction(
  "@pinLegends/fetch/request",
  "@pinLegends/fetch/success",
  "@pinLegends/fetch/failure"
)<void, PinLegend[], unknown>();

export const createColorPinLegend = createAsyncAction(
  "@pinLegends/createColorPinLegend/request",
  "@pinLegends/createColorPinLegend/success",
  "@pinLegends/createColorPinLegend/failure"
)<
  {
    callback?: (pinLegend: ColorPinLegend) => void;
    payload: CreateColorPinLegendPayload;
  },
  ColorPinLegend,
  unknown
>();

export const updateColorPinLegend = createAsyncAction(
  "@pinLegends/updateColorPinLegend/request",
  "@pinLegends/updateColorPinLegend/success",
  "@pinLegends/updateColorPinLegend/failure"
)<
  {
    callback?: (pinLegend: ColorPinLegend) => void;
    conflictCallback?: () => void;
    payload: UpdateColorPinLegendPayload;
  },
  ColorPinLegend,
  unknown
>();

export const createShapePinLegend = createAsyncAction(
  "@pinLegends/createShapePinLegend/request",
  "@pinLegends/createShapePinLegend/success",
  "@pinLegends/createShapePinLegend/failure"
)<
  {
    callback?: (pinLegend: ShapePinLegend) => void;
    payload: CreateShapePinLegendPayload;
  },
  ShapePinLegend,
  unknown
>();

export const updateShapePinLegend = createAsyncAction(
  "@pinLegends/updateShapePinLegend/request",
  "@pinLegends/updateShapePinLegend/success",
  "@pinLegends/updateShapePinLegend/failure"
)<
  {
    callback?: (pinLegend: ShapePinLegend) => void;
    conflictCallback?: () => void;
    payload: UpdateShapePinLegendPayload;
  },
  ShapePinLegend,
  unknown
>();

export const deletePinLegend = createAsyncAction(
  "@pinLegends/delete/request",
  "@pinLegends/delete/success",
  "@pinLegends/delete/failure"
)<
  {
    callback?: () => void;
    pinLegendId: PinLegend["id"];
  },
  PinLegend["id"],
  unknown
>();

export const refreshPinLegend = createAsyncAction(
  "@pinLegends/refresh/request",
  "@pinLegends/refresh/success",
  "@pinLegends/refresh/failure"
)<
  {
    callback?: (pinLegend: PinLegend) => void;
    id: PinLegend["id"];
  },
  PinLegend,
  unknown
>();

export type Actions = ActionType<
  | typeof createColorPinLegend
  | typeof createShapePinLegend
  | typeof deletePinLegend
  | typeof fetchPinLegends
  | typeof refreshPinLegend
  | typeof updateColorPinLegend
  | typeof updateShapePinLegend
>;
