import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { FilterCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import {
  Condition,
  PlatformFilterCondition,
  PlatformSimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import { DEFAULT_EMPTINESS_OPERATOR } from "@app/util/filters/defaultFilters";
import EmptyFilter from "@app/util/filters/EmptyFilter";
import RegionCodeFilter, { REGION_CODE_FILTER_OPERATORS } from "@app/util/filters/RegionCodeFilter";
import TextFilter, { TEXT_FILTER_OPERATORS } from "@app/util/filters/TextFilter";
import {
  isCombinedCondition,
  isCombinedPlatformCondition,
  isSimplePlatformCondition,
} from "@app/util/viewModel/assert";
import { getRegularFieldConditionValue } from "@app/util/viewModel/convertFromPlatformFilterModel";
import { getRegularFieldPlatformConditionValue } from "@app/util/viewModel/convertToPlatformFilterModel";

import Field, { FieldProperties } from "./Field";

export type RegionFieldConditionValue = [countryCode: CountryCode, regionIds: string[]];

// basically the same as in TextField, but with an instance
const INSTANCED_TEXT_FILTER_OPERATORS = TEXT_FILTER_OPERATORS.map((operator) => ({
  instance: "text",
  operator,
}));

class RegionField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "regionCode",
        filterInstances: {
          empty: EmptyFilter,
          regionCode: RegionCodeFilter,
          text: TextFilter,
        },
        operators: [
          ...REGION_CODE_FILTER_OPERATORS,
          ...INSTANCED_TEXT_FILTER_OPERATORS,
          ...DEFAULT_EMPTINESS_OPERATOR,
        ],
      },
      features: [
        FieldFeature.REGION_FIELD,
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.ADDRESS,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      type: FieldType.STRING,
      ...data,
    });
  }

  convertToPlatformCondition(filterCondition: FilterCondition): PlatformFilterCondition {
    if (isCombinedCondition(filterCondition)) {
      return {}; // combined condition is not supported
    } else {
      if (
        filterCondition.operator === FilterOperator.IN_ANY ||
        filterCondition.operator === FilterOperator.NONE_OF
      ) {
        const value = filterCondition.value as RegionFieldConditionValue;
        return {
          regionCode: {
            country: value[0],
            [filterCondition.operator === FilterOperator.IN_ANY
              ? PlatformFilterOperator.CONTAINS
              : PlatformFilterOperator.NOT_CONTAINS]: value[1],
          },
        };
      } else {
        return {
          [this._platformFilterName]: getRegularFieldPlatformConditionValue(this, filterCondition),
        };
      }
    }
  }

  /**
   * @override
   */
  isPresentInPlatformCondition(condition: Record<string, unknown>): boolean {
    return this.platformFilterName in condition || "regionCode" in condition;
  }

  /**
   * @override
   */
  convertFromPlatformCondition(
    filterCondition: PlatformFilterCondition
  ): FilterCondition | undefined {
    if (isCombinedPlatformCondition(filterCondition)) {
      if (
        "$or" in filterCondition &&
        Array.isArray(filterCondition.$or) &&
        filterCondition.$or.length === 2 &&
        filterCondition.$or.every(
          (condition) =>
            isSimplePlatformCondition(condition) &&
            (this.platformFilterName in condition || "regionCode" in condition)
        )
      ) {
        const regionCondition = filterCondition.$or.find(
          (condition) => this.platformFilterName in condition
        )! as PlatformSimpleCondition;
        return getRegularFieldConditionValue(this, regionCondition);
      }
      return super.convertFromPlatformCondition(filterCondition);
    } else {
      if ("regionCode" in filterCondition) {
        const condition = filterCondition.regionCode as Condition;
        const country = "country" in condition ? (condition.country as CountryCode) : undefined;
        const operator =
          PlatformFilterOperator.CONTAINS in condition
            ? FilterOperator.IN_ANY
            : FilterOperator.NONE_OF;
        const regionIds =
          condition[PlatformFilterOperator.CONTAINS] ??
          condition[PlatformFilterOperator.NOT_CONTAINS] ??
          [];
        return { operator, value: [country, regionIds] as RegionFieldConditionValue };
      }

      // wrapping condition into an object with filterName is a little hack to help getRegularFieldConditionValue
      // because this is what it normally expects. But since Location is a meta-field, condition for it comes
      // unwrapped, so we need to wrap it.
      return getRegularFieldConditionValue(this, { [this.platformFilterName]: filterCondition });
    }
  }
}

export default RegionField;
