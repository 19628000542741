import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const closedStageDealCountState = (state: RootState) =>
  state.scene.dashboard.closedStageDealCountState;

export const getClosedStageDealCountDrillDownViewState = createSelector(
  closedStageDealCountState,
  ({ viewState }) => viewState
);

export const getClosedStageDealCountDrillDownTotalFilteredRecords = createSelector(
  closedStageDealCountState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getClosedStageDealCountDrillDownTotalRecords = createSelector(
  closedStageDealCountState,
  ({ totalRecords }) => totalRecords
);

export const getClosedStageDealCountDrillDownCustomDateRange = createSelector(
  closedStageDealCountState,
  ({ drillDownCustomDateRange }) => drillDownCustomDateRange
);

export const getClosedStageDealCountDrillDownConfiguration = createSelector(
  closedStageDealCountState,
  ({ configuration }) => configuration
);

export const isClosedStageDealCountDrillDownLoading = createSelector(
  closedStageDealCountState,
  ({ loading }) => loading
);
