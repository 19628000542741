import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const closedStageDealAmountState = (state: RootState) =>
  state.scene.dashboard.closedStageDealAmountState;

export const getClosedStageDealAmountDrillDownViewState = createSelector(
  closedStageDealAmountState,
  ({ viewState }) => viewState
);

export const getClosedStageDealAmountDrillDownTotalFilteredRecords = createSelector(
  closedStageDealAmountState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getClosedStageDealAmountDrillDownTotalRecords = createSelector(
  closedStageDealAmountState,
  ({ totalRecords }) => totalRecords
);

export const getClosedStageDealAmountDrillDownCustomDateRange = createSelector(
  closedStageDealAmountState,
  ({ drillDownCustomDateRange }) => drillDownCustomDateRange
);

export const getClosedStageDealAmountDrillDownConfiguration = createSelector(
  closedStageDealAmountState,
  ({ configuration }) => configuration
);

export const isClosedStageDealAmountDrillDownLoading = createSelector(
  closedStageDealAmountState,
  ({ loading }) => loading
);
