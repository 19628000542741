import { FieldProperties } from "./Field";
import NoCheckInField from "./NoCheckInField";

class NoVerifiedCheckInField extends NoCheckInField {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      ...data,
      platformFilterName: "lastVerifiedCheckIn",
      valueGetter: ["lastVerifiedCheckIn", "completedAt"],
    });
  }
}

export default NoVerifiedCheckInField;
