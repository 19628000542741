import { createReducer } from "typesafe-actions";

import DateRangeType from "@mapmycustomers/shared/enum/DateRangeType";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import { StandardRange } from "@mapmycustomers/shared/types/range/DateRange";

import SortOrder from "@app/scene/dashboard/components/Sidebar/enum/SortOrder";
import StackRankState from "@app/scene/dashboard/store/stackRank/StackRankState";
import leaderboardFieldModel from "@app/util/fieldModel/LeaderboardFieldModel";
import standardRangeToDateRange from "@app/util/range/standardRangeToDateRange";

import {
  applyUserDrillDownListViewSettings,
  fetchDrillDownData,
  fetchMetrics,
  fetchUserDrillDownData,
  hideStackRankDrillDown,
  hideUserDrillDown,
  initialize,
  showStackRankDrillDown,
  showUserDrillDown,
  StackRankActions,
  updateDrillDownViewState,
  updateStackRankViewState,
} from "./actions";

export const DEFAULT_DATE_RANGE_TYPE = `${DateRangeType.WEEK}0` as StandardRange;
const DEFAULT_DATE_RANGE = standardRangeToDateRange(DEFAULT_DATE_RANGE_TYPE)!;

export const initialStackRankState: StackRankState = {
  drillDownData: [],
  drillDownViewState: {
    columns: leaderboardFieldModel.getDefaultListViewState().columns,
    grouping: "user",
  },
  drillDownVisible: false,
  performanceData: {},
  totalUserDrillDownFilteredRecords: 0,
  totalUserDrillDownRecords: 0,
  viewState: {
    dateRange: DEFAULT_DATE_RANGE,
    dateRangeType: DEFAULT_DATE_RANGE_TYPE,
    filters: {},
    rankingMetric: leaderboardFieldModel.getByName(LeaderboardMetricFieldName.CHECK_INS_COMPLETED)!,
    sort: SortOrder.RANK,
  },
};

const stackRank = createReducer<StackRankState, StackRankActions>(initialStackRankState)
  .handleAction(initialize.success, (state, { payload }) => ({
    ...state,
    viewState: payload ? payload : state.viewState,
  }))
  .handleAction(updateStackRankViewState, (state, { payload }) => ({
    ...state,
    viewState: { ...state.viewState, ...payload },
  }))
  .handleAction(fetchMetrics.request, (state, { payload }) => ({
    ...state,
    performanceDataLoading: true,
    viewState: { ...state.viewState, ...payload },
  }))
  .handleAction(fetchMetrics.success, (state, { payload }) => ({
    ...state,
    performanceData: payload,
    performanceDataLoading: false,
  }))
  .handleAction(fetchMetrics.failure, (state) => ({ ...state, performanceDataLoading: false }))
  .handleAction(showStackRankDrillDown, (state) => ({
    ...state,
    drillDownViewState: {
      columns: state.drillDownViewState.columns,
      grouping: "user",
      teamId: undefined,
    },
    drillDownVisible: true,
  }))
  .handleAction(hideStackRankDrillDown, (state) => ({ ...state, drillDownVisible: false }))
  .handleAction(fetchDrillDownData.request, (state, { payload }) => ({
    ...state,
    drillDownLoading: true,
    drillDownViewState: { ...state.drillDownViewState, ...payload.drillDownViewState },
    viewState: { ...state.viewState, ...payload.viewState },
  }))
  .handleAction(fetchDrillDownData.success, (state, { payload }) => ({
    ...state,
    drillDownData: payload,
    drillDownLoading: false,
  }))
  .handleAction(fetchDrillDownData.failure, (state) => ({ ...state, drillDownLoading: false }))
  .handleAction(updateDrillDownViewState, (state, { payload }) => ({
    ...state,
    drillDownViewState: { ...state.drillDownViewState, ...payload },
  }))
  .handleAction(showUserDrillDown.success, (state, { payload }) => ({
    ...state,
    userMetricDrillDownViewState: payload.viewState,
  }))
  .handleAction(hideUserDrillDown, (state) => ({
    ...state,
    userMetricDrillDownViewState: undefined,
  }))
  .handleAction(fetchUserDrillDownData.request, (state) => ({
    ...state,
    userMetricDrillDownLoading: true,
  }))
  .handleAction(fetchUserDrillDownData.success, (state, { payload }) => ({
    ...state,
    totalUserDrillDownFilteredRecords: payload.totalFilteredRecords,
    totalUserDrillDownRecords: payload.totalRecords,
    userMetricDrillDownLoading: false,
  }))
  .handleAction(fetchUserDrillDownData.failure, (state) => ({
    ...state,
    userMetricDrillDownLoading: false,
  }))
  .handleAction(applyUserDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    userMetricDrillDownViewState: {
      ...state.userMetricDrillDownViewState,
      columns: payload.columns ?? state.userMetricDrillDownViewState!.columns,
      filter: payload.filter ?? state.userMetricDrillDownViewState!.filter,
      range: payload.range ?? state.userMetricDrillDownViewState!.range,
      sort: payload.sort ?? state.userMetricDrillDownViewState!.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.userMetricDrillDownViewState!.viewAs,
    },
  }));

export default stackRank;
