import PinLegendType from "@mapmycustomers/shared/enum/PinLegendType";
import PinLegend, { ShapePinLegend } from "@mapmycustomers/shared/types/map/PinLegend";

const isSingleShapePinLegend = (pinLegend?: PinLegend): pinLegend is ShapePinLegend =>
  pinLegend?.fieldName === "entity" &&
  !pinLegend?.customField &&
  pinLegend?.default === true &&
  pinLegend?.type === PinLegendType.SHAPE &&
  pinLegend?.thematic?.shapes?.length === 1;

export default isSingleShapePinLegend;
