import { defineMessage } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";

import i18nService from "@app/config/I18nService";
import EmptyFilter from "@app/util/filters/EmptyFilter";
import NoContactFilter, { NO_CONTACT_FILTER_OPERATORS } from "@app/util/filters/NoContactFilter";

import Field, { FieldProperties } from "./Field";

const formattedMessage = defineMessage({
  id: "noContactField.message",
  defaultMessage: "{days} {days, plural, one {day} other {days}}",
  description: "No contact in field's formatted message",
});

const valueFormatter = (entity: unknown, value: unknown) => {
  return value
    ? i18nService.formatMessage(formattedMessage, String(value), { days: value as number })
    : "";
};

class NoContactField extends Field {
  constructor(data: Omit<FieldProperties, "type">) {
    super({
      customFilterConfig: {
        defaultInstance: "noContact",
        filterInstances: { empty: EmptyFilter, noContact: NoContactFilter },
        operators: [
          ...NO_CONTACT_FILTER_OPERATORS,
          { instance: "empty", operator: FilterOperator.EMPTY },
        ],
      },
      features: [
        FieldFeature.ENGAGEMENT,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.FILTERABLE,
        FieldFeature.NO_CONTACT_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NUMERIC,
      ],
      type: FieldType.NUMBER,
      valueFormatter,
      ...data,
    });
  }
}

export default NoContactField;
