import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Activity } from "@mapmycustomers/shared/types/entity";
import { MultiPin } from "@mapmycustomers/shared/types/map";

import ViewMode from "@app/enum/dashboard/ViewMode";
import { DrillDownListFetcherPayload } from "@app/scene/dashboard/types/DrillDownListFetcher";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import UpcomingActivitiesCardConfiguration from "@app/types/dashboard/cards/upcomingActivities/UpcomingActivitiesCardConfiguration";
import Scope from "@app/types/dashboard/Scope";
import MapViewportState from "@app/types/map/MapViewportState";

import UpcomingActivitiesCardData from "./UpcomingActivitiesCardData";

export const fetchUpcomingActivitiesCardData = createAction(
  "@dashboard/fetchUpcomingActivitiesCardData"
)<{
  callback: (data: UpcomingActivitiesCardData) => void;
  configuration: UpcomingActivitiesCardConfiguration;
  failureCallback?: () => void;
  offset?: number; // used with List view mode when we need to load more items
  scope?: Scope;
  viewMode: ViewMode;
  viewport?: MapViewportState; // used with Map view mode when user pans/zooms
}>();

export const showUpcomingActivitiesDrillDown = createAsyncAction(
  "@dashboard/showUpcomingActivitiesCardDrillDownModal/request",
  "@dashboard/showUpcomingActivitiesCardDrillDownModal/success",
  "@dashboard/showUpcomingActivitiesCardDrillDownModal/failure"
)<
  {
    bounds?: MultiPin["bounds"];
    configuration: UpcomingActivitiesCardConfiguration;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideUpcomingActivitiesDrillDown = createAction(
  "@dashboard/hideUpcomingActivitiesCardDrillDownModal"
)<void>();

export const fetchUpcomingActivitiesCardDrillDownData = createAsyncAction(
  "@dashboard/fetchUpcomingActivitiesCardDrillDownData/request",
  "@dashboard/fetchUpcomingActivitiesCardDrillDownData/success",
  "@dashboard/fetchUpcomingActivitiesCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<Activity>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyUpcomingActivitiesDrillDownListViewSettings = createAction(
  "@dashboard/applyUpcomingActivitiesDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportUpcomingActivitiesCardDrillDownData = createAction(
  "@dashboard/exportUpcomingActivitiesCardData"
)<{
  configuration: UpcomingActivitiesCardConfiguration;
  scope: Scope;
}>();

export type UpcomingActivitiesCardActions = ActionType<
  | typeof applyUpcomingActivitiesDrillDownListViewSettings
  | typeof exportUpcomingActivitiesCardDrillDownData
  | typeof fetchUpcomingActivitiesCardData
  | typeof fetchUpcomingActivitiesCardDrillDownData
  | typeof hideUpcomingActivitiesDrillDown
  | typeof showUpcomingActivitiesDrillDown
>;
