import React, { useCallback, useMemo, useState } from "react";

import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import Button from "antd/es/button";
import cn from "classnames";

import Team from "@mapmycustomers/shared/types/Team";
import SelectableTeam from "@mapmycustomers/shared/types/ui/SelectableTeam";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import TeamItem from "../common/TeamItem";
import { useConfigProvider } from "../ConfigProvider";
import TextField from "../input/TextField";

const block = bem("mmc-team-picker");

export const messages = {
  "ui.teamPicker.deselectAll": "Deselect All",
  "ui.teamPicker.search.placeholder": "Search teams",
  "ui.teamPicker.selectAll": "Select All",
} as const;

export interface TeamPickerProps {
  className?: string;
  onChange?: (value: Team[]) => void;
  teams: Team[];
  value?: Team[];
}

export const TeamPicker: React.FC<TeamPickerProps> = ({ className, onChange, teams, value }) => {
  const configProvider = useConfigProvider();
  const [query, setQuery] = useState("");
  const selectedTeamIds = useMemo(() => value?.map(({ id }) => id) ?? [], [value]);

  const filteredTeams = useMemo(
    () =>
      teams
        .filter((team) => !query || team.name.toLowerCase().includes(query.toLowerCase()))
        .sort(nameComparator),
    [query, teams]
  );

  const handleToggle = useCallback(
    (selectedTeam: SelectableTeam) => {
      if (typeof selectedTeam === "object") {
        if (selectedTeamIds.includes(selectedTeam.id)) {
          onChange?.(
            teams.filter((team) => selectedTeamIds.includes(team.id) && team.id !== selectedTeam.id)
          );
        } else {
          onChange?.([...teams.filter((team) => selectedTeamIds.includes(team.id)), selectedTeam]);
        }
      }
    },
    [onChange, selectedTeamIds, teams]
  );

  const handleSelectAll = useCallback(() => onChange?.(teams), [onChange, teams]);
  const handleDeselectAll = useCallback(() => onChange?.([]), [onChange]);

  return (
    <div className={cn(block(), className)}>
      <TextField
        className={block("search-field")}
        onChange={setQuery}
        placeholder={configProvider.formatMessage("ui.teamPicker.search.placeholder")}
        size="middle"
        suffix={<FontAwesomeIcon className={block("search-icon")} icon={faSearch} />}
        value={query}
      />
      <div className={block("team-list")}>
        {filteredTeams.map((team) => {
          return (
            <TeamItem
              key={team.id}
              onClick={handleToggle}
              selected={selectedTeamIds.includes(team.id)}
              team={team}
            />
          );
        })}
      </div>
      <div className={block("buttons")}>
        <Button className={block("deselect-button")} onClick={handleDeselectAll} size="small">
          {configProvider.formatMessage("ui.teamPicker.deselectAll")}
        </Button>
        <Button
          className={cn(block("select-button"))}
          onClick={handleSelectAll}
          size="small"
          type="text"
        >
          {configProvider.formatMessage("ui.teamPicker.selectAll")}
        </Button>
      </div>
    </div>
  );
};

export default TeamPicker;
