import { createReducer } from "typesafe-actions";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { MultiPin } from "@mapmycustomers/shared/types/map";

import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import UpcomingActivitiesCardConfiguration from "@app/types/dashboard/cards/upcomingActivities/UpcomingActivitiesCardConfiguration";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";
import activityFieldModel, { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  applyUpcomingActivitiesDrillDownListViewSettings,
  fetchUpcomingActivitiesCardDrillDownData,
  hideUpcomingActivitiesDrillDown,
  showUpcomingActivitiesDrillDown,
  UpcomingActivitiesCardActions,
} from "./actions";

const defaultColumns: string[] = [
  ActivityFieldName.PRIMARY_ASSOCIATION,
  ActivityFieldName.ACTIVITY_TYPE,
  ActivityFieldName.ASSIGNEE,
  ActivityFieldName.START_DATE,
  ActivityFieldName.END_DATE,
  ActivityFieldName.NOTE,
];

const defaultSortColumn = ActivityFieldName.COMPLETED_AT;

export interface UpcomingActivitiesCardState {
  bounds?: MultiPin["bounds"];
  configuration?: UpcomingActivitiesCardConfiguration; // also works as a "visible" trigger, visible if set
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const upcomingActivitiesInitialState: UpcomingActivitiesCardState = {
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: getDefaultDrillDownColumns(defaultColumns, activityFieldModel),
    filter: {},
    range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
    sort: [
      {
        field: activityFieldModel.getByName(defaultSortColumn)!,
        order: SortOrder.DESC,
      },
    ],
    viewAs: undefined,
  },
};

const upcomingActivitiesState = createReducer<
  UpcomingActivitiesCardState,
  UpcomingActivitiesCardActions
>(upcomingActivitiesInitialState)
  .handleAction(showUpcomingActivitiesDrillDown.request, (state, { payload }) => ({
    ...state,
    bounds: payload.bounds,
    configuration: payload.configuration,
  }))
  .handleAction(showUpcomingActivitiesDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideUpcomingActivitiesDrillDown, (state) => ({
    ...state,
    configuration: undefined,
  }))
  .handleAction(fetchUpcomingActivitiesCardDrillDownData.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchUpcomingActivitiesCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchUpcomingActivitiesCardDrillDownData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyUpcomingActivitiesDrillDownListViewSettings, (state, { payload }) => {
    return {
      ...state,
      viewState: {
        ...state.viewState,
        columns: payload.columns ?? state.viewState.columns,
        filter: payload.filter ?? state.viewState.filter,
        range: payload.range ?? state.viewState.range,
        sort: payload.sort ?? state.viewState.sort,
        // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
        viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
      },
    };
  });

export default upcomingActivitiesState;
