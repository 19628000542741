import React, { useCallback } from "react";
import { connect } from "react-redux";

import { roundToNearestMinutes, set } from "date-fns/esm";
import { useIntl } from "react-intl";

import { Company, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";

import { showGlobalCreateActivityModal } from "@app/component/createEditEntity/Activity/store/actions";
import Table from "@app/component/Table";
import { showEntityView } from "@app/store/entityView/actions";
import { parseApiDateWithTz } from "@app/util/parsers";

import styles from "./EntitiesTable.module.scss";
import useColumns from "./useColumns";

interface Props {
  cadencedAt: string;
  className?: string;
  entities: OutOfCadenceEntity[];
  loading?: boolean;
  onCreateActivity: typeof showGlobalCreateActivityModal;
  showEntityView: typeof showEntityView;
}

const EntitiesTable: React.FC<Props> = ({
  cadencedAt,
  className,
  entities,
  loading,
  onCreateActivity,
  showEntityView,
}) => {
  const intl = useIntl();

  const rowEventHandlers = useCallback(
    (entity: OutOfCadenceEntity) => ({
      onClick: () => showEntityView({ entityId: entity.id, entityType: entity.entity }),
    }),
    [showEntityView]
  );

  const handleAddActivity = useCallback(
    (entity: OutOfCadenceEntity) => {
      let account: Company | undefined;
      let contact: Person | undefined;
      let deal: Deal | undefined;
      if (entity.entity === EntityType.COMPANY) {
        account = entity as Company;
      } else if (entity.entity === EntityType.PERSON) {
        contact = entity as Person;
        account = (contact.primaryAccount ?? contact.accounts[0]) as Company | undefined;
      } else if (entity.entity === EntityType.DEAL) {
        deal = entity as Deal;
        contact = deal.contact;
        account = deal.account;
      }
      const now = new Date();
      let startAt = parseApiDateWithTz(cadencedAt);
      startAt = roundToNearestMinutes(
        set(startAt, { hours: now.getHours(), minutes: now.getMinutes() }),
        { nearestTo: 15 }
      );
      onCreateActivity({ activity: { account, contact, deal, startAt: startAt.toISOString() } });
    },
    [onCreateActivity, cadencedAt]
  );

  return (
    <Table<OutOfCadenceEntity>
      className={className}
      clickableRows
      columns={useColumns(intl, handleAddActivity)}
      dataSource={entities}
      loading={loading}
      onRow={rowEventHandlers}
      pagination={false}
      rowClassName={styles.row}
      rowKey="id"
      scrollable
      showBorder
    />
  );
};

const mapDispatchToProps = {
  onCreateActivity: showGlobalCreateActivityModal,
  showEntityView,
};

export default connect(null, mapDispatchToProps)(EntitiesTable);
