import { all, call, put, select, takeLatest } from "redux-saga/effects";

import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import type LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import { AnyEntity } from "@mapmycustomers/shared/types/entity/types";
import Organization from "@mapmycustomers/shared/types/Organization";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import localSettings from "@app/config/LocalSettings";
import { METRIC_TO_ENTITY_TYPE } from "@app/scene/dashboard/store/const";
import StackRankDrillDownViewState from "@app/scene/dashboard/store/stackRank/DrillDownViewState";
import StackRankViewState from "@app/scene/dashboard/store/stackRank/StackRankViewState";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import PerformanceData from "@app/scene/dashboard/types/PerformanceData";
import upsertPerformanceData from "@app/scene/dashboard/utils/upsertPerformanceData";
import { isStackRankAvailable } from "@app/scene/reports/store/selectors";
import { ApiMethodName } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { isOwner } from "@app/store/iam/util";
import { getTeams, getUsers } from "@app/store/members";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";
import { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import { CompanyFieldName } from "@app/util/fieldModel/CompanyFieldModel";
import { DealFieldName } from "@app/util/fieldModel/DealFieldModel";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";
import { RouteFieldName } from "@app/util/fieldModel/RouteFieldModel";
import loggingService from "@app/util/logging";
import { not } from "@app/util/logic";
import downloadStackRankDataAsCsv from "@app/util/stackRank/downloadStackRankDataAsCsv";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import dashboardSettings from "../../utils/dashboardSettings";
import getDefaultDrillDownColumns from "../utils/getDefaultDrillDownColumns";

import {
  applyUserDrillDownListViewSettings,
  exportDrillDownData,
  fetchDrillDownData,
  fetchMetrics,
  fetchUserDrillDownData,
  initialize,
  showUserDrillDown,
  updateStackRankViewState,
} from "./actions";
import {
  getDrillDownData,
  getDrillDownViewState,
  getStackRankViewState,
  getUserDrillDownViewState,
} from "./selectors";

function* onInitialize() {
  try {
    const localSettingsViewState: StackRankViewState | undefined = yield call(
      dashboardSettings.getStackRankViewState
    );
    yield put(initialize.success(localSettingsViewState));
    yield put(fetchMetrics.request({}));
  } catch (error) {
    yield handleError({ error });
    yield put(initialize.failure());
  }
}

function* onFetchMetrics() {
  try {
    const stackRankVisibility: boolean = yield select(isStackRankAvailable);
    if (!stackRankVisibility) {
      yield put(fetchMetrics.success({}));
      return;
    }

    const stackRankViewState: StackRankViewState = yield select(getStackRankViewState);
    yield call(dashboardSettings.setStackRankViewState, stackRankViewState);
    const { dateRange, filters, rankingMetric } = stackRankViewState;
    const metric = rankingMetric.name as LeaderboardMetricFieldName;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const teams: Team[] = yield select(getTeams);
    const users: User[] = yield select(getUsers);

    const teamsWithUsers = teams.filter(({ users }) => users.length);
    const usersWithoutTeam = users.filter(({ teams }) => !teams.length);
    const owners = usersWithoutTeam.filter(isOwner);
    const noTeamUsers = usersWithoutTeam.filter(not(isOwner));

    const payload = {
      crmActivityTypeId: filters?.activityTypeIds?.length
        ? { $in: filters.activityTypeIds }
        : undefined,
      date: {
        $gte: dateRange.startDate.toISOString(),
        $lte: dateRange.endDate.toISOString(),
      },
      funnelId: filters?.funnelIds?.length ? { $in: filters.funnelIds } : undefined,
      includePerformanceMetric: true,
      property: { $in: [metric] },
      source: filters?.sources?.length ? { $in: filters.sources } : undefined,
      stageId: filters?.stageIds?.length ? { $in: filters.stageIds } : undefined,
      summary: "user" as const,
    };

    const responses: ListResponse<LeaderboardItem>[] = yield all([
      callApi("fetchLeaderboardData", orgId, { ...payload, summary: "all" }, metric),
      callApi("fetchLeaderboardData", orgId, { ...payload, summary: "team" }, metric),
      // since we need to get ranking within each team independently, we send
      // a request for each team separately
      ...teamsWithUsers.map((team) =>
        callApi(
          "fetchLeaderboardData",
          orgId,
          { ...payload, userId: { $in: team.users.map(({ id }) => id) } },
          metric
        )
      ),
      ...(owners.length
        ? [
            callApi(
              "fetchLeaderboardData",
              orgId,
              { ...payload, userId: { $in: owners.map(({ id }) => id) } },
              metric
            ),
          ]
        : []),
      ...(noTeamUsers.length
        ? [
            callApi(
              "fetchLeaderboardData",
              orgId,
              { ...payload, userId: { $in: noTeamUsers.map(({ id }) => id) } },
              metric
            ),
          ]
        : []),
    ]);

    // build performance data for users and teams
    const performanceData: PerformanceData = {};
    teamsWithUsers.forEach((team, index) => {
      upsertPerformanceData(
        performanceData,
        metric,
        responses[index + 2].data,
        team.users,
        team.id
      );
    });
    if (owners.length) {
      const data = responses.at(noTeamUsers.length ? -2 : -1)!.data;
      upsertPerformanceData(performanceData, metric, data, owners);
    }
    if (noTeamUsers.length) {
      upsertPerformanceData(performanceData, metric, responses.at(-1)!.data, noTeamUsers, "noTeam");
    }

    if (metric in responses[0].data[0].values) {
      performanceData.undefined_undefined = {
        value: responses[0].data[0].values[metric],
      };
    }

    responses[1].data.forEach((item) => {
      if (metric in item.values) {
        performanceData[`${item.teamId}_undefined`] = {
          rank: item.rank,
          rankDiff: item.rankDiff,
          value: item.values[metric],
        };
      }
    });

    yield put(fetchMetrics.success(performanceData));
  } catch (error) {
    yield handleError({ error });
    yield put(fetchMetrics.failure());
  }
}

function* onFetchDrillDownData() {
  try {
    const stackRankViewState: StackRankViewState = yield select(getStackRankViewState);
    yield call(dashboardSettings.setStackRankViewState, stackRankViewState);
    const { dateRange, filters, rankingMetric } = stackRankViewState;
    const { grouping, teamId }: StackRankDrillDownViewState = yield select(getDrillDownViewState);

    const teams: Team[] = yield select(getTeams);
    const teamUsersIds = teamId
      ? teams.find(({ id }) => id === teamId)?.users.map(({ id }) => id)
      : undefined;

    const metric = rankingMetric.name as LeaderboardMetricFieldName;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        crmActivityTypeId: filters?.activityTypeIds?.length
          ? { $in: filters.activityTypeIds }
          : undefined,
        date: { $gte: dateRange.startDate.toISOString(), $lte: dateRange.endDate.toISOString() },
        funnelId: filters?.funnelIds?.length ? { $in: filters.funnelIds } : undefined,
        includePerformanceMetric: true,
        property: { $in: Object.values(LeaderboardMetricFieldName) }, // fetch all props
        source: filters?.sources?.length ? { $in: filters.sources } : undefined,
        stageId: filters?.stageIds?.length ? { $in: filters.stageIds } : undefined,
        summary: grouping === "team" ? "team" : "user",
        userId: grouping === "user" && teamUsersIds ? { $in: teamUsersIds } : undefined,
      },
      metric
    );

    yield put(fetchDrillDownData.success(response.data));
  } catch (error) {
    yield handleError({ error });
    yield put(fetchDrillDownData.failure());
  }
}

export function* onExportDrillDownData() {
  const data: LeaderboardItem[] = yield select(getDrillDownData);
  const viewState: StackRankDrillDownViewState = yield select(getDrillDownViewState);
  downloadStackRankDataAsCsv(
    data,
    viewState.columns.filter(({ visible }) => visible).map(({ field }) => field)
  );
}

const DEFAULT_COLUMNS: Record<LeaderboardMetricFieldName, string[]> = {
  [LeaderboardMetricFieldName.ACTIVITIES_COMPLETED]: [
    ActivityFieldName.PRIMARY_ASSOCIATION,
    ActivityFieldName.ACTIVITY_TYPE,
    ActivityFieldName.ASSIGNEE,
    ActivityFieldName.START_AT,
    ActivityFieldName.COMPLETED_AT,
    ActivityFieldName.NOTE,
  ],
  [LeaderboardMetricFieldName.CHECK_INS_COMPLETED]: [
    ActivityFieldName.PRIMARY_ASSOCIATION,
    ActivityFieldName.ACTIVITY_TYPE,
    ActivityFieldName.ASSIGNEE,
    ActivityFieldName.COMPLETED_AT,
    ActivityFieldName.CHECK_IN_DISTANCE,
    ActivityFieldName.RELIABILITY,
    ActivityFieldName.NOTE,
  ],
  [LeaderboardMetricFieldName.COMPANIES_CREATED]: [
    CompanyFieldName.NAME,
    CompanyFieldName.USER,
    CompanyFieldName.ADDRESS,
    CompanyFieldName.CREATED_AT,
    CompanyFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.DEALS_CLOSED_LOST]: [
    DealFieldName.NAME,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.DEALS_CLOSED_WON]: [
    DealFieldName.NAME,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.DEALS_CREATED]: [
    DealFieldName.NAME,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.DEALS_ROTTING]: [
    DealFieldName.NAME,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE]: [
    DealFieldName.NAME,
    DealFieldName.AMOUNT,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.PEOPLE_CREATED]: [
    PersonFieldName.NAME,
    PersonFieldName.USER,
    PersonFieldName.ADDRESS,
    PersonFieldName.CREATED_AT,
    PersonFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.REVENUE_CLOSED_LOST]: [
    DealFieldName.NAME,
    DealFieldName.AMOUNT,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.REVENUE_CLOSED_WON]: [
    DealFieldName.NAME,
    DealFieldName.AMOUNT,
    DealFieldName.USER,
    DealFieldName.ADDRESS,
    DealFieldName.CREATED_AT,
    DealFieldName.SOURCE,
  ],
  [LeaderboardMetricFieldName.ROUTES_CREATED]: [
    RouteFieldName.NAME,
    RouteFieldName.COUNT,
    RouteFieldName.START_ADDRESS,
    RouteFieldName.CREATED_AT,
  ],
  [LeaderboardMetricFieldName.TOTAL_FACE_TIME]: [
    ActivityFieldName.PRIMARY_ASSOCIATION,
    ActivityFieldName.ACTIVITY_TYPE,
    ActivityFieldName.ASSIGNEE,
    ActivityFieldName.COMPLETED_AT,
    ActivityFieldName.CHECK_IN_DISTANCE,
    ActivityFieldName.RELIABILITY,
    ActivityFieldName.NOTE,
  ],
  [LeaderboardMetricFieldName.VERIFIED_CHECK_INS]: [
    ActivityFieldName.PRIMARY_ASSOCIATION,
    ActivityFieldName.ACTIVITY_TYPE,
    ActivityFieldName.ASSIGNEE,
    ActivityFieldName.COMPLETED_AT,
    ActivityFieldName.CHECK_IN_DISTANCE,
    ActivityFieldName.RELIABILITY,
    ActivityFieldName.NOTE,
  ],
  [LeaderboardMetricFieldName.VERIFIED_FACE_TIME]: [
    ActivityFieldName.PRIMARY_ASSOCIATION,
    ActivityFieldName.ACTIVITY_TYPE,
    ActivityFieldName.ASSIGNEE,
    ActivityFieldName.COMPLETED_AT,
    ActivityFieldName.CHECK_IN_DISTANCE,
    ActivityFieldName.RELIABILITY,
    ActivityFieldName.NOTE,
  ],
};

const DEFAULT_SORT_COLUMN: Record<LeaderboardMetricFieldName, string> = {
  [LeaderboardMetricFieldName.ACTIVITIES_COMPLETED]: ActivityFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.CHECK_INS_COMPLETED]: ActivityFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.COMPANIES_CREATED]: CompanyFieldName.CREATED_AT,
  [LeaderboardMetricFieldName.DEALS_CLOSED_LOST]: DealFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.DEALS_CLOSED_WON]: DealFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.DEALS_CREATED]: DealFieldName.CREATED_AT,
  [LeaderboardMetricFieldName.DEALS_ROTTING]: DealFieldName.CREATED_AT,
  [LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE]: DealFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.PEOPLE_CREATED]: PersonFieldName.CREATED_AT,
  [LeaderboardMetricFieldName.REVENUE_CLOSED_LOST]: DealFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.REVENUE_CLOSED_WON]: DealFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.ROUTES_CREATED]: RouteFieldName.CREATED_AT,
  [LeaderboardMetricFieldName.TOTAL_FACE_TIME]: ActivityFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.VERIFIED_CHECK_INS]: ActivityFieldName.UPDATED_AT,
  [LeaderboardMetricFieldName.VERIFIED_FACE_TIME]: ActivityFieldName.UPDATED_AT,
};

const DEFAULT_SORT_ORDER: Record<LeaderboardMetricFieldName, SortOrder> = {
  [LeaderboardMetricFieldName.ACTIVITIES_COMPLETED]: SortOrder.DESC,
  [LeaderboardMetricFieldName.CHECK_INS_COMPLETED]: SortOrder.DESC,
  [LeaderboardMetricFieldName.COMPANIES_CREATED]: SortOrder.DESC,
  [LeaderboardMetricFieldName.DEALS_CLOSED_LOST]: SortOrder.DESC,
  [LeaderboardMetricFieldName.DEALS_CLOSED_WON]: SortOrder.DESC,
  [LeaderboardMetricFieldName.DEALS_CREATED]: SortOrder.DESC,
  [LeaderboardMetricFieldName.DEALS_ROTTING]: SortOrder.ASC,
  [LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE]: SortOrder.DESC,
  [LeaderboardMetricFieldName.PEOPLE_CREATED]: SortOrder.DESC,
  [LeaderboardMetricFieldName.REVENUE_CLOSED_LOST]: SortOrder.DESC,
  [LeaderboardMetricFieldName.REVENUE_CLOSED_WON]: SortOrder.DESC,
  [LeaderboardMetricFieldName.ROUTES_CREATED]: SortOrder.DESC,
  [LeaderboardMetricFieldName.TOTAL_FACE_TIME]: SortOrder.DESC,
  [LeaderboardMetricFieldName.VERIFIED_CHECK_INS]: SortOrder.DESC,
  [LeaderboardMetricFieldName.VERIFIED_FACE_TIME]: SortOrder.DESC,
};

const USER_DRILL_DOWN_API_METHOD: Record<LeaderboardMetricFieldName, ApiMethodName> = {
  [LeaderboardMetricFieldName.ACTIVITIES_COMPLETED]: "fetchActivities",
  [LeaderboardMetricFieldName.CHECK_INS_COMPLETED]: "fetchActivities",
  [LeaderboardMetricFieldName.COMPANIES_CREATED]: "fetchCompanies",
  [LeaderboardMetricFieldName.DEALS_CLOSED_LOST]: "fetchDeals",
  [LeaderboardMetricFieldName.DEALS_CLOSED_WON]: "fetchDeals",
  [LeaderboardMetricFieldName.DEALS_CREATED]: "fetchDeals",
  [LeaderboardMetricFieldName.DEALS_ROTTING]: "fetchDeals",
  [LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE]: "fetchDeals",
  [LeaderboardMetricFieldName.PEOPLE_CREATED]: "fetchPeople",
  [LeaderboardMetricFieldName.REVENUE_CLOSED_LOST]: "fetchDeals",
  [LeaderboardMetricFieldName.REVENUE_CLOSED_WON]: "fetchDeals",
  [LeaderboardMetricFieldName.ROUTES_CREATED]: "fetchCompanyRoutes",
  [LeaderboardMetricFieldName.TOTAL_FACE_TIME]: "fetchActivities",
  [LeaderboardMetricFieldName.VERIFIED_CHECK_INS]: "fetchActivities",
  [LeaderboardMetricFieldName.VERIFIED_FACE_TIME]: "fetchActivities",
};

export function* onShowUserDrillDown({ payload }: ReturnType<typeof showUserDrillDown.request>) {
  const userIds = payload;
  const { dateRange, filters, rankingMetric }: StackRankViewState = yield select(
    getStackRankViewState
  );

  const metric = rankingMetric.name as LeaderboardMetricFieldName;
  const entityType = METRIC_TO_ENTITY_TYPE[metric];
  const fieldModel = getFieldModelByEntityType(entityType);

  const drillDownViewState: DrillDownViewState = yield select(getDrillDownViewState);
  const viewState = localSettings.getViewSettings(`dashboard/stackRank/${metric}`, fieldModel, {
    ...drillDownViewState,
    columns: getDefaultDrillDownColumns(DEFAULT_COLUMNS[metric], fieldModel),
    range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
    sort: [
      {
        field: fieldModel.getByName(DEFAULT_SORT_COLUMN[metric])!,
        order: DEFAULT_SORT_ORDER[metric],
      },
    ],
  });

  const filter: FilterModel = {};
  if (metric !== LeaderboardMetricFieldName.ROUTES_CREATED) {
    if (filters.activityTypeIds) {
      filter.activityType = { operator: FilterOperator.IN_ANY, value: filters.activityTypeIds };
    }
    if (filters.sources) {
      filter.sourceCreated = { operator: FilterOperator.IN_ANY, value: filters.sources };
    }
    if (filters.funnelIds) {
      filter.funnel = { operator: FilterOperator.IN_ANY, value: filters.funnelIds };
    }
    if (filters.stageIds) {
      filter.stage = { operator: FilterOperator.IN_ANY, value: filters.stageIds };
    }
  }
  if (userIds && userIds.length) {
    filter.user = { operator: FilterOperator.IN_ANY, value: userIds };
  }

  switch (metric) {
    case LeaderboardMetricFieldName.ACTIVITIES_COMPLETED:
      filter.completed = { operator: FilterOperator.EQUALS, value: true };
      filter.completedAt = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      break;
    case LeaderboardMetricFieldName.CHECK_INS_COMPLETED:
      filter.completed = { operator: FilterOperator.EQUALS, value: true };
      filter.completedAt = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      filter.reliability = { operator: FilterOperator.NOT_EMPTY, value: undefined };
      break;
    case LeaderboardMetricFieldName.COMPANIES_CREATED:
      filter.createdAt = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      break;
    case LeaderboardMetricFieldName.DEALS_CLOSED_LOST:
      filter.closingDate = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      filter["stage.type"] = { operator: FilterOperator.IN_ANY, value: [DealStageType.LOST] };
      break;
    case LeaderboardMetricFieldName.DEALS_CLOSED_WON:
      filter.closingDate = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      filter["stage.type"] = { operator: FilterOperator.IN_ANY, value: [DealStageType.WON] };
      break;
    case LeaderboardMetricFieldName.DEALS_CREATED:
      filter.createdAt = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      break;
    case LeaderboardMetricFieldName.DEALS_ROTTING:
      filter.rottingDaysOut = { operator: FilterOperator.IS_OVERDUE, value: undefined };
      break;
    case LeaderboardMetricFieldName.NEW_PIPELINE_REVENUE:
      filter.createdAt = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      break;
    case LeaderboardMetricFieldName.PEOPLE_CREATED:
      filter.createdAt = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      break;
    case LeaderboardMetricFieldName.REVENUE_CLOSED_LOST:
      filter.closingDate = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      filter["stage.type"] = { operator: FilterOperator.IN_ANY, value: [DealStageType.LOST] };
      break;
    case LeaderboardMetricFieldName.REVENUE_CLOSED_WON:
      filter.closingDate = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      filter["stage.type"] = { operator: FilterOperator.IN_ANY, value: [DealStageType.WON] };
      break;
    case LeaderboardMetricFieldName.ROUTES_CREATED:
      filter.createdAt = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      break;
    case LeaderboardMetricFieldName.VERIFIED_CHECK_INS:
      filter.completed = { operator: FilterOperator.EQUALS, value: true };
      filter.completedAt = {
        operator: FilterOperator.IN_RANGE,
        value: [dateRange.startDate, dateRange.endDate],
      };
      filter.reliability = { operator: FilterOperator.EQUALS, value: true };
      break;
  }

  viewState.filter = filter;

  yield put(showUserDrillDown.success({ viewState }));
}

export function* onFetchUserDrillDownData({
  payload,
}: ReturnType<typeof fetchUserDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: stack rank, onFetchUserDrillDownData", payload);

    const { rankingMetric }: StackRankViewState = yield select(getStackRankViewState);
    const metric = rankingMetric.name as LeaderboardMetricFieldName;
    const entityType = METRIC_TO_ENTITY_TYPE[metric];
    const fieldModel = getFieldModelByEntityType(entityType);

    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyUserDrillDownListViewSettings(payload.request));
    }

    const drillDownViewState: DrillDownViewState = yield select(getUserDrillDownViewState);

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(drillDownViewState, `dashboard/stackRank/${metric}`);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          fieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const response: ListResponse<AnyEntity> = yield callApi(
      USER_DRILL_DOWN_API_METHOD[metric],
      orgId,
      requestPayload
    );

    if (payload.dataCallback) {
      yield call(payload.dataCallback, response);
    }

    yield put(
      fetchUserDrillDownData.success({
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchUserDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onUpdateStackRankViewState() {
  const stackRankViewState: StackRankViewState = yield select(getStackRankViewState);
  yield call(dashboardSettings.setStackRankViewState, stackRankViewState);
}

export function* stackRankSagas() {
  yield takeLatest(initialize.request, onInitialize);
  yield takeLatest(fetchMetrics.request, onFetchMetrics);
  yield takeLatest(fetchDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportDrillDownData, onExportDrillDownData);
  yield takeLatest(showUserDrillDown.request, onShowUserDrillDown);
  yield takeLatest(fetchUserDrillDownData.request, onFetchUserDrillDownData);
  yield takeLatest(updateStackRankViewState, onUpdateStackRankViewState);
}
