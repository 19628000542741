import { call, put, select, takeEvery } from "redux-saga/effects";

import { Person } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";

import { fetchPeople, fetchPerson } from "./actions";

function* onFetchPerson({ payload }: ReturnType<typeof fetchPerson>) {
  const { id, callback, failureCallback, options = {} } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const person: Person = yield callApi("fetchPerson", orgId, id, options);
    yield call(callback, person);
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onFetchPeople({ payload }: ReturnType<typeof fetchPeople>) {
  const { callback, failureCallback, options = {} } = payload;
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<Person> = yield callApi("fetchPeople", orgId, options);
    yield call(callback, response);
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

export function* personSaga() {
  yield takeEvery(fetchPerson, onFetchPerson);
  yield takeEvery(fetchPeople, onFetchPeople);
}
