import React from "react";

import { faEye, faText } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd/es/grid";
import Popover from "antd/es/popover";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";

import { EntityTypesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useChangeTracking from "@mapmycustomers/shared/util/hook/useChangeTracking";

import Alert from "../../../Alert";
import { useConfigProvider } from "../../../ConfigProvider";
import DynamicVarVariant from "../../type/DynamicVarVariant";
import FileUploadCallback from "../../type/FileUploadCallback";
import styles from "../Components.module.scss";
import InsertLink from "../InsertLink";
import Toolbar from "../Toolbar";
import toolbarStyles from "../Toolbar.module.scss";

import AttachFiles from "./component/AttachFiles";
import DynamicVars from "./component/DynamicVars";
import CreateLink from "./component/Links/CreateLink";

interface Props {
  action?: React.ReactNode;
  dynamicVarVariants?: DynamicVarVariant[];
  editorId?: string;
  entityType?: EntityTypesSupportedByEmailFeature;
  onAction?: (actionKey: string) => void;
  onFilesSelect?: (files: File) => void;
  onLoadFile: FileUploadCallback;
  onPreview?: () => void;
  previewDisableText?: string;
  recipientCount?: number;
}

const Actions: React.FC<Props> = ({
  action,
  dynamicVarVariants,
  editorId,
  entityType,
  onAction,
  onFilesSelect,
  onLoadFile,
  onPreview,
  previewDisableText,
  recipientCount,
}) => {
  const [insetLinkVisible, setInsetLinkVisible] = React.useState(false);
  const configProvider = useConfigProvider();
  const [toolbarVisible, setToolbarVisibility] = React.useState(false);

  useChangeTracking(() => {
    if (toolbarVisible) {
      onAction?.("Text Formatting Bar");
    }
  }, [toolbarVisible]);

  const multipleRecipients = recipientCount !== undefined && recipientCount > 1;
  const [alertVisible, , hideAlert] = useBoolean(true);

  return (
    <div className={styles.container}>
      {alertVisible && multipleRecipients && (
        <Alert
          closable
          message={configProvider.formatMessage("ui.emailBody.toolbar.actions.info")}
          onClose={hideAlert}
          showIcon
        />
      )}
      <Row align="middle" className={styles.actionContainer} justify="space-between">
        <Col>
          <Row align="middle">
            <Col>
              <Popover
                content={<Toolbar onLoadFile={onLoadFile} />}
                destroyTooltipOnHide={false}
                onOpenChange={setToolbarVisibility}
                overlayClassName={styles.toolbarPopover}
                placement="topLeft"
                trigger="click"
              >
                <Tooltip
                  title={configProvider.formatMessage("ui.emailBody.toolbar.actions.formatting")}
                >
                  <span
                    className={cn(toolbarStyles.togglerButton, {
                      [styles.active]: toolbarVisible,
                    })}
                    onMouseDown={stopEvents}
                  >
                    <FontAwesomeIcon icon={faText} />
                  </span>
                </Tooltip>
              </Popover>
            </Col>
            <Col>
              <InsertLink onAction={onAction} />
            </Col>
            <Col>
              <AttachFiles onAction={onAction} onFilesSelect={onFilesSelect} />
            </Col>
            {dynamicVarVariants && (
              <Col>
                <DynamicVars
                  dynamicVarVariants={dynamicVarVariants}
                  editorId={editorId}
                  entityType={entityType}
                  onAction={onAction}
                />
              </Col>
            )}
            <Col>
              <Tooltip title={configProvider.formatMessage("ui.emailBody.toolbar.actions.preview")}>
                <Tooltip title={previewDisableText} trigger={previewDisableText ? ["hover"] : []}>
                  <span
                    className={toolbarStyles.togglerButton}
                    onClick={previewDisableText ? undefined : onPreview}
                    onMouseDown={stopEvents}
                  >
                    <FontAwesomeIcon
                      className={cn({
                        [styles.disabledIcon]: !!previewDisableText,
                      })}
                      icon={faEye}
                    />
                  </span>
                </Tooltip>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col>{action}</Col>
      </Row>
      {insetLinkVisible && <CreateLink onHide={() => setInsetLinkVisible(false)} />}
    </div>
  );
};

export default Actions;
