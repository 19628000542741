import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { LabeledValue } from "antd/es/select";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import {
  Company,
  Deal,
  EntityTypesSupportingCompanyAssociation,
  Person,
} from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { FormItem, SelectField } from "@mapmycustomers/ui";

import CompanyAssociation from "@app/component/associations/CompanyAssociation";
import EntityTypeShapedIcon from "@app/component/EntityTypeIcon/EntityTypeShapedIcon";
import ChangeAssociationsPayload from "@app/store/associations/ChangeAssociationsPayload";
import { fetchCompany } from "@app/store/company/actions";
import showRelateEntitiesAlert from "@app/util/form/showRelateEntitiesAlert";

import styles from "./CommonRelationshipField.module.scss";
import RelateEntitiesRequestPayload from "./RelateEntitiesRequestPayload";

interface Props {
  allowAdd?: boolean;
  associatedCompany?: Company;
  associatedDeal?: Deal;
  associatedPerson?: Person;
  disabled?: boolean;
  entityType: EntityTypesSupportingCompanyAssociation;
  field: IField;
  loading?: boolean;
  onChangeAssociatedEntities: (payload: Omit<ChangeAssociationsPayload, "entityType">) => void;
  onFetchCompany: typeof fetchCompany;
  relateEntities?: (payload: RelateEntitiesRequestPayload) => void;
  required?: boolean;
  suggestedCompanies?: Company[];
}

const CompanyRelationshipField: React.FC<Props> = ({
  allowAdd,
  associatedCompany,
  associatedDeal,
  associatedPerson,
  disabled,
  entityType,
  field,
  onChangeAssociatedEntities,
  onFetchCompany,
  relateEntities,
  required,
  suggestedCompanies,
}) => {
  const form = useFormInstance();
  const intl = useIntl();
  console.log("associatedCompany", associatedCompany);
  const [modalVisible, showModal, hideModal] = useBoolean();
  const [selectedCompany, setSelectedCompany] = useState(associatedCompany);

  const handleSelect = useCallback(
    (companyIds: Company["id"][]) => {
      const companyId = companyIds[0];
      onFetchCompany({
        id: companyId,
        callback: (company: Company) => {
          setSelectedCompany(company);
          form.setFieldValue("companyId", companyId);
          onChangeAssociatedEntities({
            assignee: form.getFieldValue("assignee"),
            company,
            deal: associatedDeal,
            person: associatedPerson,
          });

          showRelateEntitiesAlert(intl, company, associatedPerson, associatedDeal, relateEntities);
        },
        options: { includeUsersWithAccess: true },
      });
    },
    [
      associatedDeal,
      associatedPerson,
      form,
      intl,
      onChangeAssociatedEntities,
      onFetchCompany,
      relateEntities,
    ]
  );

  const handleRemove = useCallback(() => {
    onChangeAssociatedEntities({
      assignee: form.getFieldValue("assignee"),
      company: undefined,
      deal: associatedDeal,
      person: associatedPerson,
    });
    form.setFieldValue("companyId", undefined);
  }, [associatedDeal, associatedPerson, form, onChangeAssociatedEntities]);

  const options = useMemo<LabeledValue[]>(
    () =>
      selectedCompany
        ? [
            {
              key: String(selectedCompany.id),
              label: selectedCompany.name,
              value: selectedCompany.id,
            },
          ]
        : [],
    [selectedCompany]
  );

  useEffect(() => {
    if (associatedCompany) {
      setSelectedCompany(associatedCompany);
      form.setFieldValue("companyId", associatedCompany.id);
    }
  }, [associatedCompany, form]);

  return (
    <>
      <FormItem
        label={field.displayName}
        name="companyId"
        required={required}
        rules={[{ required }]}
      >
        <SelectField<Company["id"][]>
          allowClear
          label={field.displayName}
          locked={disabled}
          onClear={handleRemove}
          onClick={disabled ? undefined : showModal}
          open={false}
          options={options}
          prefixIcon={
            <EntityTypeShapedIcon
              containerClassName={styles.entityIcon}
              entityType={EntityType.COMPANY}
            />
          }
          required={required}
          showArrow={false}
        />
      </FormItem>

      {modalVisible && (
        <CompanyAssociation
          allowAdd={allowAdd}
          assignedCompanies={associatedCompany ? [associatedCompany] : undefined}
          entityType={entityType}
          multiselect={false}
          onHide={hideModal}
          onSelect={handleSelect}
          suggestedCompanies={suggestedCompanies}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  onFetchCompany: fetchCompany,
};

export default connect(null, mapDispatchToProps)(CompanyRelationshipField);
