import { createReducer } from "typesafe-actions";

import PinLegendType from "@mapmycustomers/shared/enum/PinLegendType";
import PinLegend, {
  ColorPinLegend,
  ShapePinLegend,
} from "@mapmycustomers/shared/types/map/PinLegend";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import {
  Actions,
  createColorPinLegend,
  createShapePinLegend,
  deletePinLegend,
  fetchPinLegends,
  refreshPinLegend,
  updateColorPinLegend,
  updateShapePinLegend,
} from "@app/store/pinLegends/actions";
import PinLegendsState from "@app/store/pinLegends/PinLegendsState";

export const pinLegendsInitialState: PinLegendsState = {
  colorLegends: [],
  shapeLegends: [],
};

// put default legends first, and sort both groups by names
const pinLegendComparator = (a: PinLegend, b: PinLegend) => {
  if (a.default && !b.default) {
    return -1;
  } else if (b.default && !a.default) {
    return 1;
  }

  return nameComparator(a, b);
};

const pinLegends = createReducer<PinLegendsState, Actions>(pinLegendsInitialState)
  .handleAction(fetchPinLegends.success, (state, { payload }) => ({
    ...state,
    colorLegends: payload
      .filter((legend) => legend.type === PinLegendType.COLOR)
      .sort(pinLegendComparator) as ColorPinLegend[],
    shapeLegends: payload
      .filter((legend) => legend.type === PinLegendType.SHAPE)
      .sort(pinLegendComparator) as ShapePinLegend[],
  }))
  .handleAction(createColorPinLegend.success, (state, { payload }) => ({
    ...state,
    colorLegends: [...state.colorLegends, payload].sort(pinLegendComparator),
  }))
  .handleAction(updateColorPinLegend.success, (state, { payload }) => ({
    ...state,
    colorLegends: state.colorLegends
      .map((legend) => (legend.id === payload.id ? payload : legend))
      .sort(pinLegendComparator),
  }))
  .handleAction(createShapePinLegend.success, (state, { payload }) => ({
    ...state,
    shapeLegends: [...state.shapeLegends, payload].sort(pinLegendComparator),
  }))
  .handleAction(updateShapePinLegend.success, (state, { payload }) => ({
    ...state,
    shapeLegends: state.shapeLegends
      .map((legend) => (legend.id === payload.id ? payload : legend))
      .sort(pinLegendComparator),
  }))
  .handleAction(deletePinLegend.request, (state) => ({
    ...state,
    deleting: true,
  }))
  .handleAction(deletePinLegend.success, (state, { payload }) => ({
    ...state,
    colorLegends: state.colorLegends.filter(({ id }) => id !== payload),
    deleting: false,
    shapeLegends: state.shapeLegends.filter(({ id }) => id !== payload),
  }))
  .handleAction(deletePinLegend.failure, (state) => ({
    ...state,
    deleting: false,
  }))
  .handleAction(refreshPinLegend.request, (state) => ({
    ...state,
    refreshing: true,
  }))
  .handleAction(refreshPinLegend.success, (state, { payload }) => ({
    ...state,
    colorLegends: state.colorLegends.map((pinLegend) =>
      pinLegend.id === payload.id ? (payload as ColorPinLegend) : pinLegend
    ),
    refreshing: false,
    shapeLegends: state.shapeLegends.map((pinLegend) =>
      pinLegend.id === payload.id ? (payload as ShapePinLegend) : pinLegend
    ),
  }))
  .handleAction(refreshPinLegend.failure, (state) => ({
    ...state,
    refreshing: false,
  }));

export * from "./selectors";
export type PinLegendActions = Actions;
export default pinLegends;
