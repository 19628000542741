import React, { FC, ReactNode, useCallback } from "react";

import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import { PickerBaseProps } from "antd/es/date-picker/generatePicker";
import cn from "classnames";

import ErrorRow from "../ErrorRow";
import Labeled, { LabeledFieldProps } from "../Labeled";
import useDatePicker from "../util/useDatePicker";

const block = bem("mmc-date-picker");

export interface ClearableDatePickerProps<DateType>
  extends Omit<PickerBaseProps<DateType>, "onChange" | "onSelect" | "picker">,
    Omit<LabeledFieldProps, "children" | "suffix"> {
  caption?: string;
  className?: string;
  error?: ReactNode;
  locked?: boolean;
  onChange?: (date: Date | undefined) => void;
  picker?: PickerBaseProps<DateType>["picker"]; // making it optional
  use12Hours?: boolean;
}

export const DatePicker: FC<ClearableDatePickerProps<Date>> = ({
  caption,
  className,
  disabled,
  error,
  label,
  labelClassName,
  labelPosition,
  locked,
  onChange,
  required,
  rowProps,
  sideLabelSpan,
  size = "large",
  ...props
}) => {
  const handleChange = useCallback(
    (date: Date | null) => {
      onChange?.(date || undefined);
    },
    [onChange]
  );

  const DatePickerComponent = useDatePicker();

  return (
    <Labeled
      className={cn(block({ disabled: disabled || locked }), className)}
      label={label}
      labelClassName={cn(block("label"), labelClassName)}
      labelPosition={labelPosition}
      required={required}
      rowProps={rowProps}
      sideLabelSpan={sideLabelSpan}
    >
      <div className={block("input")}>
        <DatePickerComponent
          disabled={disabled || locked}
          onChange={handleChange}
          onSelect={onChange}
          size={size}
          suffixIcon={
            locked ? (
              <div className={block("lock")}>
                <FontAwesomeIcon icon={faLock} size="xs" />
              </div>
            ) : (
              <FontAwesomeIcon className={block("calendar")} icon={faCalendar} />
            )
          }
          {...props}
        />
        {error ? (
          <ErrorRow>{error}</ErrorRow>
        ) : caption ? (
          <div className={block("caption")}>{caption}</div>
        ) : null}
      </div>
    </Labeled>
  );
};

export default DatePicker;
